import React, { useEffect, useState } from 'react';
import './notificationNavBar.css';
import NotificationBell from '../notificationBell/notificationBell';
import useAuth from '../../hooks/auth';
import { useSelector } from 'react-redux';

const NotificationNavBar: React.FC = () => {
    const profile = JSON.parse(localStorage.getItem('authentication'));
    const { getUserActiveSubscriptions } = useAuth();
    const [showNotification, setShowNotification] = useState(false);
    const regChanges = useSelector((state: any) => state.regChange.newRegsCount);
    
    useEffect(() => {
        const delay = 2000;
        const timer = setTimeout(() => {
          if (localStorage.getItem('authentication')) {
            try {
              getUserActiveSubscriptions(profile.user_id).then((response) => {
                const hasEligiblePlan = response?.some(
                  (plan: any) => plan.plan_name === 'Premium' || plan.plan_name === 'Enterprise'
                );
                if (hasEligiblePlan && regChanges > 0) {
                  setShowNotification(true);
                }
              });
            }
            catch (error) {
              console.error('Error fetching user active subscriptions:', error);
            }
          }
        }, delay);
        return () => clearTimeout(timer);
      }, [profile]);
    
    return (
        <div className="notification-nav-bar">
            {showNotification && <NotificationBell showCount={true}/>}
        </div>
    );
};

export default NotificationNavBar;