import React from 'react';
import { Box, Button, Flex, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import useAuth from 'hooks/auth';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

const LogInPopup = () => {
  const { isOpen } = useDisclosure({ defaultIsOpen: true });
  const { login, silentlogin } = useAuth();
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    if (!profile?.user_id) {
      silentlogin();
    }
  }, [profile]);

  useEffect(() => {
    if (redirecting) {
      setRedirecting(false);
    }
  }, [redirecting]);

  const handleLogin = () => {
    login();
  };
  const menuBg = useColorModeValue('white', 'navy.800');
  if (!profile?.user_id && isOpen) {
    return (
      <Flex
        position="fixed"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        zIndex="1000"
        bg={menuBg}
        p={6}
        boxShadow="lg"
        borderRadius="md"
        flexDirection="column"
        alignItems="center"
         justifyContent="center"
      >
        <Box mb={4} fontSize="lg" fontWeight="bold" textAlign="center">
          Login Required
        </Box>
        <Box mb={4} textAlign="center">
          {redirecting ? (
            <p>Redirecting to the login page...</p>
          ) : (
            <p>You need to log in to access this feature.</p>
          )}
        </Box>
        <Button colorScheme="blue" onClick={handleLogin}>
          Login
        </Button>
      </Flex>
    );
  }

  return null;
};

export default LogInPopup;
