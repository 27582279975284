import {
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import ComingSoonMessage from "../CommingSoon";
import RegulatoryChangeTableComponent from "./Table/table";
import {
  RegulatoryChangesSortField,
  TableData,
} from "../../../../models/regChangeModels";
import DrillDown from "./DrillDown/drillDown";
import {
  setCurrentFile,
  setCurrentFileOpen,
  setNewRegsCount,
} from "../../../../store/reducers/regChange";
import eventBus from "../../../../utils/eventBus";
import "./styles.css";
import {
  ensureValidTokenExternal,
  getRegulatoryChangeListExternal,
} from "../../../../services/RegChangesService/regExternalService";
import { SortSelection } from "../../../../models/complianceMetric";

export default function RegulatoryChangeReview() {
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedFile, setSelectedFile] = useState<number | null>(null);
  const [showFile, setShowFile] = useState(false);
  const currentFileOpen = useSelector(
    (state: RootState) => state.regChange.currentFileOpen
  );
  const [newChanges, setNewChanges] = useState<number>(0);
  const [regulatoryChanges, setRegulatoryChanges] = useState<TableData | null>(
    null
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState(1);
  const scrollRef = useRef<HTMLDivElement>(null);

  const [isTablet] = useMediaQuery("(max-height: 810px)");
  const [search, setSearch] = useState<string>("");
  const [sort_fields, setSortFields] = useState<RegulatoryChangesSortField[]>([
    {
      field: "created_at",
      order: "DESC",
    },
  ]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [filterType, setFilterType] = useState<string | null>(params.get("type"));
  const [filterImpact, setFilterImpact] = useState<string | null>(params.get("impact"));
  const [query, setQuery] = useState<string>("");
  const feature =  params.get("feature");

  const setupQuery = () => {
    let newQuery = "";
    if (filterType) {
      newQuery += `type=${filterType}`;
    }
    if (filterImpact) {
      if (newQuery) {
        newQuery += "&";
      }
      newQuery += `impact=${filterImpact}`;
    }
    setQuery(newQuery);
    setQueryCalculated(true);
  };

  const [start_date, setStartDate] = useState<Date>(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 6);
    return date;
  });

  const [end_date, setEndDate] = useState<Date>(new Date());
  const [regulators, setRegulators] = useState<string[]>([]);
  const [jurisdictions, setJurisdictions] = useState<string[]>([]);
  const [types, setTypes] = useState<any[]>([]);
  const [impacts, setImpacts] = useState<any[]>([]);
  const [queryCalculated, setQueryCalculated] = useState<boolean>(false);

  useEffect(() => {
    if (queryCalculated) {
      fetchRegulatoryChangesExternal();
    }
  }, [regulators, jurisdictions, pageNo, pageSize, sort_fields, query]);

  useEffect(() => {
    setupQuery();
    getSelectedFilters();
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
    const resetComponent = () => {
      localStorage.removeItem("currentChange");
      localStorage.removeItem("currentChangeOpen");
      localStorage.removeItem("currentRegChange");
      setSelectedFile(null);
      setShowFile(false);
      dispatch(setCurrentFile(null));
      dispatch(setCurrentFileOpen(false));
    };
    eventBus.on("resetRegChanges", resetComponent); 
    localStorage.setItem('from', start_date.toISOString().split("T")[0]);
    localStorage.setItem('to', end_date.toISOString().split("T")[0]);
    return () => {
      eventBus.off("resetRegChanges", resetComponent);
    };
   
  }, []);

  useEffect(() => {
    if (currentFileOpen) {
      setShowFile(true);
    } else {
      setShowFile(false);
    }
  }, [currentFileOpen]);

  const getSelectedFilters = () => {
    let filterString = "";
    if (filterType !== null) {
      for (let i = 0; i < types.length; i++) {
        if (types[i].toLowerCase() === filterType.toLowerCase()) {
          types[i] = filterType;
          filterString += `type=${filterType}`;
        }
      }
    }
      localStorage.setItem('filterString', filterString);
  }

  const fetchRegulatoryChangesExternal = async () => {
    const token = await ensureValidTokenExternal(
      localStorage.getItem("access_token"),
      JSON.parse(localStorage.getItem("authentication") || "{}").user.email,
      JSON.parse(localStorage.getItem("authentication") || "{}").org_id
    );
    console.log(query)
    try {
      setLoading(true);
      const response = await getRegulatoryChangeListExternal(
        token,
        pageNo,
        pageSize,
        search,
        query,
        sort_fields,
        regulators.join(","),
        jurisdictions.join(","),
        start_date.toISOString().split("T")[0],
        end_date.toISOString().split("T")[0]
      )
        .then((response) => {
          if (response) {
            calculateNewRegs(response);
          }
          return response;
        })
        .finally(() => {
          setLoading(false);
        });
      setRegulatoryChanges(response);
    } catch (error) {
      console.error("Error fetching regulatory changes:", error);
    }
  };

  const calculateNewRegs = async (response: TableData) => {
    let changes = 0;
    for (let i = 0; i < response?.data?.length; i++) {
      if (response.data[i].checked === false) {
        changes++;
      }
    }
    setNewChanges(changes);
    dispatch(setNewRegsCount(changes));
  };

  const buildFilterWithTypesAndImpacts = () => {
    let filterString = "";
    const currentTypes: any[] = localStorage.getItem("type")?.split(",");
    const currentImpacts: any[] = localStorage.getItem("impactLevels")?.split(",");
    if (currentTypes.length > 0) {
      currentTypes.forEach((type, index) => {
        console.log(type);
        if (type.selected) {
          filterString += `type=${type.name}`;
          if (index < types.length - 1) {
            filterString += "&";
          }
        }
      });
    }
    if (currentImpacts.length > 0) {
      if (types.length > 0) {
        filterString += "&";
      }
      currentImpacts.forEach((impact, index) => {
        filterString += `impact=${impact.name}`;
        if (impact.selected){
          if (index < impacts.length - 1) {
            filterString += "&";
          }
        }
      });
      console.log(filterString)
    }
    console.log(filterString);
    return filterString;
  };


  const handleFilter = (filterCriteria: string) => {
    setRegulators(localStorage.getItem("regulators")?.split(",") || []);
    setJurisdictions(localStorage.getItem("jurisdictions")?.split(",") || []);
    setTypes(localStorage.getItem("type")?.split(",") || []);
    setImpacts(localStorage.getItem("impactLevels")?.split(",") || []);
    // setFilter(buildFilterWithTypesAndImpacts());
    setStartDate(new Date(localStorage.getItem("from") || ""));
    setEndDate(new Date(localStorage.getItem("to") || ""));
  };

  const getCorrectSortField = (sortSelection: SortSelection) => {
    let field: string;
    switch (sortSelection.header) {
      case "Change":
        field = "display_name";
        break;
      case "Regulator":
        field = "regulator";
        break;
      case "Type":
        field = "type";
        break;
      case "Date":
        field = "created_at";
        break;
      case "Jurisdiction":
        field = "jurisdiction";
        break;
      case "Impact":
        field = "impact_level";
        break;
      case "Status":
        field = "checked";
        break;
      case "UpdatesRequired":
        field = "update_required";
        break;
      case "PolicyUpdates":
        field = "policy_update_required";
        break;
      case "MaterialUpdates":
        field = "material_update_required";
        break;
      default:
        field = "id";
        break;
    }
    return field;
  };

  const handleSort = (sortSelection: SortSelection) => {
    const sortField = getCorrectSortField(sortSelection);
    setSortFields([
      {
        field: sortField,
        order: sortSelection.value,
      },
      ...sort_fields.map((sf) => ({
        ...sf,
        order: sf.field === sortField ? sortSelection.value : "ASC",
      })),
    ]);
  };

  const handlePageNoChange = (page: number) => {
    setPageNo(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageNo(1);
    setPageSize(size);
  };

  const handleFileSelected = (id: number, selected: boolean) => {
    setSelectedFile(id);
    setShowFile(selected);
    dispatch(setCurrentFile(id.toString()));
    dispatch(setCurrentFileOpen(selected));
  };

  const handleFileClose = () => {
    setShowFile(false);
  };

  const handleSearchQuery = (searchQuery: string) => {
    setSearch(searchQuery);
  };
  useEffect(() => {
    if (queryCalculated) {
      const delayDebounceFn = setTimeout(() => {
        fetchRegulatoryChangesExternal();
      }, 500);
      return () => clearTimeout(delayDebounceFn);
  }
  }, [search]);

  useEffect(() => {
    setTotalPages(Math.ceil(regulatoryChanges?.count_doc / pageSize) || 1);
  }, [pageSize, regulatoryChanges]);

  return (
    <div className="top-margin-100">
      <Flex
        w="100%"
        direction="column"
        mt={{ base: "20px", sm: "0px", md: "0px", xl: "0px" }}
        ml={{ base: "0px", md: "3vw", xl: isTablet ? "3vw" : "0vw" }}
        mr={{ base: "0px", md: "0vw", xl: "3vw" }}
        ref={scrollRef}
      >
        {!showFile && (
          <Tabs
            variant="soft-rounded"
            colorScheme="teal"
            isFitted
            position="fixed"
            minW={{ base: "90vw", md: "95vw", xl: isTablet ? "72vw" : "80vw" }}
            maxW={{ base: "90vw", md: "95vw", xl: isTablet ? "72vw" : "80vw" }}
          >
            <TabList>
              <Tab>Recent Changes</Tab>
              <Tab>Policy Review</Tab>
              <Tab>Change Scenario</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <RegulatoryChangeTableComponent
                  dataType="regulatory"
                  data={regulatoryChanges}
                  pageSize={pageSize}
                  pageNo={pageNo}
                  loading={loading}
                  query={handleSearchQuery}
                  onSort={handleSort}
                  onPageNoChange={handlePageNoChange}
                  onPageSizeChange={handlePageSizeChange}
                  onFileSelect={handleFileSelected}
                  onFilterChange={handleFilter}
                  feature={feature}
                />
              </TabPanel>
              <TabPanel>
                <Flex justifyContent="center" alignItems="center" height="100%">
                  <ComingSoonMessage />
                </Flex>
              </TabPanel>
              <TabPanel>
                <Flex justifyContent="center" alignItems="center" height="100%">
                  <ComingSoonMessage />
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
        {showFile && <DrillDown id={selectedFile} />}
      </Flex>
    </div>
  );
}