import React from 'react';
import {
  Button, Input, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import { RenderEditModalProps } from './FilelIst';

export function renderEditModal({
  isOpen, onClose, inputTextColor, setEditedFileName, handleSave, isSaving,
}: RenderEditModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit File Name</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            color={inputTextColor}
            onChange={(e) => setEditedFileName(e.target.value)}
            placeholder="Enter new file name" />
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSave}
            isLoading={isSaving}
          >
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
