import {
  Box,
  Flex,
  Icon,
  Progress,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import CustomCard from "components/card/Card";
import PremiumModal from "components/modals/PremiumModal";
import React from "react";
import { IconType } from "react-icons";
import { FaFileAlt, FaFilePdf, FaFileWord } from "react-icons/fa";
import { MdPerson } from "react-icons/md";
import ReactMarkdown from "react-markdown";
import Tile from "../Tile";
import { DrillDownContent } from "../../solutions/regulatory/DrillDown/drillDownContent";
import { customRenderers } from "./Messages";
import CopyMessage from "./CopyMessage";

export default function MessageContent(
  divRef: React.MutableRefObject<HTMLDivElement>,
  isChat: boolean,
  tabValue: any,
  regulatoryChangeData: any,
  messages: any[],
  isMobile: boolean,
  borderColor: string,
  brandColor: string,
  textColor: string,
  SelectedIcon: IconType,
  sendButtonClicked: any,
  selectedFiles: any,
  flexRef: any,
  inputColor: string,
  progressArray: any,
  attachedQuestion: any,
  profile: any,
  handleSendMessage: any
): React.ReactElement<any, any> {

  return (
    <Flex
      ref={divRef}
      w="100%"
      h="80%"
      mt="10px"
      overflowY="auto"
      flexDirection="column"
      p="3"
      sx={{
        "&::-webkit-scrollbar": {
          width: isChat ? "8px" : "0px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: isChat ? "#7551FF" : "transparent",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: isChat ? "#555" : "transparent",
        },
      }}
    >
      {tabValue === 3 && <PremiumModal />}

      {!isChat && regulatoryChangeData && (
        <DrillDownContent regulatoryChangeData={regulatoryChangeData} />
      )}

      {messages.map((item: any, index: any) => {

        if (item.from === "me") {
          const startTag = "<FILETAGSTART>";
          const endTag = "</FILETAGEND>";
          let htmlContent = null;
          let remainingText = item.text;

          if (item.text.includes(startTag) && item.text.includes(endTag)) {
            const startIndex = item.text.indexOf(startTag) + startTag.length;
            const endIndex = item.text.indexOf(endTag);
            htmlContent = item.text.substring(startIndex, endIndex).trim();
            const beforeTagText = item.text
              .substring(0, item.text.indexOf(startTag))
              .trim();
            const afterTagText = item.text
              .substring(endIndex + endTag.length)
              .trim();

            remainingText = `${beforeTagText} ${afterTagText}`.trim();
          }

          return (
            <Flex
              key={index}
              w="100%"
              align="top"
              my="10px"
              display={isMobile ? "unset" : "flex"}
            >
              <Flex
                width={isMobile ? "100%" : ""}
                borderRadius="full"
                justify="center"
                align="center"
                bg="transparent"
                border="1px solid"
                borderColor={borderColor}
                me="20px"
                h="40px"
                minH="40px"
                minW="40px"
              >
                <Icon
                  as={MdPerson}
                  width="20px"
                  height="20px"
                  color={brandColor}
                />
              </Flex>
              <Flex flexDirection="column" flex="1">
                {htmlContent && (
                  <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                )}
                {remainingText && (
                  <Text
                    color={textColor}
                    fontWeight="600"
                    fontSize={{ base: "sm", md: "md" }}
                    lineHeight={{ base: "24px", md: "26px" }}
                    mt={htmlContent ? "10px" : "0"}
                  >
                    {remainingText}
                  </Text>
                )}
              </Flex>
            </Flex>
          );
        } else {
          return (
            <Flex key={index} w="100%" display={isMobile ? "unset" : "flex"}>
              <Flex
                width={isMobile ? "100%" : ""}
                borderRadius="full"
                justify="center"
                align="center"
                bg="linear-gradient(15.46deg, rgb(54, 82, 186) 26.3%, rgb(6, 128, 255) 86.4%)"
                me="20px"
                h="40px"
                minH="40px"
                minW="40px"
                mb={isMobile ? "10px" : "0"}
              >
                <Icon
                  as={SelectedIcon}
                  width="20px"
                  height="20px"
                  color="white"
                />
              </Flex>
              <CustomCard
                px="22px !important"
                pl="22px !important"
                color={textColor}
                minH="80px"
                fontSize={{ base: "sm", md: "md" }}
                lineHeight={{ base: "24px", md: "26px" }}
                fontWeight="500"
              >
                {item.text.trim() === "" ? (
                  <Flex alignItems="center">
                    <Spinner color="navy.100" />
                    <Text
                      fontSize="md"
                      fontWeight="bold"
                      color={textColor}
                      textAlign="left"
                      ml="10px"
                      mr="10px"
                    >
                      Thinking...
                    </Text>
                  </Flex>
                ) : (
                  <>
                    <ReactMarkdown
                      className="font-medium markdown-content"
                      components={customRenderers}
                    >
                        {item.text.endsWith("<END>") ? item.text.slice(0, -5) : item.text}
                    </ReactMarkdown>
                    {item.text.endsWith("<END>") &&
                    <CopyMessage  
                      item={item}
                      textColor={textColor}
                    />}
                    
                  </>
                )}
              </CustomCard>
            </Flex>
          );
        }
      })}

      {sendButtonClicked && selectedFiles.length > 0 && (
        <Flex w="100%" align="top" my="10px">
          <Flex
            borderRadius="full"
            justify="center"
            align="center"
            bg="transparent"
            border="1px solid"
            borderColor={borderColor}
            me="20px"
            h="40px"
            minH="40px"
            minW="40px"
          >
            <Icon as={MdPerson} width="20px" height="20px" color={brandColor} />
          </Flex>
          <Flex flexDirection="column" flex="1">
            <div ref={flexRef}>
              <Flex p={{ base: "2px", md: "5px" }} gap="5px" wrap="wrap">
                {selectedFiles.map((file: File, index: number) => (
                  <Flex
                    key={index}
                    justifyContent="space-between"
                    alignItems="center"
                    mb="4px"
                    width={{ base: "180px", md: "220px" }}
                    p="10px"
                    border="1px solid"
                    borderColor="gray.500"
                    borderRadius="md"
                    position="relative"
                    flexDirection="column"
                  >
                    <Box display="flex" alignItems="center">
                      <Box mr="8px">
                        {file.type === "application/pdf" && (
                          <Icon as={FaFilePdf} color="white" w={6} h={6} />
                        )}
                        {(file.type === "application/msword" ||
                          file.type ===
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && (
                          <Icon as={FaFileWord} color="white" w={6} h={6} />
                        )}
                        {file.type === "text/plain" && (
                          <Icon as={FaFileAlt} color="white" w={6} h={6} />
                        )}
                        {file.type === "text/html" && (
                          <Icon as={FaFileAlt} color="white" w={6} h={6} />
                        )}
                      </Box>
                      <Box>
                        <Text
                          fontSize="sm"
                          color={inputColor}
                          isTruncated
                          maxW={{ base: "100px", md: "130px" }}
                        >
                          {file.name}
                        </Text>
                        <Text fontSize="sm" color={inputColor}>
                          {file.type === "application/pdf"
                            ? "PDF"
                            : file.type === "application/msword"
                            ? "DOC"
                            : file.type ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            ? "DOCX"
                            : file.type === "text/plain"
                            ? "TXT"
                            : file.type.toUpperCase()}
                        </Text>
                      </Box>
                    </Box>
                    {/* Progress Bar */}
                    <Progress
                      value={progressArray[index]} // Dynamic progress for each file
                      size="sm"
                      colorScheme="white"
                      mt="8px"
                      width="100%"
                      height="4px"
                    />
                  </Flex>
                ))}
              </Flex>
            </div>
            {/* Display Selected Files=================================== */}
            {attachedQuestion && (
              <Text
                fontSize="md"
                fontWeight="bold"
                color={textColor}
                textAlign="left"
                ml="10px"
                mr="10px"
              >
                {attachedQuestion}
              </Text>
            )}
          </Flex>
        </Flex>
      )}

      {isChat &&
        !sendButtonClicked &&
        messages.length === 0 &&
        profile?.user_id && (
          <Flex justify="center" align="center" mt={4} height="100vh">
            {tabValue === 1 && (
              <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={10}>
                <Tile
                  title="How can my bank prepare for section 1071?"
                  onClick={() =>
                    handleSendMessage(
                      "How can my bank prepare for section 1071?"
                    )
                  }
                />
                <Tile
                  title="What are the test steps in a redlining risk assessment?"
                  onClick={() =>
                    handleSendMessage(
                      "What are the test steps in a redlining risk assessment?"
                    )
                  }
                />
                <Tile
                  title="What is the ‘Right to Rescind’ in TILA and when does it apply?"
                  onClick={() =>
                    handleSendMessage(
                      "What is the ‘Right to Rescind’ in TILA and when does it apply?"
                    )
                  }
                />
              </SimpleGrid>
            )}
            {tabValue === 2 && (
              <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={10}>
                <Tile
                  title="Can you write me a set of 5 questions for a self quiz on the Indiana Consumer Protection regulation?"
                  onClick={() =>
                    handleSendMessage(
                      "Can you write me a set of 5 questions for a self quiz on the Indiana Consumer Protection regulation?"
                    )
                  }
                />
                <Tile
                  title="Can you tell me if I am able to share private information about a client with 3rd party vendors in California?"
                  onClick={() =>
                    handleSendMessage(
                      "Can you tell me if I am able to share private information about a client with 3rd party vendors in California?"
                    )
                  }
                />
                <Tile
                  title="What are the requirements for a non-banking entity to operate automated teller machines (ATMs) in New York?"
                  onClick={() =>
                    handleSendMessage(
                      "What are the requirements for a non-banking entity to operate automated teller machines (ATMs) in New York?"
                    )
                  }
                />
              </SimpleGrid>
            )}
          </Flex>
        )}
    </Flex>
  );
}
