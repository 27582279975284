import React, { useEffect, useMemo, useState } from "react";
import { TableData, RegulatoryTable } from "../../../../../models/regChangeModels";
import "./table.css";
import PageSelect from "../../../../../components/pageSelect/pageSelect";
import PageSizeSelection from "../../../../../components/pageSizeSelection/pageSizeSelection";
import { BsLink } from "react-icons/bs";
import SearchBox from "../../../../../components/searchBox/searchBox";
import {
  Flex,
  Spinner,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import useDebounce from "../../../../../utils/debounce";
import { capitolizeStateNames } from "../../../../../utils/conversionUtils/textCoversions";
import HeaderFilter from "../HeaderFilter/HeaderFilter";
import {
  ensureValidTokenExternal,
  getInstitutionProfileRegulatoryExternal,
} from "../../../../../services/RegChangesService/regExternalService";
import { RegulatorySortFilters, SortSelection } from "../../../../../models/complianceMetric";

interface RegulatoryTableProps {
  dataType: string;
  data: TableData;
  pageSize: number;
  pageNo: number;
  loading: boolean;
  query: (query: string) => void;
  onSort: (sortField: SortSelection) => void;
  onPageNoChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
  onFileSelect: (id: number, selected: boolean) => void;
  onFilterChange?: (filter: string) => void;
  feature?: string;
}
const RegulatoryChangeTableComponent: React.FC<RegulatoryTableProps> = ({
  dataType,
  data,
  pageSize,
  pageNo,
  loading,
  onSort,
  query,
  onPageNoChange,
  onPageSizeChange,
  onFileSelect,
  onFilterChange,
  feature
}) => {
  const [isMobile] = useMediaQuery("(max-width: 1200px)");
  const [isMediumScreen] = useMediaQuery(["(max-width: 1720px)"]);
  const [isShortScreen] = useMediaQuery(["(max-height: 1000px)"]);
  const [isTablet] = useMediaQuery("(max-height: 810px)");
  const regulatoryTableData: RegulatoryTable[] = useMemo(
    () => (Array.isArray(data?.data) ? data?.data : []),
    [data]
  );
  const [totalPages, setTotalPages] = useState(1);
  const tableColor = useColorModeValue("black", "white");
  const headerBg = useColorModeValue("gray.100", "navy.800");
  const headerColor = useColorModeValue("black", "white");
  const [regulators, setRegulators] = useState<
    { name: string; selected: boolean }[]
  >([]);
  const [jurisdictions, setJurisdictions] = useState<
    { name: string; selected: boolean }[]
  >([]);
  const profile = JSON.parse(localStorage.getItem("authentication") || "{}");
  const [dataToDisplay, setDataToDisplay] = React.useState("");

  useEffect(() => {
    showChangeType();
    getSortAndFilterOptions();
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(data?.count_doc / pageSize) || 1);
  }, [pageSize, data]);

  const pageSizeOptions = [
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 75, label: "75" },
    { value: 100, label: "100" },
  ];

  const handleRowClick = (filteredData: RegulatoryTable) => {
    onFileSelect(filteredData.id, true);
    localStorage.setItem("currentRegChange", JSON.stringify(filteredData));
  };

  const showChangeType = () => {
    switch (feature) {
      case "High_Impact_Rules_Published":
      case "High_Impact_Rules_Proposed":
      case "Enforcement_Actions_on_Peers":
        setDataToDisplay("regulatory_changes");
        break;
      case "Policy_Updates_Required":
        setDataToDisplay("policy_changes");
        break;
      case "Training_Updates_Required":
        setDataToDisplay("material_changes");
        break;
      default:
        break;
    }
  };
  const generateHeaders = (data: RegulatoryTable[]) => {
    if (data.length === 0) return [];
    switch (dataType) {
      case "regulatory":
        return regulatoryHeaders;
      default:
        return [];
    }
  };

  const getSortAndFilterOptions = async () => {
    if (!localStorage.getItem("RegSortOptions")) {
      const regulatorySortOptions: RegulatorySortFilters = {
        Change: [{ header: 'Change', value: true, labels: 'A to Z' }, { header: 'Change', value: false, labels: 'Z to A' }],
        Regulator: [{ header: 'Regulator', value: true, labels: 'A to Z' }, { header: 'Regulator', value: false, labels: 'Z to A' }],
        Type: [{ header: 'Type', value: true, labels: 'A to Z' }, { header: 'Type', value: false, labels: 'Z to A' }],
        Date: [{ header: 'Date', value: true, labels: 'Newer' }, { header: 'Date', value: false, labels: 'Older' }],
        UpdatesRequired: [{ header: 'UpdatesRequired', value: true, labels: 'Asc' }, { header: 'UpdatesRequired', value: false, labels: 'Desc' }],
        PolicyUpdates: [{ header: 'PolicyUpdates', value: true, labels: 'Asc' }, { header: 'PolicyUpdates', value: false, labels: 'Desc' }],
        MaterialUpdates: [{ header: 'MaterialUpdates', value: true, labels: 'Asc' }, { header: 'MaterialUpdates', value: false, labels: 'Desc' }],
        Status: [{ header: 'Status', value: true, labels: 'Reviewed' }, { header: 'Status', value: false, labels: 'Unreviewed' }],
        Jurisdiction: [{ header: 'Jurisdiction', value: true, labels: 'A to Z' }, { header: 'Jurisdiction', value: false, labels: 'Z to A' }],
        Impact: [{ header: 'Impact', value: true, labels: 'A to Z' }, { header: 'Impact', value: false, labels: 'Z to A' }],
      }
      localStorage.setItem('RegSortOptions', JSON.stringify(regulatorySortOptions));
    }

    if (
      !localStorage.getItem("regulators") &&
      !localStorage.getItem("jurisdictions")
    ) {
      const email = profile?.user.email;
      const org_id = profile?.org_id;
      const token = await ensureValidTokenExternal(
        localStorage.getItem("access_token"),
        email,
        org_id
      );
      await getInstitutionProfileRegulatoryExternal(token).then((response) => {
        response.data.forEach((category: any) => {
          if (
            category.category_name === "RegulatorSelection" &&
            localStorage.getItem("regulators") === null
          ) {
            const regulators = category.products.map((product: any) => ({
              name: product.name,
              selected: true,
            }));
            localStorage.setItem("regulators", JSON.stringify(regulators));
            setRegulators(regulators);
          } else if (
            category.category_name === "JurisdictionSelection" &&
            localStorage.getItem("jurisdictions") === null
          ) {
            const jurisdictions = category.products.map((product: any) => ({
              name: product.name,
              selected: true,
            }));
            localStorage.setItem(
              "jurisdictions",
              JSON.stringify(jurisdictions)
            );
            setJurisdictions(jurisdictions);
          }
        });

      });

    }
    if (localStorage.getItem("impactLevels") === null) {
      const impactLevels = [
        { name: "Low", selected: true },
        { name: "Medium", selected: true },
        { name: "High", selected: true },
      ]
      localStorage.setItem("impactLevels", JSON.stringify(impactLevels));
    }
    if (localStorage.getItem("type") === null) {
      const type = [
        { name: "Rules", selected: true },
        { name: "Proposed Rules", selected: true },
        { name: "Rulestest", selected: true },
        { name: "Final Rule", selected: true },
        { name: "Others", selected: true },
      ]
      localStorage.setItem("type", JSON.stringify(type));
    }
  };

  const regulatoryHeaders = [
    "Change",
    "Regulator",
    "Type",
    "Date",
    "Updates Required",
    "Policy Updates",
    "Material Updates",
    "Jurisdiction",
    "Impact",
    "Status",
    "id",
    "impact_reason",
    "document_name",
    "new_document_url",
  ];

  const mapDataToHeaders = (data: RegulatoryTable[], headers: string[]) => {
    return data.map((item) => {
      const mappedItem: { [key: string]: any } = {};
      headers.forEach((header) => {
        switch (header) {
          case "Change":
            mappedItem[header] = capitolizeStateNames(
              item?.display_name.replace(/_/g, " ")
            );
            break;
          case "Regulator":
            mappedItem[header] = item.regulator;
            break;
          case "Type":
            mappedItem[header] = item.type;
            break;
          case "Date":
            mappedItem[header] = item.created_at;
            break;
          case "Jurisdiction":
            mappedItem[header] = Array.isArray(item.jurisdiction)
              ? item.jurisdiction.join(", ")
              : item.jurisdiction
              ? item.jurisdiction.replace(/[[]""]/g, "")
              : "";
            break;
          case "Impact":
            mappedItem[header] = item.impact_level;
            break;
          case "Status":
            mappedItem[header] = item.checked ? "Reviewed" : "Pending";
            break;
          case "id":
            mappedItem[header] = item.id;
            break;
          case "impact_reason":
            mappedItem[header] = item.impact_reason;
            break;
          case "document_name":
            mappedItem[header] = item.document_name;
            break;
          case "new_document_url":
            mappedItem[header] = item.new_document_url;
            break;
          case "Updates Required":
            mappedItem[header] = item.update_required;
            break;
          case "Policy Updates":
            mappedItem[header] = item.policy_update_required;
            break;
          case "Material Updates":
            mappedItem[header] = item.material_update_required;
            break;
          default:
            mappedItem[header] = (item as unknown as Record<string, unknown>)[
              header
            ];
        }
      });
      return mappedItem;
    });
  };

  const searchQuery = "";

  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const filteredData = useMemo(() => {
    return regulatoryTableData.filter((item: RegulatoryTable) => {
      const changeData = JSON.parse(item.change);
      const searchFields = [
        changeData?.name,
        item.regulator,
        item.type,
        item.created_at,
        item.jurisdiction,
        item.impact_level,
        item.checked ? "Unreviewed" : "Reviewed",
      ];

      return searchFields.some((field) =>
        field
          ?.toString()
          .toLowerCase()
          .includes(debouncedSearchQuery.toLowerCase())
      );
    });
  }, [debouncedSearchQuery, regulatoryTableData]);

  const headers = generateHeaders(regulatoryTableData);
  const rows = mapDataToHeaders(regulatoryTableData, headers);

  return (
    <div className="table-container">
      <Flex
        w="99%"
        direction={isMobile ? "column" : "row"}
        position="relative"
        justifyContent="space-between"
        alignItems={isMobile ? "" : "center"}
        mb={4}
        mr={10}
      >
        <SearchBox
          onSearch={query}
          w={isMediumScreen ? 400 : isTablet ? 200 : 900}
        />
        <PageSizeSelection
          options={pageSizeOptions}
          onPageSizeChange={(size: number) => onPageSizeChange(size)}
          w={200}
          ml={5}
          mr={0}
          mt={0}
        />
        <PageSelect
          currentPage={pageNo}
          totalPages={totalPages}
          loading={false}
          onPageChange={onPageNoChange}
          w={500}
          ml={5}
          mr={0}
          mt={10}
        />
      </Flex>
      <Flex
        overflow={regulatoryTableData.length === 0 ? "hidden" : "scroll"}
        maxHeight={{
          base: "60vh",
          sm: "37vh",
          md: "50vh",
          xl: isShortScreen ? "50vh" : "80vh",
        }}
        minHeight={{
          base: "60vh",
          sm: "37vh",
          md: "50vh",
          xl: isShortScreen ? "50vh" : "80vh",
        }}
        w="99%"
      >
        <table className="table-style">
          <thead className="sticky-header">
        <tr>
          {headers
            .filter((header) => {
          if (dataToDisplay === "regulatory_changes") {
            return (
              header !== "id" &&
              header !== "impact_reason" &&
              header !== "document_name" &&
              header !== "new_document_url"
            );
          } else if (dataToDisplay === "policy_changes") {
            return (
              header === "Change" ||
              header === "Regulator" ||
              header === "Type" ||
              header === "Date" ||
              header === "Policy Updates" ||
              header === "Jurisdiction" ||
              header === "Impact" ||
              header === "Status"
            );
          } else if (dataToDisplay === "material_changes") {
            return (
              header === "Change" ||
              header === "Regulator" ||
              header === "Type" ||
              header === "Date" ||
              header === "Material Updates" ||
              header === "Jurisdiction" ||
              header === "Impact" ||
              header === "Status"
            );
          }
          return false;
            })
            .map((header) => (
          <th
            key={header}
            id={header}
            className={`table-header ${
              headerBg === "gray.100"
            ? "header-bg-light"
            : "header-bg-dark"
            } ${
              headerColor === "black"
            ? "header-color-light"
            : "header-color-dark"
            }`}
          >
            <HeaderFilter
              header={header}
              filterType={
            header as
              | "Change"
              | "Regulator"
              | "Type"
              | "Date"
              | "Updates Required"
              | "Policy Updates"
              | "Material Updates"
              | "Jurisdiction"
              | "Impact"
              | "Status"
              }
              regulatorFilter={header === "Regulator" ? regulators : []}
              jurisdictionFilter={
            header === "Jurisdiction" ? jurisdictions : []
              }
              onFilterChange={onFilterChange}
              onSortChange={(value) => onSort(value)}
            />
          </th>
            ))}
        </tr>
          </thead>
          <tbody>
        {regulatoryTableData.length === 0 && loading && (
          <tr>
            <td colSpan={headers.length} className="no-data">
          <Spinner className="spinner-style" size="xl" />
            </td>
          </tr>
        )}
        {regulatoryTableData.length === 0 && !loading && (
          <tr>
            <td colSpan={headers.length} className="no-data">
          No regulatory change data available at present!
            </td>
          </tr>
        )}
        {regulatoryTableData.length > 0 &&
          rows.map((row, rowIndex) => (
            <tr
          key={rowIndex}
          onClick={() => handleRowClick(filteredData[rowIndex])}
          className="pointer"
          style={{ maxHeight: "25px" }}
            >
          {headers
            .filter((header) => {
              if (dataToDisplay === "regulatory_changes") {
            return (
              header !== "id" &&
              header !== "impact_reason" &&
              header !== "document_name" &&
              header !== "new_document_url"
            );
              } else if (dataToDisplay === "policy_changes") {
            return (
              header === "Change" ||
              header === "Regulator" ||
              header === "Type" ||
              header === "Date" ||
              header === "Policy Updates" ||
              header === "Jurisdiction" ||
              header === "Impact" ||
              header === "Status"
            );
              } else if (dataToDisplay === "material_changes") {
            return (
              header === "Change" ||
              header === "Regulator" ||
              header === "Type" ||
              header === "Date" ||
              header === "Material Updates" ||
              header === "Jurisdiction" ||
              header === "Impact" ||
              header === "Status"
            );
              }
              return false;
            })
            .map((header) => (
              <td
            key={header}
            className={`table-header ${loading ? "pulse" : ""} ${
              headerBg === "gray.100"
                ? "border-light"
                : "border-dark"
            } ${
              header === "Updates Required" ? "text-center" : ""
            }`}
              >
            {header === "Change" ? (
              <span className="change-header change-data">
                <BsLink className="link-icon" />
                {rows[rowIndex][header]}
              </span>
            ) : (
              <span
                className={`table-cell ${
              tableColor === "black"
                ? "text-black"
                : "text-white"
                }`}
              >
                {header === "Date"
              ? new Date(
                  rows[rowIndex][header]
                ).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })
              : header === "Type"
              ? rows[rowIndex][header]
                  .toLowerCase()
                  .replace(/\b\w/g, (char: string) =>
                char.toUpperCase()
                  )
              : header === "Jurisdiction" &&
                typeof rows[rowIndex][header] === "string"
              ? rows[rowIndex][header].replace(/['"[\]]+/g, "")
              : header === "Regulator" &&
                typeof rows[rowIndex][header] === "string"
              ? rows[rowIndex][header].replace(/['"[\]]+/g, "")
              : rows[rowIndex][header]}
              </span>
            )}
              </td>
            ))}
            </tr>
          ))}
        {regulatoryTableData.length > 0 &&
          rows.length < 10 &&
          Array.from({ length: 10 - rows.length }).map((_, index) => (
            <tr
          key={`empty-${index}`}
          className="pointer"
          style={{ maxHeight: "25px" }}
            >
          {headers
            .filter((header) => {
              if (dataToDisplay === "regulatory_changes") {
            return (
              header !== "id" &&
              header !== "impact_reason" &&
              header !== "document_name" &&
              header !== "new_document_url"
            );
              } else if (dataToDisplay === "policy_changes") {
            return (
              header === "Change" ||
              header === "Regulator" ||
              header === "Type" ||
              header === "Date" ||
              header === "Policy Updates" ||
              header === "Jurisdiction" ||
              header === "Impact" ||
              header === "Status"
            );
              } else if (dataToDisplay === "material_changes") {
            return (
              header === "Change" ||
              header === "Regulator" ||
              header === "Type" ||
              header === "Date" ||
              header === "Material Updates" ||
              header === "Jurisdiction" ||
              header === "Impact" ||
              header === "Status"
            );
              }
              return false;
            })
            .map((header) => (
              <td
            key={header}
            className={`table-header ${loading ? "pulse" : ""} ${
              headerBg === "gray.100"
                ? "border-light"
                : "border-dark"
            } ${
              header === "Updates Required" ? "text-center" : ""
            }`}
              >
            &nbsp;
              </td>
            ))}
            </tr>
          ))}
          </tbody>
        </table>
      </Flex>
    </div>
  );
};

export default RegulatoryChangeTableComponent;
