import { AttachmentIcon } from '@chakra-ui/icons';
import {
  Flex,
  IconButton,
  Tooltip, Textarea,
  Input
} from '@chakra-ui/react';
import React from 'react';
import { setInputMessage } from 'store/reducers/inputMessage';

interface PlaceholderColor {
  color: string;
}

interface TextEntryAreaChatProps {
  fileInputRef: React.MutableRefObject<HTMLInputElement>;
  handleFileInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  textareaRef: React.MutableRefObject<HTMLTextAreaElement>;
  inputMessage: string;
  inputColor: string;
  placeholderColor: PlaceholderColor;
  placeholderMsg: string;
  dispatch: any;
  adjustTextareaHeight: () => void;
  profile: { idToken: string } | null;
  handleLogIn: () => void;
  loading: boolean;
  handleSendMessage: () => void;
  setSendButtonClicked: React.Dispatch<React.SetStateAction<boolean>>;
  isChat: boolean;
  textColor: string;
}

export function textEntryAreaChat({
  fileInputRef,
  handleFileInputChange,
  textareaRef,
  inputMessage,
  inputColor,
  placeholderColor,
  placeholderMsg,
  dispatch,
  adjustTextareaHeight,
  profile,
  handleLogIn,
  loading,
  handleSendMessage,
  setSendButtonClicked,
  isChat,
  textColor
}: TextEntryAreaChatProps) {
  return (
    <Flex position="relative" pe="10px" me="10px">
      <Input
        type="file"
        accept=".pdf, .doc, .docx, .txt"
        display="none"
        id="pdf-upload"
        ref={fileInputRef}
        onChange={handleFileInputChange}
        multiple
      />

      <Textarea
        ref={textareaRef}
        pe={{ base: '10px', md: '30px' }}
        ps={{ base: '25px', md: '30px' }}
        value={inputMessage}
        minH="40px"
        maxH="200px"
        h="unset"
        resize="none"
        border="none"
        fontSize="sm"
        fontWeight="500"
        _focus={{ outline: 'none' }}
        color={inputColor}
        _placeholder={placeholderColor}
        placeholder={placeholderMsg}
        onChange={(e) => {
          dispatch(setInputMessage(e.target.value));
          adjustTextareaHeight();
        }}
        onClick={() => {
          if (!profile?.idToken) {
            handleLogIn();
          }
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !loading) {
            dispatch(setInputMessage(''));
            handleSendMessage();
            setSendButtonClicked(true);
          }
        }}
        className="custom-scrollbar"
      />
      {isChat && (
        <Tooltip label="Attach PDF file" aria-label="Attach PDF file">
          <IconButton
            as="label"
            htmlFor="pdf-upload"
            icon={<AttachmentIcon />}
            variant="unstyled"
            color={textColor}
            h="20px"
            position="absolute"
            top="50%"
            left={{ base: '2px', md: '5px' }}
            transform={{ base: 'translateY(-70%)', lg: 'translateY(-60%)' }}
            cursor="pointer"
            aria-label="Attach file"
          />
        </Tooltip>
      )}
    </Flex>
  );
}
