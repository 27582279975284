import {
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import SEOComponent from "../../../../seo";
import HandleFileChange from "../../../../utils/fileUtils/handleFileChangeUtil";
import { PolicyOutputCard } from "./policyOutputCard";
import { DownloadPolicyCard } from "./downloadPolicyCard"
import { PolicyEntryCard } from "./policyEntryCard";
import { getPolicyTypes } from "../../../../services/PolicyService/policyService";
import useAuth from "../../../../hooks/auth";
import { PolicyType } from "../../../../models/policy";
import GetNewSessionId from "../../../../utils/sessionUtils/sessionId";
import useDisplayToast from '../../../../utils/DisplayToast';
import { addMessageListener, closeSocket, sendMessage } from "../../../../webSockets/policyReview/policyReviewWebsocket";

export default function PolicyReview() {
  const profile = JSON.parse(localStorage.getItem("authentication") || "{}");
  const textColor = useColorModeValue("navy.700", "white");
  const placeholderColor = useColorModeValue(
    { color: "gray.500" },
    { color: "whiteAlpha.600" }
  );
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.200");
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const loading = false;
  const displayToast = useDisplayToast();
  const { ensureValidToken } = useAuth();

  const [policyTypes, setPolicyTypes] = useState<PolicyType[]>([]);
  const [selectedPolicyTypes, setSelectedPolicyTypes] = useState<PolicyType[]>([]);
  const [policyText, setPolicyText] = useState<string>("");
  const [selectedFiles, setSelectedFiles] = useState<FileList>();
  const [sessionId, setSessionId] = useState<string>("");
  const [updatedPdfLink, setUpdatedPdfLink] = useState<string>("");
  const [redlinePdfLink, setRedlinePdfLink] = useState<string>("");
  const [policyReviewed, setPolicyReviewed] = useState<boolean>(false);
  const [requirmentsMet, setRequirementsMet] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<string[]>([]);
  const [generatingPolicy, setGeneratingPolicy] = useState<boolean>(false);
  const [resetClicked, setResetClicked] = useState<boolean>(false);
  const [endOfMessage, setEndOfMessage] = useState<boolean>(false);
  const [responseLoading, setResponseLoading] = useState<boolean>(false);

  useEffect(() => {
    const messageHandler = (event: MessageEvent<any>) => {
      const newMessages = JSON.parse(event.data);
      setMessage(newMessages.message);
      if (message && messages.length === 0) {
        setResponseLoading(true);
      }
      if (newMessages.recommendations) {
        setMessages(newMessages.recommendations);
      };
      if (newMessages.updated_policy_url) {
        setUpdatedPdfLink(newMessages.updated_policy_url);
      };
      if (newMessages.redline_policy_url) {
        setRedlinePdfLink(newMessages.redline_policy_url);
      };
      if (newMessages.end) {
        setEndOfMessage(true);	
      }

    };
    addMessageListener(messageHandler);
  }, []);

  interface Metadata {
    session_id: string;
    user_id: number;
    org_id: number;
    policy_types: string;
    additional_instruction: string;
    access_token?: string;
    file_name: string;
  }

  const sendFileWithJson = async () => {
    const file = selectedFiles[0];
    if (!file) return;
    const token = await ensureValidToken(localStorage.getItem("token"));
    const metadata: Metadata = {
      session_id: sessionId,
      user_id: profile.user_id,
      org_id: profile.org_id,
      policy_types: JSON.stringify(selectedPolicyTypes.map((policy) => policy.name)),
      additional_instruction: policyText,
      access_token: token,
      file_name: file.name,
    };
    const encoder = new TextEncoder();
    const jsonBuffer = encoder.encode(JSON.stringify(metadata));
    const fileBuffer = await file.arrayBuffer();
    const separator = encoder.encode('--SEPARATOR--');
    const combinedBuffer = new Uint8Array(jsonBuffer.length + separator.length + fileBuffer.byteLength);
    combinedBuffer.set(jsonBuffer, 0);
    combinedBuffer.set(separator, jsonBuffer.byteLength);
    combinedBuffer.set(new Uint8Array(fileBuffer), jsonBuffer.length + separator.length);
    sendMessage(combinedBuffer);
  };

  useEffect(() => {
    if (selectedFiles && selectedFiles.length !== 0 && selectedPolicyTypes.length !== 0) {
      setRequirementsMet(true);
    } else {
      setRequirementsMet(false);
    }
  }, [selectedPolicyTypes, policyText, selectedFiles]);

  const handleSelectedPolicyTypes = (selectedPolicyTypes: PolicyType[]) => {
    setSelectedPolicyTypes(selectedPolicyTypes);
  }

  const startNewSession = () => {
    const newSessionId = GetNewSessionId();
    setSessionId(newSessionId);
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPolicyText(e.target.value);
  }

  const handleFileChange = (newFiles: FileList) => {
    const updatedFiles = HandleFileChange(newFiles, loading, selectedFiles, displayToast);
    if (updatedFiles !== null) {
      const dataTransfer = new DataTransfer();
      Array.from(updatedFiles).forEach(file => dataTransfer.items.add(file));
      setSelectedFiles(dataTransfer.files);
      console.log(updatedFiles);
      Array.from(updatedFiles).forEach((file, index) => {
        if (Object.getPrototypeOf(file) === File.prototype) {
          console.log(`File ${index + 1} "${file.name}" has the File prototype.`);
        } else {
          console.error(`File ${index + 1} "${file.name}" does NOT have the File prototype.`);
        }
      });
    }
  };

  const handleGeneratePolicy = () => {
    sendFileWithJson();
    setGeneratingPolicy(true);
  } 

  const handleResetPage = () => {
    fetchPolicyTypes();
    setResetClicked(true);
    startNewSession();
    setMessages([]);
    setMessage("");
    setPolicyText("");
    setSelectedFiles(new DataTransfer().files);
    setUpdatedPdfLink("");
    setRedlinePdfLink("");
    setSelectedPolicyTypes([]);
    setGeneratingPolicy(false);
    setTimeout(() => {
      setResetClicked(false);
    }, 3000);
  }

  const fetchPolicyTypes = async () => { 
    const response = await getPolicyTypes(await ensureValidToken(localStorage.getItem("token")));
    return response.data
  }

  useEffect(() => {
    startNewSession();
    fetchPolicyTypes().then(data => {
      setPolicyTypes(data.data);
    });
  }
  , []);
  
  return (
    <>
      <SEOComponent
        title="NuComply-Email Review"
        description="Banking Compliance Expert Email Review"
        canonical="/compliance/email-review"
      />
      <div className="margin-top-110">
        <Flex
          w="95%"
          mx="auto"
          direction="column"
          position="relative"
          mt={{ base: "70px", md: "0px", xl: "0px" }}
          minH="80vh"

        >
          {policyTypes.length !== 0 && 
          <Flex
            mr={screenWidth <= 768 ? "0px" : "5px"}
            w={{ base: "100%", md: "100%", xl: "100%" }}
            maxW="100%"
            justify="center"
            direction={{ base: "column", md: "row" }}
          >
            <PolicyEntryCard
              generatingPolicy={generatingPolicy} 
              screenWidth={screenWidth}
              selectedFiles={selectedFiles}
              handleFileChange={handleFileChange}
              textColor={textColor}
              borderColor={borderColor}
              placeholderColor={placeholderColor}
              policyTypes={policyTypes}
              handleSelectedPolicyTypes={handleSelectedPolicyTypes}
              handleTextChange={handleTextChange}
              handleGeneratePolicy={handleGeneratePolicy}
              policyReviewed={policyReviewed}
              requirementsMet={requirmentsMet}
              resetClicked={resetClicked}/>
            <Flex
              ml={screenWidth <= 768 ? "0px" : "5px"}
              w="100%"
              direction="column"
              position="relative"
              mt={{ base: "70px", md: "0px", xl: "0px" }}
              maxH="80vh"
            > 
              <PolicyOutputCard 
                screenHeight={screenHeight}
                screenWidth={screenWidth} 
                textColor={textColor} 
                outputCode={messages} 
                borderColor={borderColor}
                responseLoading={responseLoading}
                message={message}
                endOfMessage={endOfMessage}
                resetPage={handleResetPage}
                updatedPdfLink={updatedPdfLink}
                redlinePdfLink={redlinePdfLink}
              />
              {/* <DownloadPolicyCard 
                textColor={textColor}
                updatedPdfLink={updatedPdfLink}
                redlinePdfLink={redlinePdfLink}
                screenWidth={screenWidth}/> */}
            </Flex>
          </Flex>
      }
        </Flex>
        
      </div>
    </>
  );
}
