import PageSelect from './pageSelect';
import React from 'react';

interface AdminPageSelectProps {
    currentPage?: number;
    totalPages?: number;
    view: string;
    loading: boolean;
    onPageChangeApproved: (page: number) => void;
    onPageChangePending: (page: number) => void;
    w?: number;
    h?: number;
    mt?: number;
    mb?: number;
    ml?: number;
    mr?: number;
}

const AdminPageSelect: React.FC<AdminPageSelectProps> = (props) => {
   
    const handlePageChange = (pageNo: number) => {
        if (props.view === 'approved') {
            props.onPageChangeApproved(pageNo);
        }
        else if (props.view === 'pending') {
            props.onPageChangePending(pageNo);
        }
    }

    return (
        <>
            <PageSelect 
                currentPage={props.currentPage} 
                totalPages={props.totalPages} 
                onPageChange={handlePageChange} 
                loading={props.loading}
                w={props.w}
                h={props.h}
                mt={props.mt}
                mb={props.mb}
                ml={props.ml}
                mr={props.mr}
            />
        </>
    );
};

export default AdminPageSelect;