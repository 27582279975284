'use client';
import React from 'react';
import {
  Button, Flex, FormLabel,
  Select,
  Text,
  Textarea, useColorModeValue
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import MessageBox from 'components/MessageBox';
import PremiumModal from 'components/modals/PremiumModal';
import useAuth from 'hooks/auth';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { OpenAIModel } from 'types/types';
import ComingSoonMessage from '../CommingSoon';
import useDisplayToast from '../../../../utils/DisplayToast';

export default function RegulatoryReportingAssistant() {
  const [content, setContent] = useState<string>('');
  const [language, setLanguage] = useState<
    | ''
    | 'English'
    | 'Chinese'
    | 'Spanish'
    | 'Arabic'
    | 'Hindi'
    | 'Italian'
    | 'Portuguese'
    | 'Russian'
    | 'Japanese'
    | 'Romanian'
    | 'German'
  >('');
  const outputCode = useState<string>('')[0];
  const model = useState<OpenAIModel>('gpt-3.5-turbo')[0];
  const loading = useState<boolean>(false)[0];
  const textColor = useColorModeValue('navy.700', 'white');
  const placeholderColor = useColorModeValue(
    { color: 'gray.500' },
    { color: 'whiteAlpha.600' },
  );
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.200');
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const { login } = useAuth();
  const displayToast = useDisplayToast();

  const handleTranslate = async () => {
    const maxCodeLength = model === 'gpt-3.5-turbo' ? 500 : 500;

    if (!profile?.idToken || !profile?.user_id) {
      login();
      return;
    }


    if (!content) {
      alert('Please enter your content.');
      return;
    }

    if (content.length > maxCodeLength) {
      alert(
        `Please enter content less than ${maxCodeLength} characters. You are currently at ${content.length} characters.`,
      );
      return;
    }

    if (!language) {
      alert('Please choose your language.');
      return;
    }
    displayToast('Hey there! 👋', "We're hard at work developing this feature to enhance your experience. Stay tuned for exciting updates! 🚀",'info', 3000);
  };


  const handleContent = (Event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(Event.target.value);
  };
  const handleLanguage = (Event: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(Event.target.value as '' | 'English' | 'Chinese' | 'Spanish' | 'Arabic' | 'Hindi' | 'Italian' | 'Portuguese' | 'Russian' | 'Japanese' | 'Romanian' | 'German');
  };


  return (
  
    
    <div className="margin-top-100">
    <Flex
      w="100%"
      direction="column"
      position="relative"
      mt={{ base: '70px', md: '0px', xl: '0px' }}
    >
    <ComingSoonMessage/>
      <Flex
        mx="auto"
        w={{ base: '100%', md: '100%', xl: '100%' }}
        maxW="100%"
        justify="center"
        direction={{ base: 'column', md: 'row' }}
      >
        <Card
          minW={{ base: '100%', md: '40%', xl: '476px' }}
          maxW={{ base: '100%', md: '40%', xl: '476px' }}
          h="min-content"
          me={{ base: '0px', md: '20px' }}
          mb={{ base: '20px', md: '0px' }}
        >
          <Text fontSize={'30px'} color={textColor} fontWeight="800" mb="10px">
            Content Details
          </Text>
          <Text fontSize={'16px'} color="gray.500" fontWeight="500" mb="30px">
            Enter the content you want to translate
          </Text>
          <Textarea
            border="1px solid"
            borderRadius={'10px'}
            borderColor={borderColor}
            p="15px 20px"
            mb="28px"
            minH="324px"
            fontWeight="500"
            _focus={{ borderColor: 'none' }}
            color={textColor}
            placeholder="Type here the content..."
            _placeholder={placeholderColor}
            onChange={handleContent}
          />
          <FormLabel
            display="flex"
            ms="10px"
            htmlFor={'lang'}
            fontSize="md"
            color={textColor}
            letterSpacing="0px"
            fontWeight="bold"
            _hover={{ cursor: 'pointer' }}
          >
            Select the Language
          </FormLabel>
          <Select
            border="1px solid"
            borderRadius={'10px'}
            borderColor={borderColor}
            h="60px"
            id="lang"
            _focus={{ borderColor: 'none' }}
            mb="28px"
            onChange={handleLanguage}
          >
            <option className="white-background" value="Arabic">Arabic</option>
            <option className="white-background" value="Chinese">Chinese</option>
            <option className="white-background" value="English">English</option>
            <option className="white-background" value="German">German</option>
            <option className="white-background" value="Hindi">Hindi</option>
            <option className="white-background" value="Italian">Italian</option>
            <option className="white-background" value="Japanese">Japanese</option>
            <option className="white-background" value="Portuguese">Portuguese</option>
            <option className="white-background" value="Romanian">Romanian</option>
            <option className="white-background" value="Russian">Russian</option>
            <option className="white-background" value="Spanish">Spanish</option>
          </Select>

          <Button
            py="20px"
            px="16px"
            fontSize="md"
            variant="primary"
            borderRadius="45px"
            w={{ base: '100%' }}
            mt="28px"
            h="54px"
            onClick={handleTranslate}
            isLoading={loading}
            _hover={{
              boxShadow:
                '0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important',
              bg: 'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%) !important',
              _disabled: {
                bg: 'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%)',
              },
            }}
          >
            Translate your Content
          </Button>
        </Card>
        <Card maxW="100%" h="100%">
          <Text fontSize={'30px'} color={textColor} fontWeight="800" mb="10px">
            AI Output
          </Text>
          <Text fontSize={'16px'} color="gray.500" fontWeight="500" mb="30px">
            Enjoy your new translated content!
          </Text>
          <MessageBox output={outputCode} />
          <Button
            variant="transparent"
            border="1px solid"
            borderColor={borderColor}
            borderRadius="full"
            maxW="160px"
            ms="auto"
            fontSize="md"
            w={{ base: '300px', md: '420px' }}
            h="54px"
            onClick={() => {
              if (outputCode) navigator.clipboard.writeText(outputCode);
              displayToast('Hey there! 👋', outputCode ? 'Content succesfully copied!' : 'Generate some content first!', 'success', 3000);
            }}
          >
            Copy text
          </Button>
        </Card>
      </Flex>
    </Flex>

<PremiumModal />
    </div>
  );
}
