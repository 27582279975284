import { Alert, AlertDescription, AlertIcon, AlertTitle, Box } from '@chakra-ui/react';
import useAuth from 'hooks/auth';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PaymentErrorAlert = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const { ensureValidToken } = useAuth();
  const extractUUIdFromURL = () => {
    const urlParts = location.pathname.split('/');
    const uuId = urlParts[urlParts.length - 1];
    return uuId;
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = await ensureValidToken(localStorage.getItem('token')); 
      const uuId = extractUUIdFromURL();
      const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}/api/v1/payment/cancel/${uuId}`;

      try {
        const response = await fetch(apiUrl,{
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`  }
      });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        setLoading(false);
        setSuccess(responseData.success);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        setLoading(false);
        setSuccess(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      {loading ? (
        <Alert
          status='info'
          variant='subtle'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          width="400px"
          borderRadius="md"
          boxShadow="lg"
        >
          <AlertIcon boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            Loading...
          </AlertTitle>
          <AlertDescription maxWidth='sm'>
            Please wait while we process your request.
          </AlertDescription>
        </Alert>
      ) : success ? (
        <Alert
          status='success'
          variant='subtle'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          width="400px"
          borderRadius="md"
          boxShadow="lg"
        >
          <AlertIcon boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            Your payment has been cancelled!
          </AlertTitle>
          <AlertDescription maxWidth='sm'>
            We&apos;ve successfully cancelled your payment.
          </AlertDescription>
        </Alert>
      ) : (
        <Alert
          status='error'
          variant='subtle'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          width="400px"
          borderRadius="md"
          boxShadow="lg"
        >
          <AlertIcon boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            Invalid URL
          </AlertTitle>
          <AlertDescription maxWidth='sm'>
            The URL you provided is invalid. Please try again.
          </AlertDescription>
        </Alert>
      )}
    </Box>
  );
};

export default PaymentErrorAlert;
