import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay, Button, Spinner
} from '@chakra-ui/react';

export function renderDeleteFileDialog(isDeleteAlertOpen: boolean, cancelRef: React.RefObject<HTMLButtonElement>, handleCloseDeleteAlert: () => void, isDeleting: boolean, fileToDelete: string, handleConfirmDelete: () => Promise<void>) {
  return <AlertDialog
    isOpen={isDeleteAlertOpen}
    leastDestructiveRef={cancelRef}
    onClose={handleCloseDeleteAlert}
  >
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Delete File
        </AlertDialogHeader>
        <AlertDialogBody>
          {isDeleting ? (
            <Spinner size="md" />
          ) : (
            `Are you sure you want to delete the file ${fileToDelete}?`
          )}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            ref={cancelRef}
            onClick={handleCloseDeleteAlert}
            isDisabled={isDeleting} // Disable the cancel button during deletion
          >
            Cancel
          </Button>
          <Button
            colorScheme="red"
            onClick={handleConfirmDelete}
            ml={3}
            isLoading={isDeleting} // Show loading spinner on the delete button
            isDisabled={isDeleting} // Disable the delete button during deletion
          >
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>;
}
