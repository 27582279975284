export const ToastComponent = {
  components: {
    Toast: {
      baseStyle: () => ({
        container: {
          opacity: '1'
        },
      }),
    },
  },
};
