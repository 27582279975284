import React from 'react';
import { Box, Grid } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

// Custom components
import ProfileSettings from 'views/admin/main/profile/settings';

// Assets
import banner from 'assets/img/auth/banner.png';
import useAuth from 'hooks/auth';
import Banner from './components/Banner';
import { getUserProfile } from '../../../services/UserService/userService';

interface UserProfile {
  id: number;
  name: string;
  organization: string;
  email: string;
  title: string;
}

export default function ProfileOverview() {
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const { ensureValidToken } = useAuth();

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = await ensureValidToken(localStorage.getItem('token')); 
      try {
        const response = await getUserProfile(token, profile?.userId);
        setUserProfile(response);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  return (
    <Box pt={{ base: '80px', md: '80px', xl: '110px' }} mr={{ base: '20px', xl: '20px' }} ml={{ base: '30px', xl: '30px' }}>
      <Grid
        templateColumns={{
          base: '1fr',
          lg: '1.34fr 1fr 1.62fr',
        }}
        templateRows={{
          base: 'repeat(1, 1fr)',
          lg: '1fr',
        }}
        gap={{ base: '20px', xl: '20px' }}
      >
        <Banner
          gridArea="1 / 1 / 2 / 2"
          banner={banner}
          avatar={profile?.user?.picture}
          name={userProfile?.name ?? profile?.name}
          email={profile?.userEmail}
          organization={userProfile?.organization ?? profile?.organization}
          title={userProfile?.title ?? profile?.title}
        />
      </Grid>
      <ProfileSettings />
    </Box>
  );
}
