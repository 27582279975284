import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ensureValidTokenExternal, getComplianceMetricsSummaryExternal } from '../../../../../../../services/RegChangesService/regExternalService';
import { extractTrend } from '../../../../../../../utils/trendConversion/trendConversion';
import WidgetTemplate from '../WidgetTemplate/WidgetTemplate';

interface WidgetLogicProps {
    title: string;
    feature: string;
    filter: string;
}

const WidgetLogic: React.FC<WidgetLogicProps> = ({ title, feature, filter }) => {
    const [percentage, setPercentage] = React.useState<number>(0);
    const [trend, setTrend] = React.useState<string>('');
    const [description, setDescription] = React.useState<string>('Compliance Metrics');
    const [data, setData] = React.useState([]);
    const profile = JSON.parse(localStorage.getItem('authentication'));
    const history = useHistory();
    useEffect(() => {
        retrieveData();
    }, []);

    interface MonthwiseData {
        [key: string]: number;
    }

    interface ConvertedData {
        name: string;
        value: number;
    }

    const convertObjectToArray = (obj: MonthwiseData): ConvertedData[] => {
        if (!obj || typeof obj !== 'object') {
            return [];
        }

        return Object.entries(obj).map(([key, value]) => ({
            name: key,
            value: value,
        }));
    }

    const retrieveData = async () => {
        const email = profile?.user.email;
        const org_id = profile?.org_id;
        const access_token = await ensureValidTokenExternal(localStorage.getItem('access_token'), email, org_id);
        const endDate = new Date();
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 6);
        const data = await getComplianceMetricsSummaryExternal(
            access_token,
            feature,
            filter,
            startDate.toISOString().split('T')[0],
            endDate.toISOString().split('T')[0]
        );
        const trendData = extractTrend(data.data.trend);
        setTrend(trendData.trend);
        setPercentage(trendData.percentage);
        setData(convertObjectToArray(data.data.monthwise_value));
        setDescription(data.data.summary);
    };

    const handleClick = () => {
        const queryParams = `feature=${feature}&${filter}`;
        history.push(`/compliance/regulatory-change-review-list?${queryParams}`);
    };

    return (
        <div onClick={handleClick}>
            <WidgetTemplate 
                title={title}
                change={percentage}
                description={description}
                graphData={data}
                trend={trend}
                feature={feature}
            />
        </div>
    );
};

export default WidgetLogic;