import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Link,
  SimpleGrid,
  Spinner,
  Text,
  Textarea,
  useMediaQuery,
} from '@chakra-ui/react';
import axios from 'axios';
import useAuth from 'hooks/auth';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import TagMultiSelect from './TagMultiSelect';
import useDisplayToast from '../../../utils/DisplayToast';
import React from 'react';

const ExploreData = () => {
  const { fileName, displayName, personaId, persona, partner } = useParams<{ fileName: string; displayName: string; personaId: string; persona: string; partner: string }>();
  const [fileData, setFileData] = useState<any>(null);
  const [fileURL, setFileURL] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [editableContent, setEditableContent] = useState<
    Record<string, string>
  >({});
  const [charCount, setCharCount] = useState<Record<string, number>>({});
  const [isDeleteLoading ] = useState(false);
  const profile =
    localStorage.getItem('authentication') &&
    JSON.parse(localStorage.getItem('authentication') as string);
  const { login } = useAuth();

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [fileIdToDelete, setFileIdToDelete] = useState<string | null>(null);
  const cancelRef = useRef<any>();
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  ); 
  const [isEditingContent, setIsEditingContent] = useState<number | null>(null);
  const [isEditingTags, setIsEditingTags] = useState<number | null>(null);
  const ws = useRef<WebSocket | null>(null);
  const { ensureValidToken } = useAuth();
  const onCloseAlert = () => setIsAlertOpen(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const displayToast = useDisplayToast();
  const handleSelectionChange = () => {
    console.info('Get new data on select');
  };

  const handleWSMessage = () => {
    if (!ws.current) return;

    ws.current.onmessage = (event) => {
      setLoading(false);
      const message = event.data;
      const message_obj = JSON.parse(message);

      const sortedFileData = message_obj.file_data.sort((a: any, b: any) => {
        return a.id.localeCompare(b.id);
      });
      setFileData({ ...message_obj, file_data: sortedFileData });
      if (!fileURL) {
        setFileURL(sortedFileData[0]?.sourceurl);
      }
      console.info('=================');
      console.info(sortedFileData[0]);
      console.info('=================');

      if (message_obj?.is_last) {
        console.info(JSON.parse(message));
        console.info('=================');
        ws.current.close();
      }
      console.info('=============');
      const initialContent = sortedFileData.reduce((acc: any, file: any) => {
        acc[file.id] = file.content;
        return acc;
      }, {});
      setEditableContent(initialContent);
      const initialCharCount = sortedFileData.reduce((acc: any, file: any) => {
        acc[file.id] = file.content.length;
        return acc;
      }, {});
      setCharCount(initialCharCount);
    };
  };

  const sendWSMessage = async () => {
    const token = await ensureValidToken(null);

    const message = JSON.stringify({
      partner,
      persona,
      filename: fileName,
      access_token: token,
    });
    ws.current.send(message);
    handleWSMessage();
  };

  const connectWebSocket = () => {
    setLoading(true);
    const newWs = new WebSocket(
      `${process.env.REACT_APP_LLM_WS_URL}/ws-explore-data`
    );

    newWs.onopen = () => {
      console.info('WebSocket connected to explore-data');
      ws.current = newWs;
    };

    newWs.onclose = () => {
      console.info('WebSocket disconnected');
    };

    newWs.onerror = (error) => {
      console.error('WebSocket error explore-data:', error);
      displayToast('Error', 'Failed to connect websocket. Please try again later.', 'error', 3000);
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  };

  useEffect(() => {
    if (!profile?.idToken || !profile?.user_id) {
      login();
    }
    if (profile?.role_name !== 'Admin') {
      console.info('NOT ADMIN');
    } else {
      console.info('ADMIN');
      connectWebSocket();
      setTimeout(() => {
        sendWSMessage();
      }, 2000);
    }
  }, []);

  const handleEditContent = (id: string, content: string) => {
    if (content.length <= 1000) {
      setEditableContent({
        ...editableContent,
        [id]: content,
      });
      setCharCount({
        ...charCount,
        [id]: content.length,
      });
    }
  };

  const handleUpdateContent = async (file: any) => {
    const token = await ensureValidToken(localStorage.getItem('token'));

    const updatedContent = editableContent[file?.id];
    const currentContent = fileData.file_data.find(
      (item: any) => item.id === file.id
    )?.content;
    if (updatedContent === currentContent) {
      displayToast('No Changes Detected', 'The content has not changed.', 'info', 3000);
      return;
    }
    const updatedFileData = {
      ...fileData,
      file_data: fileData.file_data.map((item: any) =>
        item.id === file.id ? { ...item, content: updatedContent } : item
      ),
    };

    setFileData(updatedFileData);

    try {
      const updatePayload = {
        partner: process.env.REACT_APP_PARTNER,
        persona: process.env.REACT_APP_PERSONA,
        filename: file?.sourcefile,
        id: file?.id,
        content: updatedContent,
        sourcepage: file?.sourcepage,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/update-file-chunk`,
        updatePayload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data === true) {
        displayToast('Update Successful', 'The file chunk has been successfully updated.', 'success', 3000);
      } else {
        displayToast('Update Failed', 'Failed to update the file chunk. Please try again later.', 'error', 3000);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error updating file chunk:', error);
      displayToast('Error', 'An error occurred while updating the file chunk. Please try again later.', 'error', 3000);
      setLoading(false);
    }
  };

  const handleDeleteContent = async () => {
    const token = await ensureValidToken(localStorage.getItem('token'));

    if (!fileIdToDelete) return;

    const updatedFileData = {
      ...fileData,
      file_data: fileData.file_data.filter(
        (item: any) => item.id !== fileIdToDelete
      ),
    };
    setFileData(updatedFileData);
    onCloseAlert();
    try {
      const requestBody = {
        partner: process.env.REACT_APP_PARTNER,
        persona: process.env.REACT_APP_PERSONA,
        file_id: fileIdToDelete,
        auth: profile?.user?.sub,
        organization: profile?.organization,
      };

      const response = await axios.delete(
        `${process.env.REACT_APP_LLM_API_URL}/delete-file-chunk`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: requestBody,
        }
      );

      if (response.data.delete === true) {
        displayToast('Delete Successful', 'The file chunk has been successfully deleted.', 'success', 3000);
      }
    } catch (error) {
      console.error('Error deleting file chunk:', error);
      displayToast('Error', 'An error occurred while deleting the file chunk. Please try again later.', 'error', 3000);
    } finally {
      setFileIdToDelete(null);
    }
  };

  const handleGoBack = () => {
    window.close();
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    const token = await ensureValidToken(localStorage.getItem('token'));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_LLM_API_URL}/get-persona-tags/${personaId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data: any = await response.json();

      const fetchedTags = data.map((tag: any) => ({
        priority: tag.priority,
        value: tag.tag,
        label: tag.tag_type_name,
        parent_tag_type_id: tag.parent_tag_type_id,
        parent_tag_type_name: tag.parent_tag_type_name,
      }));
      setOptions(fetchedTags);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  return (
    <Box p={8} maxW="7xl" mx="auto">
      {isMobile ? (
        <IconButton
          icon={<ArrowBackIcon />}
          aria-label="Back"
          variant="outline"
          colorScheme="blue"
          onClick={handleGoBack}
          mb={4}
        />
      ) : (
        ''
      )}
      <Text fontSize="3xl" textAlign="center" mb={6}>
        Explore Data: {displayName}
      </Text>
      {fileURL ? (
        <Text fontSize="xl" textAlign="center" mb={4}>
          File Source URL:
          <Link href={fileURL} isExternal color=" #007bff">
            {fileURL}
          </Link>
        </Text>
      ) : null}

      {loading ? (
        <Spinner size="xl" />
      ) : (
        <Box>
          {fileData?.file_data.map((file: any) => (
            <Box key={file.id} mb={4}>
              <Box p={6} borderWidth="1px" borderRadius="10px">
                <Text fontWeight="bold" mb={2}>
                  ID: {file.id}
                </Text>
                <Text fontWeight="bold" mb={1}>
                  Item Details:
                </Text>

                {isEditingContent === file.id ? (
                  <>
                    <Textarea
                      border="2px solid"
                      borderRadius="10px"
                      p="15px 20px"
                      mb="28px"
                      minH="150px"
                      fontWeight="500"
                      value={editableContent[file.id] || ''}
                      onChange={(e) =>
                        handleEditContent(file.id, e.target.value)
                      }
                    />
                    <ButtonGroup>
                      <Button
                        colorScheme="blue"
                        onClick={() => {
                          handleUpdateContent(file);
                          setIsEditingContent(null);
                        }}
                      >
                        Save Content
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => {
                          setIsAlertOpen(true);
                          setFileIdToDelete(file.id);
                        }}
                      >
                        Delete
                      </Button>
                      <Button onClick={() => setIsEditingContent(null)}>
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </>
                ) : (
                  <>
                    <Box
                      p={4}
                      borderRadius="10px"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      lineHeight="1.2em"
                    >
                      {file.content}
                    </Box>
                  </>
                )}

                <SimpleGrid columns={2} spacing={4} mt={4}>
                  <TagMultiSelect
                    partner={partner}
                    persona={persona}
                    fileName={file.sourcefile}
                    sourcepage={file.sourcepage}
                    id={file.id}
                    selectedOptions={file.tags}
                    onSelectionChange={handleSelectionChange}
                    isEditing={isEditingTags === file.id}
                    options={options}
                    onSaveComplete={() => setIsEditingTags(null)}
                  />
                </SimpleGrid>

                <ButtonGroup mt={4}>
                  {isEditingTags === file.id ? (
                    <Button onClick={() => setIsEditingTags(null)}>
                      Cancel
                    </Button>
                  ) : (
                    <>
                      {isEditingTags !== file.id && (
                        <>
                          {isEditingContent !== file.id && (
                            <Button
                              colorScheme="blue"
                              onClick={() => {
                                setIsEditingContent(file.id);
                                setIsEditingTags(null);
                              }}
                            >
                              Edit Content
                            </Button>
                          )}
                        </>
                      )}
                      {isEditingContent !== file.id && (
                        <Button
                          colorScheme="blue"
                          onClick={() => {
                            setIsEditingTags(file.id);
                            setIsEditingContent(null);
                          }}
                        >
                          Edit Tags
                        </Button>
                      )}
                    </>
                  )}
                </ButtonGroup>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      {isAlertOpen && (
        <AlertDialog
          isOpen={isAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={onCloseAlert}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete File Chunk
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to delete this file chunk? This action
                cannot be undone.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCloseAlert}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  onClick={handleDeleteContent}
                  ml={3}
                  isLoading={isDeleteLoading}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </Box>
  );
};

export default ExploreData;
