import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/react';
import './messages.css'

export default function MessageBox(props: { output: string[] | string, height?: string, loading?: boolean, statusText?: string }) {
  const { output, loading, statusText } = props;
  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.200');
  return (
    <Flex
      w="100%"
      maxW="100%"
      flexWrap="wrap"
      p="15px 20px"
      border="1px solid"
      color={textColor}
      borderColor={borderColor}
      borderRadius="10px"
      minH={props.height ? props.height : "564px"}
      fontSize="md"
      fontWeight="500"
      mb="28px"
      overflow="auto"
      background="#0b1437"
      backgroundColor="#0b1437"
      position="relative"
      transition="min-height 3s ease"
    >
      {props.statusText && (
        <Flex
          w="100%"
          p="10px"
          minH={"40px"}
          maxH={"40px"}
          bg="blue.500"
          color="white"
          position="sticky"
          top="0"
          zIndex="1"
          justifyContent="center"
          alignItems="center"
          borderRadius="10px"
          opacity={0.8}
        >
          {props.statusText}
        </Flex>
      )}
      <Flex
        w="100%"
        justifyContent="center"
        alignItems="center"
        flex="1"
      >
        { output.length <= 0 && statusText === "" ? (
          <div>Your generated response will appear here...</div>
        ) : (
          <ReactMarkdown className="w-[100%] markdown">
            {output !== undefined || output !== '' ?
              (Array.isArray(output) ? output.join('\n') : output)
              :
              'Your generated response will appear here...'
            }
          </ReactMarkdown>
        )}
      </Flex>
    </Flex>
    );
  }
