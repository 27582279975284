import axios from 'axios';

const API_URL = process.env.REACT_APP_LLM_API_URL;
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

export const getUsers = async (
    token: string,
    org_id: number,
    page: number,
    limit: number,
    search: string
) => {
    const response = await axios.post(
        `${API_URL}/get-org-users`,
        {
            org_id,
            page,
            limit,
            search
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }    
    );
    return response;
};

export const getUserById = async (token: string, user_id: number) => {
    const response = await axios.get(
        `${API_URL}/get-user-by-id/${user_id}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }
    );
    return response;
};

export const removeUserById = async (
    token: string, 
    user_id: number, 
    org_id: number
) => {
    const response = await axios.post(
        `${API_URL}/remove-user-by-id`,
        {
            user_id,
            org_id
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }
    );
    return response;
};

export const setUserRole = async (token: string, user_id: number, org_id: number, role_id: number) => {
    const response = await axios.post(`${API_URL}/set-user-role`, 
        { 
            user_id,
            org_id,
            role_id
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }
    );
    return response;
}

export const updateUserStatus = async (token: string, user_id: number, org_id: number, status: string) => {
    const response = await axios.post(`${API_URL}/update-user-status`, 
        { 
            user_id,
            org_id,
            status
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }
    );
    return response;
}

export const inviteUser = async (token: string, user_id: number, org_id: number, role_id: number, name: string, email: string) => {
    const response = await axios.post(`${API_URL}/invite-user`, 
        { 
            user_id,
            org_id,
            role_id,
            name,
            email 
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }
    );
    return response;
}

export const getRoles = async (token: string) => {
    const response = await axios.get(`${API_URL}/get-roles`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    });
    return response;
}

export const getUserProfile = async (token: string, user_id: number) => {
    try {
        const response = await axios.post(
            `${BACKEND_API_URL}/api/v1/auth/user-profile`,
            { userId: user_id },
            { headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`  } }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
}

export const getInstitutionProfileForNuComply = async (token: string) => {
    try {
        const response = await axios.post(
            `${API_URL}/get-institution-profile-for-nucomply`,
            {
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching organization profile:', error);
        throw error;
    }   
}

export const setInstitutionProfileForNuComply = async (token: string, added_product_ids: number[], removed_product_ids: number[]) => {
    try {
        const response = await axios.post(
            `${API_URL}/save-institution-profile-for-nucomply`,
            {
                added_product_ids,
                removed_product_ids
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error setting organization profile:', error);
        throw error;
    }   
}