import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  SimpleGrid,
  Spinner,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import React, { useEffect, useState } from 'react';

interface TagMultiSelectProps {
  selectedOptions: string[];
  onSelectionChange: (selectedOptions: string[]) => void;
  isEditing: boolean;
  options: { value: string; label: string }[];
  onSaveComplete: (tags: string[]) => void;
  onCancel: () => void;
}

const CommonTagMultiSelect: React.FC<TagMultiSelectProps> = ({
  selectedOptions,
  onSelectionChange,
  isEditing,
  options,
  onSaveComplete,
  onCancel,
}) => {
  const [selectedItems, setSelectedItems] = useState<
    { value: string; label: string }[]
  >(() => selectedOptions.map((option) => ({ value: option, label: option })));
  const isLoading = false;

  useEffect(() => {
    setSelectedItems(
      selectedOptions.map((option) => ({ value: option, label: option }))
    );
  }, [selectedOptions]);

  const handleChange = (newValue: any, actionMeta: any, parent: string) => {
    const updatedItems = selectedItems.filter(
      (item) =>
        !groupedOptions[parent].some(
          (option: any) => option.value === item.value
        )
    );
    const newItems = newValue.map((item: any) => ({
      value: item.value,
      label: item.label,
    }));
    const allSelectedItems = [...updatedItems, ...newItems];

    setSelectedItems(allSelectedItems);
    onSelectionChange(allSelectedItems.map((item: any) => item.value));
  };

  const handleSaveTag = async () => {
    const tags: any = selectedItems.map((item) => item.value);
    onCancel();
    onSaveComplete(tags);
  };

  const groupOptionsByParent = (options: any) => {
    return options.reduce((acc: any, option: any) => {
      const parent = option.parent_tag_type_name || option.label; // Use label if parent_tag_type_name is null
      const key =
        parent === option.label ? parent : `${parent}: ${option.label}`; // Create a unique key for each parent-label pair

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push({ value: option.value, label: option.value });

      return acc;
    }, {} as { [key: string]: { value: string; label: string }[] });
  };

  const groupedOptions = groupOptionsByParent(options);

  return (
    <>
      {isEditing ? (
        <Flex direction="column">
          <SimpleGrid columns={2} spacing={4} mt={4}>
            {Object.keys(groupedOptions).map((parent, index) => (
              <FormControl p={4} key={index}>
                <FormLabel>{parent}</FormLabel>
                <Select
                  isSearchable={false}
                  isMulti
                  isDisabled={!isEditing}
                  selectedOptionStyle="check"
                  name="tags"
                  options={groupedOptions[parent]}
                  value={selectedItems.filter((item) =>
                    groupedOptions[parent].some(
                      (option: any) => option.value === item.value
                    )
                  )}
                  onChange={(newValue, actionMeta) =>
                    handleChange(newValue, actionMeta, parent)
                  }
                  placeholder={`Select ${parent} tag...`}
                  closeMenuOnSelect={false}
                  chakraStyles={{
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      bg: 'transparent',
                      px: 2,
                      cursor: 'inherit',
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      display: 'none',
                    }),
                    groupHeading: (provided) => ({
                      ...provided,
                      fontWeight: 'bold',
                      color: 'gray',
                    }),
                    option: (provided) => ({
                      ...provided,
                      paddingLeft: '30px',
                    }),
                  }}
                />
              </FormControl>
            ))}
          </SimpleGrid>

          <Flex direction="column" mt={4} align="flex-start" p={4}>
            {isLoading ? (
              <Spinner />
            ) : (
              <Flex direction="row" gap={4} align="center" width="100%">
                <Button
                  width="100px"
                  colorScheme="blue"
                  onClick={() => handleSaveTag()}
                >
                  Save tag
                </Button>
                <Button width="100px" colorScheme="gray" onClick={onCancel}>
                  Cancel
                </Button>
              </Flex>
            )}
          </Flex>
        </Flex>
      ) : (
        <FormControl p={4}>
          <Flex flexWrap="wrap" gap={2}>
            {/* Optionally show selected tags here if needed */}
          </Flex>
        </FormControl>
      )}
    </>
  );
};

export default CommonTagMultiSelect;
