import { getApprovedFilesPaginated, getPendingFilesPaginated } from '../../../services/FileService/fileService';


export interface FetchPersonasPartnersProps {
  ensureValidToken: (token: string | null) => Promise<string>;
  alreadyCalledFetchPartnersRef: React.MutableRefObject<boolean>;
  setPartners: React.Dispatch<React.SetStateAction<any[]>>;
  alreadyCalledFetchPersonasRef: React.MutableRefObject<boolean>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setPersonas: React.Dispatch<React.SetStateAction<any[]>>;
}
export function fileFetchers(ensureValidToken: (token: string) => Promise<string>, pageSize: number) {
  const fetchApprovedFiles = async (partner_name: string, persona_name: string, page: number, size?: number, group?: string, search?: string) => {
    const token = await ensureValidToken(localStorage.getItem('token'));
    return await getApprovedFilesPaginated(
      token,
      partner_name,
      persona_name,
      page as number,
      size || pageSize as number,
      group,
      search || ''
    );
  };

  const fetchPendingFiles = async (partner_name: string, persona_name: string, page: number, size?: number, group?: string, search?: string) => {
    const token = await ensureValidToken(localStorage.getItem('token'));
    return await getPendingFilesPaginated(
      token,
      partner_name,
      persona_name,
      page as number,
      size || pageSize as number,
      group,
      search || ''
    );
  };
  return { fetchApprovedFiles, fetchPendingFiles };
}
