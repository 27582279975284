import { Button, Flex, FormLabel, Input, useColorModeValue, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import useDisplayToast from '../../utils/DisplayToast';

interface UploadFilesProps {
    selectedFiles: FileList;
    onFileChange: (files: FileList) => void;
    multipleSelection?: boolean;
    resetClicked?: boolean;
}

const UploadFiles: React.FC<UploadFilesProps> = ({ selectedFiles, onFileChange, multipleSelection, resetClicked }) => {
    const textColor = useColorModeValue('navy.700', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.200');
    const [disabled, setDisabled] = useState(false);
    const displayToast = useDisplayToast();

    useEffect(() => {
      if (resetClicked) {
        setDisabled(false);
        onFileChange(new DataTransfer().files);
      }  
    }, [resetClicked]);

    const onFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!multipleFileCheck(files)) return;
        onFileChange(files);
    }

    const multipleFileCheck = (files: FileList) => {
      if (!multipleSelection) {
        if (files.length > 1) {
          displayToast('error', 'Only one file can be uploaded at a time', 'info', 5000);
          setDisabled(true);
          return false;
        } else {
            if (files[0].name.endsWith('.doc') || files[0].name.endsWith('.docx') || files[0].name.endsWith('.pdf')) {
              setDisabled(true);
              return true;
            } else {
              displayToast('error', 'Only .doc/.docx or .pdf files are allowed', 'info', 5000);
              setDisabled(false);
              return false;
            }
        }
      }
        return true;
    }
    
    return (
        <>
            <FormLabel
                display="flex"
                ms="10px"
                htmlFor="file-upload"
                fontSize="md"
                color={textColor}
                letterSpacing="0px"
                fontWeight="bold"
                _hover={{ cursor: 'pointer' }}
              >
                Upload Files
              </FormLabel>
              <Input
                type="file"
                id="file-upload"
                multiple
                onChange={onFileSelection}
                border="1px solid"
                borderRadius={'10px'}
                borderColor={borderColor}
                h="60px"
                mb="10px"
                color={textColor}
                display="none"
              />
              <Flex>
                <Button
                  as="label"
                  htmlFor="file-upload"
                  cursor="pointer"
                  backgroundColor="gray.700"
                  color="white"
                  _hover={{ backgroundColor: 'gray.600' }}
                  mb="20px"
                  borderRadius="10px"
                  p="10px 20px"
                  disabled={disabled}
                  onClick={(e) => disabled && e.preventDefault()}
                >
                  {multipleSelection ? 'Select Files' : 'Select File'}
                </Button>
                <Flex mt="10px" ml="5px">
                  
                  {selectedFiles?.length > 0 ? (
                    multipleSelection ? (
                      <Text color={textColor} fontSize="sm">
                        {selectedFiles.length} files selected
                      </Text>
                    ) : (
                        <Text color={textColor} fontSize="sm" title={selectedFiles[0].name}>
                          {selectedFiles[0].name.length > (window.innerWidth < 900 ? 20 : 35) ? 
                            `${selectedFiles[0].name.substring(0, window.innerWidth < 900 ? 20 : 35)}...` : 
                            selectedFiles[0].name}
                        </Text>
                    )
                  ) : (
                    <Text color={textColor} fontSize="sm">
                      No file selected
                    </Text>
                  )}
                </Flex>
              </Flex>
        </>
    );
};

export default UploadFiles;