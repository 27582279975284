import React, { useState, useRef, useEffect } from 'react';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { FaClone } from 'react-icons/fa';

interface Item {
  text: string;
  complete: boolean;
}

interface CopyMessageProps {
  item: Item;
  textColor: string;
}

const CopyMessage: React.FC<CopyMessageProps> = ({ item, textColor }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(item.text);
  };
  
  const [showCopy, setShowCopy] = useState(false);
  const showCopyRef = useRef(showCopy);

  useEffect(() => {
    if (item.text.endsWith("<END>")) {
      setShowCopy(true)
    }
  }, [item.text]);

  return (
    <>
      { showCopyRef && (
        <Flex align="center" mr="10px" ml="auto">
          <Icon as={FaClone} cursor="pointer" onClick={handleCopy} />
          <Text ml="2" fontSize="sm" color={textColor}>
            Copy
          </Text>
        </Flex>
      )}
    </>
  );
};

export default CopyMessage;