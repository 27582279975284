import { Button } from '@chakra-ui/react';
import React from 'react';

export function sendButton(loading: boolean, isMobile: boolean, handleSendMessage: () => void, selectedFiles: File[], setSendButtonClicked: (clicked: boolean) => void) {
  return <Button
    disabled={loading}
    variant="primary"
    py="20px"
    px="16px"
    mt={isMobile ? "5px" : ""}
    fontSize="sm"
    borderRadius="45px"
    ms="auto"
    w={isMobile ? "100%" : { base: '160px', md: '210px' }}
    h="55px"
    alignSelf="flex-start"
    _hover={{
      boxShadow: '0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important',
      bg: 'linear-gradient(15.46deg, rgb(54, 82, 186) 26.3%, rgb(6, 128, 255) 86.4%) !important',
      _disabled: {
        bg: 'linear-gradient(15.46deg, rgb(54, 82, 186) 26.3%, rgb(6, 128, 255) 86.4%)',
      },
    }}
    onClick={() => {
      handleSendMessage();
      if (selectedFiles.length > 0) {
        setSendButtonClicked(true);
      }
    }}
  >
    {loading ? 'Sending...' : 'Send'}
  </Button>;
}
