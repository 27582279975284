import { Flex, useColorModeValue } from "@chakra-ui/react";
import React, { useState } from "react";
import { User } from "../../../models/user";
import "../../../views/client/solutions/regulatory/Table/table.css";
import modals from "./userManagementModals";
import { InviteButton } from "./inviteButton";
import { capitalizeFirstLetter } from "../../../utils/capitolise";

interface UserListProps {
  users: User[];
  loading?: boolean;
  accordionOpen?: boolean;
  onModalAction: () => void;
}

export function UserList({
  users = [],
  loading,
  accordionOpen,
  onModalAction,
}: UserListProps) {
  const headerBg = useColorModeValue("gray.100", "navy.800");
  const headerColor = useColorModeValue("black", "white");
  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
  const [isDeactivateAccountModalOpen, setIsDeactivateAccountModalOpen] =
    useState(false);
  const [isReactivateAccountModalOpen, setIsReactivateAccountModalOpen] =
    useState(false);
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const [modalTarget, setModalTarget] = useState<User | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>();
  const isMobile = window.innerWidth < 768;

  const [rowMenuOpen, setRowMenuOpen] = useState<Record<number, boolean>>({});

  const headers = isMobile
    ? ["", "Name", "Email", "Account Type", "Status"]
    : ["Name", "Email", "Account Type", "Status", ""];

  const onFilter = (header: string) => {
    console.info(`Filtering by ${header}`);
  };

  const handleInviteClick = () => {
    setIsInviteUserModalOpen(true);
  };

  const handleMobileElipsisClick = (index: number) => {
    setRowMenuOpen({
      ...rowMenuOpen,
      [index]: !rowMenuOpen[index],
    });
  };
  const verticalScreenSize = window.innerHeight;
  const maxHeight =
    verticalScreenSize < 700
      ? verticalScreenSize * (accordionOpen && isMobile ? 0.62 : 0.42)
      : verticalScreenSize * (accordionOpen && isMobile ? 0.9 : 0.7);

  return (
    <div className="table-container table-container-overflow">
      <Flex
        overflow={users.length === 0 ? "hidden" : "scroll"}
        maxHeight={`${maxHeight}px`}
        w="99%"
      >
        <table className="table-style" style={{ overflow: "visible" }}>
          <thead className="stcky-header">
            <tr>
              {headers.map((header, index) => (
                <th
                  key={index}
                  className={`table-header ${
                    headerBg === "gray.100"
                      ? "header-bg-light"
                      : "header-bg-dark"
                  } ${
                    headerColor === "black"
                      ? "header-color-light"
                      : "header-color-dark"
                  }`}
                >
                  <button
                    className="filter-button"
                    onClick={() => onFilter(header)}
                  >
                    {header.charAt(0).toUpperCase() + header.slice(1)}
                  </button>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={`${loading ? "pulse" : ""}`}>
            {users.length === 0 && (
              <tr className="table-row-height table-row-height">
                <td colSpan={4} className="no-data">
                  There are no users to display.
                </td>
              </tr>
            )}
            {users.length > 0 &&
              users.map((user, index) => (
                <React.Fragment key={index}>
                  <tr className="pointer table-row-height">
                    {isMobile && (
                      <td className="table-data" style={{ fontSize: "130%" }}>
                        {capitalizeFirstLetter(user.user_status) !==
                          null && (
                          <button
                            type="button"
                            className="dropbtn"
                            onClick={() => handleMobileElipsisClick(index)}
                            style={{ fontSize: "130%" }}
                          >
                            {rowMenuOpen[index] ? "▲" : "▼"}
                          </button>
                        )}
                      </td>
                    )}
                    <td className="table-data">{user.user_name}</td>
                    <td className="table-data">{user.user_email}</td>
                    <td className="table-data">{user.role_name}</td>
                    <td className="table-data">
                      {capitalizeFirstLetter(user.user_status)}
                    </td>
                    {!isMobile && (user.user_status !== null) && (
                      <td className="elipsis-table">
                        <div className="dropdown">
                          <button type="button" className="dropbtn">
                            ...
                          </button>
                            <div
                            className={`dropdown-content ${
                              capitalizeFirstLetter(user.user_status) !==
                              "Deactivated"
                              ? "dropdown-left"
                              : "dropdown-left-single"
                            }`}
                            style={{ zIndex: 1 }}
                            >
                            {capitalizeFirstLetter(user.user_status) !==
                            "Deactivated" ? (
                              <>
                              <button
                                className="dropdown-button dropdown-button-top"
                                type="button"
                                onClick={() => {
                                setCurrentIndex(index);
                                setIsEditProfileModalOpen(true);
                                setModalTarget(user);
                                }}
                              >
                                Edit Profile
                              </button>
                              <button
                                className="dropdown-button dropdown-button-bottom"
                                type="button"
                                onClick={() => {
                                setCurrentIndex(index);
                                setIsDeactivateAccountModalOpen(true);
                                setModalTarget(user);
                                }}
                              >
                                Deactivate Account
                              </button>
                              </>
                            ) : (
                              <button
                              className="dropdown-button dropdown-button-single"
                              type="button"
                              onClick={() => {
                                setCurrentIndex(index);
                                setIsReactivateAccountModalOpen(true);
                                setModalTarget(user);
                              }}
                              >
                              Reactivate Account
                              </button>
                            )}
                            </div>
                        </div>
                      </td>
                    )}
                  </tr>

                  {rowMenuOpen[index] && (
                    <tr className="pointer table-row-height">
                      <td colSpan={5} style={{ padding: 0 }}>
                        <div
                          className="dropdown-mobile"
                          style={{ display: "flex", justifyContent: "left" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                            }}
                          >
                            {capitalizeFirstLetter(user.user_status) !==
                            "Deactivated" ? (
                              <>
                                <button
                                  className="dropdown-button row-menu-button"
                                  type="button"
                                  onClick={() => {
                                    setCurrentIndex(index);
                                    setIsEditProfileModalOpen(true);
                                    setModalTarget(user);
                                    handleMobileElipsisClick(index);
                                  }}
                                >
                                  Edit Profile
                                </button>
                                <button
                                  className="dropdown-button row-menu-button"
                                  type="button"
                                  onClick={() => {
                                    setCurrentIndex(index);
                                    setIsDeactivateAccountModalOpen(true);
                                    setModalTarget(user);
                                    handleMobileElipsisClick(index);
                                  }}
                                >
                                  Deactivate Account
                                </button>
                              </>
                            ) : (
                              <button
                                className="dropdown-button row-menu-button"
                                type="button"
                                onClick={() => {
                                  setCurrentIndex(index);
                                  setIsReactivateAccountModalOpen(true);
                                  setModalTarget(user);
                                  handleMobileElipsisClick(index);
                                }}
                              >
                                Reactivate Account
                              </button>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}

                  {isEditProfileModalOpen && index === currentIndex && (
                    <modals.UserProfileModal
                      isOpen={isEditProfileModalOpen}
                      onOpen={() => setIsEditProfileModalOpen(true)}
                      onClose={() => {
                        setIsEditProfileModalOpen(false);
                        setCurrentIndex(undefined);
                        onModalAction();
                      }}
                      user={users.find(
                        (u) => u.user_name === modalTarget?.user_name
                      )}
                    />
                  )}
                  {isDeactivateAccountModalOpen && index === currentIndex && (
                    <modals.DeactivateAccountModal
                      isOpen={isDeactivateAccountModalOpen}
                      onOpen={() => setIsDeactivateAccountModalOpen(true)}
                      onClose={() => {
                        setIsDeactivateAccountModalOpen(false);
                        setCurrentIndex(undefined);
                        onModalAction();
                      }}
                      user={users.find(
                        (u) => u.user_name === modalTarget?.user_name
                      )}
                    />
                  )}
                  {isReactivateAccountModalOpen &&
                    index === currentIndex && (
                      <modals.ReactivateAccountModal
                        isOpen={isReactivateAccountModalOpen}
                        onOpen={() => setIsReactivateAccountModalOpen(true)}
                        onClose={() => {
                          setIsReactivateAccountModalOpen(false);
                          setCurrentIndex(undefined);
                          onModalAction();
                        }}
                        user={users.find(
                          (u) => u.user_name === modalTarget?.user_name
                        )}
                      />
                    )}
                </React.Fragment>
              ))}
          </tbody>
        </table>
        {isInviteUserModalOpen && (
          <modals.InviteUserModal
            isOpen={isInviteUserModalOpen}
            onOpen={() => setIsInviteUserModalOpen(true)}
            onClose={() => setIsInviteUserModalOpen(false)}
          />
        )}
      </Flex>
      <InviteButton onClick={handleInviteClick} />
    </div>
  );
}
