import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import CustomCard from 'components/card/Card';
import useAuth from 'hooks/auth';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Category } from '../../../../../models/products';
import MultiSelect from './MultiSelect'; // Import the MultiSelect component

export default function Settings() {
  const [categories, setCategories] = useState<Category[]>([]);
  const product = useSelector((state: RootState) => state.product.userProduct);
  const { fetchUserProduct } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      if (!product) {
        const fetchedProduct = await fetchUserProduct();
        setCategories(fetchedProduct?.product || []);
      } else {
        setCategories(product.product);
      }
    };

    fetchData();
  }, [product, fetchUserProduct]);

  return (
    <Box pt={{ base: '0px', md: '0px', xl: '0px' }}>
      <SimpleGrid
        my="20px"
        columns={{ sm: 1, lg: 2 }}
        spacing={{ base: '20px', xl: '20px' }}
      >
        {categories.map((category: Category) => (
          <Flex direction="column" key={category.category_id}>
            <CustomCard h="100%" py="24px" px="24px">
              <Text fontSize="xl" fontWeight="bold" mb="10px">
                {category.category_display_name}
              </Text>
              <Text fontSize="md" color="gray.500" mb="20px">
                {category.category_description}
              </Text>
              {/* Render a MultiSelect component for each category */}
              <MultiSelect category={category} policyTypes={null} width="30vw" />
            </CustomCard>
          </Flex>
        ))}
      </SimpleGrid>
    </Box>
  );
}
