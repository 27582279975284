
export default function HandleFileChange(
    newFiles: FileList, 
    loading: boolean, 
    selectedFiles: FileList,
    displayToast: (title: string, description: string, status: "info" | "warning" | "success" | "error", duration: number) => void
    ): FileList | null {

    const maxTotalSize = 1 * 1024 * 1024;    
    const allowedTypes = [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'message/rfc822',
    ];
    const filesArray = Array.from(newFiles);

    const currentTotalSize = selectedFiles ? Array.from(selectedFiles).reduce(
        (acc, file) => acc + file.size,
        0
    ) : 0;
    const newTotalSize = filesArray.reduce(
        (acc, file) => acc + file.size,
        currentTotalSize
    );    
    const validFiles = filesArray.filter((file) =>
        allowedTypes.includes(file.type)
    );
    if (loading) {
        displayToast('File Upload in Progress', 'Please wait until the current file upload is completed.', 'warning', 5000);
        return null;
    }
    if (newTotalSize > maxTotalSize) {
        displayToast('File Size Limit Exceeded', 'Total file size exceeds 1 MB. Please select smaller files.', 'warning', 5000);
        return null;
    }
    if (validFiles.length !== filesArray.length) {
        displayToast('Invalid File Type', 'Only PDF, DOCX and EML files are allowed.', 'warning', 5000);
        return null;
    }
    if (validFiles.length > 5) {
        displayToast('File Limit Exceeded', 'Maximum 5 files can be uploaded at a time.', 'warning', 5000);
        return null;
    }
    const dataTransfer = new DataTransfer();
    [...(selectedFiles ? Array.from(selectedFiles) : []), ...validFiles].forEach(file => dataTransfer.items.add(file));
    return dataTransfer.files;
}
