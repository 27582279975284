export function extractTrend(inputString: string): { trend: string, percentage: number } | null {
    const regex = /([↑↓])\s([\d.]+%)/;

    const match = inputString.match(regex);

    if (match) {
        const arrow = match[1];
        const percentageString = match[2];
        const percentage = parseFloat(percentageString.replace('%', ''));
        let trend: 'positive' | 'negative' | 'neutral';
        if (arrow === '↑') {
            trend = 'positive';
        } else if (arrow === '↓') {
            trend = 'negative';
        } else {
            trend = 'neutral';
        }
        return { trend, percentage };
    } else {
        return null;
    }
}
