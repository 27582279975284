import { useState } from "react";

const socketUrl = `${process.env.REACT_APP_LLM_WS_URL}/ws-get-policy-review`;

let socket: WebSocket;
let reconnectAttempts = 0;
const maxReconnectAttempts = 5;
const reconnectDelay = 3000; // 3 seconds

interface MessageEvent {
  data: any;
  type: string;
  target: WebSocket;
}

type MessageCallback = (event: MessageEvent) => void;

const onMessageCallbacks: MessageCallback[] = [];

const createWebSocket = () => {
  socket = new WebSocket(socketUrl);
  socket.binaryType = "arraybuffer";

  socket.onopen = () => {
    console.log("socket opened");
    reconnectAttempts = 0; // Reset reconnect attempts on successful connection
  };

  socket.onmessage = (event) => {
    if (event.data instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        const combinedBuffer = new Uint8Array(reader.result as ArrayBuffer);
        const messageEvent: MessageEvent = {
          data: combinedBuffer,
          type: event.type,
          target: event.target as WebSocket
        };
        onMessageCallbacks.forEach((callback) => callback(messageEvent));
      };
      reader.readAsArrayBuffer(event.data);
    } else {
      try {
        console.log("received message", event.data);
        const message = JSON.parse(new TextDecoder().decode(event.data.toString()));
        const messageEvent: MessageEvent = {
          data: message,
          type: event.type,
          target: event.target as WebSocket
        };
        onMessageCallbacks.forEach((callback) => callback(messageEvent));
      } catch (e) {
        console.log("non JSON message received. ", event.data);
        const messageEvent: MessageEvent = {
          data: event.data,
          type: event.type,
          target: event.target as WebSocket
        };
        onMessageCallbacks.forEach((callback) => callback(messageEvent));
      }
    }
  };

  socket.onerror = (error) => {
    console.error("socket error:", error);
  };

  socket.onclose = () => {
    console.log("socket closed");
    attemptReconnect();
  };
};

const attemptReconnect = () => {
  if (reconnectAttempts < maxReconnectAttempts) {
    reconnectAttempts++;
    console.log(`Reconnection attempt ${reconnectAttempts}...`);
    setTimeout(() => {
      createWebSocket();
    }, reconnectDelay);
  } else {
    console.error("Max reconnection attempts reached. Could not reconnect to WebSocket.");
  }
};

createWebSocket();

const sendMessage = (data: string | number | readonly any[] | Buffer | ArrayBuffer | Uint8Array | SharedArrayBuffer | DataView | ArrayBufferView | readonly number[] | { valueOf(): ArrayBuffer; } | { valueOf(): SharedArrayBuffer; } | { valueOf(): Uint8Array; } | { valueOf(): readonly number[]; } | { valueOf(): string; } | { [Symbol.toPrimitive](hint: string): string; }) => {
  console.log("sending message", data);
  console.log("socket ready state", socket.readyState);
  
  const send = () => {
    if (data instanceof Uint8Array) {
      socket.send(data);
    } else {
      socket.send(JSON.stringify(data));
    }
  };

  if (socket.readyState === WebSocket.OPEN) {
    send();
  } else {
    console.error('WebSocket connection is not open, attempting to reopen');
    socket.onopen = () => {
      console.log("socket reopened");
      send();
    };
    socket.onerror = (error) => {
      console.error("socket error during reopen attempt:", error);
    };
    socket.onclose = () => {
      console.log("socket closed during reopen attempt");
    };
  }
};

const addMessageListener = (callback: any) => {
  onMessageCallbacks.push(callback);
};

const closeSocket = () => {
  socket.close();
}

export { sendMessage, addMessageListener, closeSocket };
