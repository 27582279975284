import React from 'react';
import {
  Box, Flex, Text,
} from '@chakra-ui/react';
import { AdminFile } from '../../../models/files';
import CombinedPageSelection from './combinedPageSelection';
import { buildEditFileTooltip, deleteTooltip, fileDetailIconWithTooltipPending, fileName, imageAvailabilityTooltip, uploadedDate, noFilesToDisplay } from './common';

export function renderPendingFilesList(
    totalPendingCount: number, 
    handleOnPageChangeApproved: (page: number) => void, 
    handleOnPageChangePending: (page: number) => void, 
    pendingPageNo: number, 
    pendingTotalPages: number, 
    view: string, 
    pendingLoading: boolean, 
    filteredPendingFiles: { [key: string]: AdminFile[] }, 
    handlePendingFileClick: (fileName: string, displayName: string, documentId: number) => void, 
    handleEditFileName: (editedFile: AdminFile) => void, 
    handleOpenDeleteAlert: (fileName: string) => void,
    handleOnPageSizeChange: (size: number) => void
  ) {
  return <>
  {Object.keys(filteredPendingFiles).length > 0 && (
    <>
      <Text fontWeight="bold" mb={1} fontSize="lg">
        Total Pending Files: {totalPendingCount}
      </Text>
      {totalPendingCount > 0 && (
        <CombinedPageSelection handleOnPageChangeApproved={handleOnPageChangeApproved} handleOnPageChangePending={handleOnPageChangePending} pageNo={pendingPageNo} totalPages={pendingTotalPages} view={view} pendingLoading={pendingLoading} handleOnPageSizeChange={handleOnPageSizeChange} />
      )}
    </>
  )}
    {Object.keys(filteredPendingFiles).length ? (
      Object.keys(filteredPendingFiles).map(
        (group, groupIndex) => (
          <Box
            key={groupIndex}
            mb={4}
            className={pendingLoading ? 'pulse' : ''}
          >
            <Text fontWeight="bold" fontSize="md" mb={2}>
              {group}
            </Text>
            {filteredPendingFiles[group].map(
              (file: AdminFile, fileIndex: number) => (
                <Flex
                  key={fileIndex}
                  alignItems="center"
                  justifyContent="space-between"
                  mb={2}
                >
                  {fileName(handlePendingFileClick, file)}
                  <Flex alignItems="center">
                    <>
                      {file.last_modified_time && (
                        uploadedDate(file)
                      )}
                      {imageAvailabilityTooltip(file)}
                      {buildEditFileTooltip(handleEditFileName, file)}
                      {fileDetailIconWithTooltipPending(handlePendingFileClick, file)}
                      {deleteTooltip(handleOpenDeleteAlert, file)}
                    </>
                  </Flex>
                </Flex>
              )
            )}
          </Box>
        )
      )
    ) : (
      <>
        {noFilesToDisplay('pending')}
      </>
    )}
  </>;
}
