import React from 'react';
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react';
import { RenderFileListTabsProps } from './FilelIst';

export function renderFileListTabs({ view, setView }: RenderFileListTabsProps) {
  return (
    <Tabs
      index={view === 'pending' ? 0 : view === 'approved' ? 1 : 2}
      onChange={(index) => setView(index === 0 ? 'pending' : index === 1 ? 'approved' : 'add')}
        >
      <TabList>
        <Tab>Pending Approval</Tab>
        <Tab>Approved</Tab>
        <Tab>Add Files</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>{/* Content for Approved Queue */}</TabPanel>
        <TabPanel>{/* Content for Pending Approval */}</TabPanel>
        <TabPanel>{/* Content for Add Files */}</TabPanel>
      </TabPanels>
    </Tabs>
  );
}
