import React, { useState, useRef, useEffect } from "react";
import {
  RegulatorySortFilters,
  SortFilter,
  SortSelection,
} from "../../../../../models/complianceMetric";

interface HeaderFilterProps {
  header: string;
  filterType:
    | "Change"
    | "Regulator"
    | "Type"
    | "Date"
    | "Updates Required"
    | "Policy Updates"
    | "Material Updates"
    | "Status"
    | "Jurisdiction"
    | "Impact";
  regulatorFilter?: { name: string; selected: boolean }[];
  jurisdictionFilter?: { name: string; selected: boolean }[];
  onFilterChange: (value: any) => void;
  onSortChange?: (value: SortSelection) => void;
}

const HeaderFilter: React.FC<HeaderFilterProps> = ({
  header,
  filterType,
  regulatorFilter,
  jurisdictionFilter,
  onFilterChange,
  onSortChange,
}) => {
  const [checkboxOptions, setCheckboxOptions] = useState<
    { name: string; selected: boolean }[]
  >([]);
  const [sortOptions, setSortOptions] = useState<SortFilter[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [sortFilterOptions, setSortFilterOptions] =
    useState<RegulatorySortFilters>(
      JSON.parse(localStorage.getItem("RegSortOptions"))
    );

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setSortFilterOptions(JSON.parse(localStorage.getItem("RegSortOptions")));
    populateFilterOptions();
    if (filterType === "Regulator") {
      setCheckboxOptions(
        localStorage.getItem("regulators")
          ? JSON.parse(localStorage.getItem("regulators") as string)
          : []
      );
    } else if (filterType === "Jurisdiction") {
      setCheckboxOptions(
        localStorage.getItem("jurisdictions")
          ? JSON.parse(localStorage.getItem("jurisdictions") as string)
          : []
      );
    }
  }, []);

  const populateFilterOptions = () => {
    setSortFilterOptions(JSON.parse(localStorage.getItem("RegSortOptions")));
    switch (filterType) {
      case "Change":
        setSortOptions(
          sortFilterOptions?.Change ? sortFilterOptions.Change : []
        );
        break;
      case "Regulator":
        setSortOptions(
          sortFilterOptions?.Regulator ? sortFilterOptions.Regulator : []
        );
        setCheckboxOptions(
          localStorage.getItem("regulators")
            ? JSON.parse(localStorage.getItem("regulators") as string)
            : []
        );
        break;
      case "Type":
        setSortOptions(sortFilterOptions?.Type ? sortFilterOptions.Type : []);
        // setCheckboxOptions(
        //   localStorage.getItem("type")
        //     ? JSON.parse(localStorage.getItem("type") as string)
        //     : []
        // );
        break;
      case "Date":
        setSortOptions(sortFilterOptions?.Date ? sortFilterOptions.Date : []);
        break;
      case "Updates Required":
        setSortOptions(
          sortFilterOptions?.UpdatesRequired
            ? sortFilterOptions.UpdatesRequired
            : []
        );
        break;
      case "Status":
        setSortOptions(
          sortFilterOptions?.Status ? sortFilterOptions.Status : []
        );
        break;
      case "Jurisdiction":
        setSortOptions(
          sortFilterOptions?.Jurisdiction ? sortFilterOptions.Jurisdiction : []
        );
        setCheckboxOptions(
          localStorage.getItem("jurisdictions")
            ? JSON.parse(localStorage.getItem("jurisdictions") as string)
            : []
        );
        break;
      case "Impact":
        setSortOptions(sortFilterOptions?.Impact ? sortFilterOptions.Impact : []);
        // setCheckboxOptions(
        //   localStorage.getItem("impactLevels")
        //     ? JSON.parse(localStorage.getItem("impactLevels") as string)
        //     : []
        // );
        break;
      default:
        break;
    }
  };

  const handleRadioChange = (value: SortFilter) => {
    switch (value.header) {
      case "Change":
        const updatedChangeOptions = sortFilterOptions.Change.map((option) => ({
          ...option,
          value: option.value === value.value,
        }));
        sortFilterOptions.Change = updatedChangeOptions;
        setSortOptions(updatedChangeOptions);
        localStorage.setItem(
          "RegSortOptions",
          JSON.stringify(sortFilterOptions)
        );
        break;
      case "Regulator":
        const updatedRegulatorOptions = sortFilterOptions.Regulator.map(
          (option) => ({
            ...option,
            value: option.value === value.value,
          })
        );
        sortFilterOptions.Regulator = updatedRegulatorOptions;
        setSortOptions(updatedRegulatorOptions);
        localStorage.setItem(
          "RegSortOptions",
          JSON.stringify(sortFilterOptions)
        );
        break;
      case "Type":
        const updatedTypeOptions = sortFilterOptions.Type.map((option) => ({
          ...option,
          value: option.value === value.value,
        }));
        sortFilterOptions.Type = updatedTypeOptions;
        setSortOptions(updatedTypeOptions);
        localStorage.setItem(
          "RegSortOptions",
          JSON.stringify(sortFilterOptions)
        );
        break;
      case "Date":
        const updatedDateOptions = sortFilterOptions.Date.map((option) => ({
          ...option,
          value: option.value === value.value,
        }));
        sortFilterOptions.Date = updatedDateOptions;
        setSortOptions(updatedDateOptions);
        localStorage.setItem(
          "RegSortOptions",
          JSON.stringify(sortFilterOptions)
        );
        
        
        break;
      case "UpdatesRequired":
        const updatedUpdatesRequiredOptions =
          sortFilterOptions.UpdatesRequired.map((option) => ({
            ...option,
            value: option.value === value.value,
          }));
        sortFilterOptions.UpdatesRequired = updatedUpdatesRequiredOptions;
        setSortOptions(updatedUpdatesRequiredOptions);
        localStorage.setItem(
          "RegSortOptions",
          JSON.stringify(sortFilterOptions)
        );
        break;
      case "Status":
        const updatedStatusOptions = sortFilterOptions.Status.map((option) => ({
          ...option,
          value: option.value === value.value,
        }));
        sortFilterOptions.Status = updatedStatusOptions;
        setSortOptions(updatedStatusOptions);
        localStorage.setItem(
          "RegSortOptions",
          JSON.stringify(sortFilterOptions)
        );
        break;
      case "Jurisdiction":
        const updatedJurisdictionOptions = sortFilterOptions.Jurisdiction.map(
          (option) => ({
            ...option,
            value: option.value === value.value,
          })
        );
        sortFilterOptions.Jurisdiction = updatedJurisdictionOptions;
        setSortOptions(updatedJurisdictionOptions);
        localStorage.setItem(
          "RegSortOptions",
          JSON.stringify(sortFilterOptions)
        );
        break;
      case "Impact":
        const updatedImpactOptions = sortFilterOptions.Impact.map((option) => ({
          ...option,
          value: option.value === value.value,
        }));
        sortFilterOptions.Impact = updatedImpactOptions;
        setSortOptions(updatedImpactOptions);
        localStorage.setItem(
          "RegSortOptions",
          JSON.stringify(sortFilterOptions)
        );
        break;
      default:
        break;
    }
    const sortHeader = value.header;
    const sortValue = value.labels;
    switch (sortValue) {
      case "A to Z":
      case "Newer":
      case "Asc":
        onSortChange({ header: sortHeader, value: "ASC" });
        break;
      case "Z to A":
      case "Older":
      case "Desc":
        onSortChange({ header: sortHeader, value: "DESC" });
        break;
      default:
        break;
    }
    // onFilterChange(header, value);
  };

  const handleCheckboxChange = (value: { name: string; selected: boolean }) => {
    const newCheckboxes = checkboxOptions.map((option) =>
      option.name === value.name
        ? { ...option, selected: !option.selected }
        : option
    );
    setCheckboxOptions(newCheckboxes);

    onFilterChange(newCheckboxes);

    if (filterType === "Regulator") {
      localStorage.setItem("regulators", JSON.stringify(newCheckboxes));
    } else if (filterType === "Jurisdiction") {
      localStorage.setItem("jurisdictions", JSON.stringify(newCheckboxes));
    }
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      className="header-filter"
      style={{ position: "relative", display: "inline-block" }}
    >
      <label
        onClick={toggleDropdown}
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        {header.charAt(0).toUpperCase() + header.slice(1)}{" "}
        <svg
          fill="#FFFFFF"
          width="16px"
          height="16px"
          viewBox="0 0 36 36"
          version="1.1"
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginLeft: "5px" }}
        >
          <title>filter-line</title>
          <path
            className="clr-i-outline clr-i-outline-path-1"
            d="M33,4H3A1,1,0,0,0,2,5V6.67a1.79,1.79,0,0,0,.53,1.27L14,19.58v10.2l2,.76V19a1,1,0,0,0-.29-.71L4,6.59V6H32v.61L20.33,18.29A1,1,0,0,0,20,19l0,13.21L22,33V19.5L33.47,8A1.81,1.81,0,0,0,34,6.7V5A1,1,0,0,0,33,4Z"
          ></path>
          <rect x="0" y="0" width="6" height="6" fill-opacity="0" />
        </svg>
      </label>
      {isOpen && (
        <div
          ref={dropdownRef}
          className="filter-dropdown"
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            background: "white",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: 10,
            minWidth: "150px",
            overflow: "visible",
            whiteSpace: "nowrap",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            maxHeight: "200px",
            overflowY: "auto",
            color: "black",
          }}
        >
          {filterType === "Date" && (
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label htmlFor="from-date" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  From:
                </label>
                <input
                  type="date"
                  id="from-date"
                  defaultValue={localStorage.getItem('from') || ''}
                  onChange={(e) => onFilterChange({ from: e.target.value })}
                  style={{ padding: "5px", fontSize: "14px" }}
                />
                </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label htmlFor="to-date" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  To:
                </label>
                <input
                  type="date"
                  id="to-date"
                  defaultValue={localStorage.getItem('to') || ''}
                  onChange={(e) => onFilterChange({ to: e.target.value })}
                  style={{ padding: "5px", fontSize: "14px" }}
                />
              </div>
            </div>
          )}
          {sortOptions.map((option, index) => (
            <div key={index} className="filter-option">
              <input
                type="radio"
                id={`filter-${header}-${index}`}
                name={`filter-${header}`}
                value={option.value.toString()}
                checked={option.value}
                onChange={() => handleRadioChange(option)}
              />
              <label
                htmlFor={`filter-${header}-${index}`}
                style={{ fontSize: "14px", fontWeight: "bold" }}
              >
                <i
                  className={`fa ${
                    option.labels === "A to Z"
                      ? "fa-sort-alpha-asc"
                      : option.labels === "Z to A"
                      ? "fa-sort-alpha-desc"
                      : option.labels === "Newer"
                      ? "fa-sort-numeric-asc"
                      : option.labels === "Older"
                      ? "fa-sort-numeric-desc"
                      : option.labels === "Asc"
                      ? "fa-sort-amount-asc"
                      : option.labels === "Desc"
                      ? "fa-sort-amount-desc"
                      : ""
                  }`}
                />
                {option.labels === "Reviewed"
                  ? " Reviewed"
                  : option.labels === "Unreviewed"
                  ? " Unreviewed"
                  : option.labels === "Low"
                  ? " Low"
                  : option.labels === "Medium"
                  ? " Medium"
                  : option.labels === "High"
                  ? " High"
                  : ""}
              </label>
            </div>
          ))}
          {sortOptions && checkboxOptions.length > 0 && (
            <hr
              style={{
                margin: "10px 0",
                border: "none",
                borderTop: "1px solid black",
                width: "100%",
              }}
            />
          )}

          {checkboxOptions?.map((option, index) => (
            <div key={index} className="filter-option">
              <input
                type="checkbox"
                id={`filter-${header}-${index}`}
                value={option.selected.toString()}
                checked={option.selected}
                onChange={() => handleCheckboxChange(option)}
              />
              <label
                htmlFor={`filter-${header}-${index}`}
                style={{ fontSize: "14px", fontWeight: "bold" }}
              >
                {option.name}
              </label>
            </div>
          ))}
          {checkboxOptions.length !== 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <button
                onClick={() => {
                  const clearedCheckboxes = checkboxOptions.map((option) => ({
                    ...option,
                    selected: false,
                  }));
                  setCheckboxOptions(clearedCheckboxes);
                  onFilterChange(clearedCheckboxes);

                  if (filterType === "Regulator") {
                    localStorage.setItem(
                      "regulators",
                      JSON.stringify(clearedCheckboxes)
                    );
                  } else if (filterType === "Jurisdiction") {
                    localStorage.setItem(
                      "jurisdictions",
                      JSON.stringify(clearedCheckboxes)
                    );
                  }
                }}
                style={{ padding: "5px 10px", cursor: "pointer" }}
              >
                Clear
              </button>
              <button
                onClick={() => {
                  const allSelectedCheckboxes = checkboxOptions.map(
                    (option) => ({
                      ...option,
                      selected: true,
                    })
                  );
                  setCheckboxOptions(allSelectedCheckboxes);
                  onFilterChange(allSelectedCheckboxes);

                  if (filterType === "Regulator") {
                    localStorage.setItem(
                      "regulators",
                      JSON.stringify(allSelectedCheckboxes)
                    );
                  } else if (filterType === "Jurisdiction") {
                    localStorage.setItem(
                      "jurisdictions",
                      JSON.stringify(allSelectedCheckboxes)
                    );
                  }
                }}
                style={{ padding: "5px 10px", cursor: "pointer" }}
              >
                Select All
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HeaderFilter;
