import React from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import MessageBox from "../../../../components/MessageBox";
import useDisplayToast from "../../../../utils/DisplayToast";
import { DownloadPolicyCard } from "./downloadPolicyCard";

interface PolicyOutputCardProps {
  screenHeight: number;
  screenWidth: number;
  textColor: string;
  outputCode: string[];
  borderColor: string;
  responseLoading: boolean;
  message: string;
  endOfMessage: boolean;
  resetPage: () => void;
  updatedPdfLink: string;
  redlinePdfLink: string;
}

export const PolicyOutputCard: React.FC<PolicyOutputCardProps> = ({
  screenHeight,
  screenWidth,
  textColor,
  outputCode,
  borderColor,
  responseLoading,
  message,
  endOfMessage,
  resetPage,
  updatedPdfLink,
  redlinePdfLink,
}) => {
  const displayToast = useDisplayToast();
  return (
    <Card
      minW={screenWidth <= 768 ? "90vw" : "15vw"}
      minHeight={
        screenWidth <= 768 ? "90vh" : screenHeight < 900 ? "95vh" : "85vh"
      }
      maxHeight={
        screenWidth <= 768 ? "85vh" : screenHeight < 900 ? "95vh" : "75vh"
      }
      ml={screenWidth <= 768 ? "-10px" : "0"}
    >
      <Text fontSize={"30px"} color={textColor} fontWeight="800" mb="10px">
        Policy Output
      </Text>
      <Text fontSize={"16px"} color="gray.500" fontWeight="500" mb="30px">
        All policies are considered drafts and must be reviewed by appropriate
        internal committees before being implemented
      </Text>

      <DownloadPolicyCard 
        textColor={textColor}
        updatedPdfLink={updatedPdfLink}
        redlinePdfLink={redlinePdfLink}
        screenWidth={screenWidth}
      /> 
      <MessageBox
        output={outputCode}
        height={
          updatedPdfLink || redlinePdfLink ? "50vh" : "60vh"
        }
        loading={responseLoading}
        statusText={message}
      />
      <style>{`
            @keyframes fadeIn {
                from {
                opacity: 0;
                }
                to {
                opacity: 1;
                }
            }
            `}</style>
      <Flex
        direction="row"
        align="center"
        justify="flex-end"
        gap="5px"
        h="100%"
        pb="8px"
        mt="auto"
      >
        <Button
          variant="solid"
          borderRadius="full"
          maxW="160px"
          fontSize="md"
          w={{ base: "150px", md: "210px" }}
          h="54px"
          onClick={() => {
            resetPage();
          }}
          isDisabled={!endOfMessage}
        >
          Reset
        </Button>
        <Button
          variant="solid"
          border="1px solid"
          borderColor={borderColor}
          borderRadius="full"
          maxW="160px"
          fontSize="md"
          w={{ base: "150px", md: "210px" }}
          h="54px"
          onClick={() => {
            if (outputCode)
              navigator.clipboard.writeText(outputCode.join("\n"));
            displayToast(
              outputCode
                ? "Copied to clipboard"
                : "Generate some content first!",
              "",
              "success",
              3000
            );
          }}
          isDisabled={!endOfMessage}
        >
          Copy text
        </Button>
      </Flex>
    </Card>
  );
};
