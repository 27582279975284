import React, { useEffect } from "react";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { FaArrowsLeftRight } from "react-icons/fa6";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { MonthwiseValue } from "../../../../../../../models/complianceMetric";

interface WidgetTemplateProps {
  title: string;
  change: number;
  trend: string;
  description: string;
  graphData: MonthwiseValue[];
  feature: string;
}

export default function WidgetTemplate({
  title,
  change,
  trend,
  description,
  graphData,
  feature,
}: WidgetTemplateProps) {
  const [trendColor, setTrendColor] = React.useState<string>("");
  const [dataToDisplay, setDataToDisplay] = React.useState("");

  useEffect(() => {
    showChangeType();
  }, []);

  useEffect(() => {
    switch (trend) {
      case "positive":
        setTrendColor("green");
        break;
      case "negative":
        setTrendColor("red");
        break;
      case "neutral":
        setTrendColor("#e9bf24");
        break;
      default:
        break;
    }
  }, [trend]);

  const trendArrow = () => {
    switch (trend) {
      case "positive":
        return <FaArrowUp color="white" />;
      case "negative":
        return <FaArrowDown color="white" />;
      case "neutral":
        return <FaArrowsLeftRight color="white" />;
      default:
        break;
    }
  };
  const showChangeType = () => {
    switch (feature) {
      case "High_Impact_Rules_Published":
      case "High_Impact_Rules_Proposed":
      case "Enforcement_Actions_on_Peers":
        setDataToDisplay("regulatory_changes");
        break;
      case "Policy_Updates_Required":
        setDataToDisplay("policy_changes");
        break;
      case "Training_Updates_Required":
        setDataToDisplay("material_changes");
        break;
      default:
        break;
    }
  };
  const cardContent = (
    <Flex
      my="auto"
      h={{ base: "60vh", md: "20vw" }}
      direction={{ base: "column", md: "column", lg: "row" }}
      align="center"
      justify="center"
      className="content"
    >
      <Box
        w={{ base: "90vw", sm: "70vw", md: "50vw", lg: "30vw", xl: "20vw" }}
        h={{ base: "90vw", sm: "70vw", md: "50vw", lg: "20vw", xl: "15vw" }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        zIndex="1"
        cursor="pointer"
        bg={trendColor}
        color="white"
        mx="10px"
        borderRadius="10px"
        p="10px"
      >
        <h1
          style={{ fontSize: "18px", fontWeight: "bold", textAlign: "center" }}
        >
          {title}
        </h1>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize={"24px"}
          flex="1"
        >
          {trendArrow()}
          <h2>{change}%</h2>
        </Box>
        <Box mt="auto">
          <p style={{ alignSelf: "flex-end", fontSize: "12px" }}>
            {description}
          </p>
        </Box>
      </Box>
      <Box
        w={{ base: "90vw", sm: "70vw", md: "50vw", lg: "30vw", xl: "20vw" }}
        h={{ base: "90vw", sm: "70vw", md: "50vw", lg: "30vw", xl: "15vw" }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        zIndex="1"
        cursor="pointer"
        border={`2px solid ${trendColor}`}
        borderRadius="10px"
        mx="10px"
        my="10px"
        p="10px"
      >
        <h1
          style={{ fontSize: "18px", fontWeight: "bold", textAlign: "center" }}
        >
          {title}
        </h1>
        {graphData.length > 0 && dataToDisplay !== "" && (
          <LineChart
            width={
              window.innerWidth < 768
                ? (70 * window.innerWidth) / 100
                : window.innerWidth < 992
                ? (50 * window.innerWidth) / 100
                : window.innerWidth < 1200
                ? (30 * window.innerWidth) / 100
                : (12 * window.innerWidth) / 100
            }
            height={
              window.innerWidth < 768
                ? (50 * window.innerWidth) / 100
                : window.innerWidth < 992
                ? (45 * window.innerWidth) / 100
                : window.innerWidth < 1200
                ? (25 * window.innerWidth) / 100
                : (12 * window.innerWidth) / 100
            }
            data={graphData}
            margin={{ top: 15, left: -15, right: 28, bottom: 5 }}
          >
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <XAxis
              dataKey="name"
              tickFormatter={(tick) => {
                const date = new Date(tick);
                if (!isNaN(date.getTime())) {
                  return date.toLocaleString("default", { month: "short" });
                } else {
                  return tick;
                }
              }}
              tick={{ fontSize: 12 }}
            />
            <YAxis tick={{ fontSize: 12 }} />
            <Tooltip
              formatter={(value, name) => {
                switch (dataToDisplay) {
                  case "regulatory_changes":
                    return [
                      `${value} Regulatory Changes`,
                      "Regulatory Changes",
                    ];
                  case "policy_changes":
                    return [`${value} Policy Changes`, "Policy Changes"];
                  case "material_changes":
                    return [`${value} Material Changes`, "Material Changes"];
                  default:
                    return [value, name];
                }
              }}
            />
            {dataToDisplay === "regulatory_changes" && (
              <Line
                type="monotone"
                dataKey="value.regulatory_changes"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            )}
            {dataToDisplay === "policy_changes" && (
              <Line
                type="monotone"
                dataKey="value.policy_changes"
                stroke="#82ca9d"
              />
            )}
            {dataToDisplay === "material_changes" && (
              <Line
                type="monotone"
                dataKey="value.material_changes"
                stroke="#ffc658"
              />
            )}
          </LineChart>
        )}
        {graphData.length === 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize={"24px"}
            flex="1"
          >
            <Spinner />
          </Box>
        )}
      </Box>
    </Flex>
  );

  return <Box>{cardContent}</Box>;

}
