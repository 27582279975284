import { PolicyReviewResponse } from './../../models/policyReviewResponse';
import axios from 'axios';

const API_URL = process.env.REACT_APP_LLM_API_URL;

export const getPolicyTypes = async (token: string) => {
    const response = await axios.get(`${API_URL}/get-policy-types`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const getPolicyReview = async (
    token: string, 
    session_id: string,
    user_id: string,
    org_id: string,
    policy_types: string,
    additional_instructions: string,
    file: File[]
): Promise<PolicyReviewResponse> => {
    const formData = new FormData();
    formData.append("session_id", session_id);
    formData.append("user_id", user_id);
    formData.append("org_id", org_id);
    formData.append("policy_types", policy_types);
    if (additional_instructions !== "") {
        formData.append("additional_instructions", additional_instructions);
    }
    file.forEach(file => {
        formData.append("file", file);
    });
    const response = await axios.post(`${API_URL}/get-policy-review`, 
        formData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}