import {
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { IconType } from "react-icons";
import { MdAutoAwesome, MdBolt, MdPerson, MdSunny } from "react-icons/md";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import "../chat.css";
import { useMediaQuery } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import messageContent from "./messageContent";

export const customRenderers = {
  a: ({
    href,
    children,
    ...props
  }: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="custom-link"
      {...props}
    >
      {children}
    </a>
  ),
};

export const Messages: React.FC<any> = ({
  handleSendMessage,
  tabValue,
  selectedFiles,
  sendButtonClicked,
  progressArray,
  flexRef,
  attachedQuestion,
  regulatoryChangeData,
}) => {
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.200");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("navy.700", "white");

  let SelectedIcon: IconType;
  if (tabValue === 1) {
    SelectedIcon = MdAutoAwesome;
  } else if (tabValue === 2) {
    SelectedIcon = MdBolt;
  } else if (tabValue === 3) {
    SelectedIcon = MdSunny;
  } else {
    SelectedIcon = MdPerson;
  }

  const inputColor = useColorModeValue("navy.700", "white");

  const divRef = useRef<HTMLDivElement>(null);
  const messages = useSelector((state: RootState) => state.messages);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const location = useLocation();

  const [isChat, setIsChat] = useState(false);

  const [regChangeSkipScroll, setRegChangeSkipScroll] = useState(true);

  useEffect(() => {
    if (location.pathname === "/compliance/chat") {
      setIsChat(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (shouldScrollToBottom) {
        divRef.current.scrollTop = divRef.current.scrollHeight;
      }
    };
    if (!regChangeSkipScroll || isChat) {
      scrollToBottom();
    }
    setRegChangeSkipScroll(false);
  }, [messages, shouldScrollToBottom, sendButtonClicked]);

  useEffect(() => {
    const handleScroll = () => {
      if (divRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = divRef.current;
        if (scrollTop < scrollHeight - clientHeight) {
          setShouldScrollToBottom(false);
        }
      }
    };

    const divElement = divRef.current;
    if (divElement) {
      divElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (divElement) {
        divElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return messageContent(divRef, isChat, tabValue, regulatoryChangeData, messages, isMobile, borderColor, brandColor, textColor, SelectedIcon, sendButtonClicked, selectedFiles, flexRef, inputColor, progressArray, attachedQuestion, profile, handleSendMessage);
};

export default Messages;
