/* eslint-disable prefer-const */
import { Flex, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import axios from 'axios';
import { useAbortController } from 'hooks/AbortControllerContext';
import useAuth from 'hooks/auth';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SEOComponent from 'seo';
import { setInputMessage } from 'store/reducers/inputMessage';
import {
  addMessage,
  clearMessages,
  updateLastMessage,
} from 'store/reducers/messages';
import { newSessionFound } from 'store/reducers/sessionSlice';
import { RootState } from 'store/store';
import { v4 as uuidv4 } from 'uuid';
import LogInModal from 'views/shared/component/LogInModal';
import AIModel from './AIModel';
import Divider from './Divider';
import ChatFooter from './Footer';
import Messages from './Messages/Messages';
import { extractAndRemoveUUID } from './utils';
import { RegulatoryChangeDetailResponse, RegulatoryChangeDetail } from '../../../models/regChangeModels';
import { getRegulatoryChangeDetail } from '../../../services/RegChangesService/regChangesService';
import useDisplayToast from '../../../utils/DisplayToast';
import GetNewSessionId from '../../../utils/sessionUtils/sessionId';

export default function Chat() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialTab = parseInt(queryParams.get('tab') || '1', 10);
  const [tabValue, setTabValue] = useState(initialTab);
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const dispatch = useDispatch();
  const messages = useSelector((state: RootState) => state.messages);
  const inputMessage = useSelector((state: RootState) => state.inputMessage);
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const [newChat, setIsNewChat] = useState(false);
  const ws = useRef<WebSocket | null>(null);
  const { ensureValidToken } = useAuth();
  const [ websocketErrorToast, setWebsocketErrorToast ] = useState(false);
  const [ regulatoryChangeData, setRegulatoryChangeData ] = useState<RegulatoryChangeDetail>();
  const flexRef = useRef<HTMLDivElement>(null);
  const [sessionId, setSessionId] = useState(null);
  const { abortController, setAbortController } = useAbortController();
  const [isChat, setIsChat] = useState(false);
  const displayToast = useDisplayToast();
  const [isLongPage] = useMediaQuery("(min-height: 1444px)");

  useEffect(() => {
    const fetchData = async () => {
      if (location.pathname === '/compliance/chat') {
        setIsChat(true);
      } else {
        fetchCurrentChange();    
      }
    };

    fetchData();
  }, [location.pathname]);

  const fetchCurrentChange = async () => {
    const token = await ensureValidToken(localStorage.getItem('token'));
    await getRegulatoryChangeDetail(token,  Number(localStorage.getItem('currentChange')))
      .then(async (response: RegulatoryChangeDetailResponse) => {
        if (response !== null || response !== undefined) {
          setRegulatoryChangeData(response?.data[0]);
        }
        
      });
  }

  const handleSendWSMessage = () => {
    if (!ws.current) return;
    let intervalId: ReturnType<typeof setInterval> | null = null;
    const bufferArray: string[] = [];

    ws.current.onmessage = (event) => {
      if (sessionStorage.getItem('session') === sessionId) {
        const message = event.data;
        let {
          uuid: answer_uuid,
          cleanedText,
          prompt,
        } = extractAndRemoveUUID(message);

        if (cleanedText.endsWith('<END>')) {
          if (
            process.env.REACT_APP_NODE_ENV === 'dev' ||
            process.env.REACT_APP_NODE_ENV === 'local'
          ) {
            console.info('PROMPT =================');
            console.info(prompt);
            console.info('=========================');
          }
          setLoading(false);
          if (newChat) {
            dispatch(newSessionFound());
            setIsNewChat(false);
          }
        }

        bufferArray.push(cleanedText);

        if (intervalId === null) {
          intervalId = setInterval(() => {
            if (bufferArray.length > 0) {
              const textToRender = bufferArray.shift();
              if (textToRender !== undefined) {
                dispatch(
                  addMessage({
                    from: 'computer',
                    text: textToRender,
                    uu_id: answer_uuid,
                  })
                );
              }
            } else {
              clearInterval(intervalId);
              intervalId = null;
            }
          }, 33);
        }
      }
    };
  };

  const [sendButtonClicked, setSendButtonClicked] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [attachedQuestion, setAttachedQuestion] = useState(null);
  const [progressArray, setProgressArray] = useState<number[]>(
    Array(selectedFiles.length).fill(0)
  );
  const [isDragging, setIsDragging] = useState(false);
  const maxTotalSize = 1 * 1024 * 1024;

const handleFileChange = (newFiles: FileList | File[]) => {
  if (loading) {
    displayToast('File Upload in Progress', 'Please wait until the current file upload is completed.', 'warning', 5000);
    return;
  }

  const filesArray = Array.from(newFiles);

  const currentTotalSize = selectedFiles.reduce(
    (acc, file) => acc + file.size,
    0
  );
  const newTotalSize = filesArray.reduce(
    (acc, file) => acc + file.size,
    currentTotalSize
  );
  if (newTotalSize > maxTotalSize) {
    displayToast('File Size Limit Exceeded',`Total file size exceeds 1 MB. Please select smaller files.`,'warning',5000);
    return;
  }
  const allowedTypes = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  const validFiles = filesArray.filter((file) =>
    allowedTypes.includes(file.type)
  );

  if (validFiles.length !== filesArray.length) {
    displayToast('Invalid File Type','Only PDF, and DOCX are allowed.','warning',5000);
    return;
  }

  const nonDuplicateFiles = validFiles.filter(
    (file) =>
      !selectedFiles.some(
        (selectedFile) =>
          selectedFile.name === file.name && selectedFile.size === file.size
      )
  );

  if (nonDuplicateFiles.length !== validFiles.length) {
    displayToast('Duplicate Files Detected','Some files are already selected and will not be added again.','warning',5000);
  }

  if (nonDuplicateFiles.length > 5) {
    displayToast('File Upload Limit Exceeded','You can only upload a maximum of 5 files at a time. Please remove some files and try again.','warning',5000);
    return;
  }

  setSelectedFiles((prevFiles) => [...prevFiles, ...nonDuplicateFiles]);
};

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    if (isChat) {
      event.preventDefault();
      setIsDragging(true);
    }  
  };

  const handleDragLeave = () => {
    if (isChat) {
      setIsDragging(false);
    }  
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (isChat) {
      event.preventDefault();
    const files = event.dataTransfer.files;
    setIsDragging(false);
    handleFileChange(files);
    }
  };

  const handleRemoveFile = (fileName: string) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );
  };

  const simulateSlowerProgress = (
    actualProgress: number,
    setProgress: (value: number) => void
  ) => {
    let currentProgress = 0;
    const interval = setInterval(() => {
      if (currentProgress < Math.min(actualProgress * 90, 90)) {
        currentProgress += 1;
        setProgress(currentProgress);
      } else {
        clearInterval(interval);
      }
    }, 50);
    return () => clearInterval(interval);
  };

  const handleSendFiles = async () => {
    setLoading(true);
    if (selectedFiles.length > 0) {
      for (let index = 0; index < selectedFiles.length; index++) {
        const element = selectedFiles[index];
        let stopSimulation: (() => void) | null = null;

        try {
          setProgressForFile(index, 0);

          const fileUpload = await handleUpload(element, (actualProgress) => {
            if (stopSimulation) stopSimulation();
            stopSimulation = simulateSlowerProgress(
              actualProgress,
              (progress) => {
                setProgressForFile(index, progress);
              }
            );
          });

          if (fileUpload) {
            if (stopSimulation) stopSimulation();
            setProgressForFile(index, 100);
          } else {
            if (stopSimulation) stopSimulation();
            setProgressForFile(index, 0);
            console.error(`File ${element.name} failed to upload.`);
            break;
          }
        } catch (error) {
          if (stopSimulation) stopSimulation();
          setProgressForFile(index, 0);
          console.error(`Error uploading file ${element.name}:`, error);
          break;
        }

        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    }

    setLoading(false);
  };

  const handleUpload = async (
    file: File,
    onProgress: (progress: number) => void
  ) => {
    if (file) {
      const token = await ensureValidToken(localStorage.getItem('token'));

      try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post(
          `${process.env.REACT_APP_LLM_API_URL}/upload-user-file-to-azure?&user_id=${profile?.user_id}&session_id=${sessionId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
            signal: abortController?.signal,
            onUploadProgress: (progressEvent) => {
              const percentCompleted = progressEvent.total
                ? (progressEvent.loaded / progressEvent.total) * 100
                : 0;
              onProgress(percentCompleted);
            },
          }
        );

        if (response.data.success === true) {
          console.info('FILE upload done');
          return true;
        } else {
          displayToast('Upload Failed', response.data?.data || 'Something went wrong.', 'error', 3000);
          return false;
        }
      } catch (error: any) {
        if (error.message === 'canceled') {
          console.info('Request canceled:', error.message);
          displayToast('Upload Canceled', 'The upload was canceled due to a tab change or user action.', 'warning', 3000);
        } else {
          console.error('Error uploading file:', error);
          displayToast('Upload Failed', 'Failed to upload the file. Please try again later.', 'error', 3000);
         }
        return false;
      }
    } else {
      console.info('No file selected or no partner/persona selected.');
      return false;
    }
  };

  const setProgressForFile = (index: number, value: number) => {
    setProgressArray((prevProgress) => {
      const updatedProgress = [...prevProgress];
      updatedProgress[index] = value; 
      return updatedProgress;
    });
  };

  const handleSendMessage = async (messageToSend?: string) => {
    const message = messageToSend || inputMessage;
    let finalQuestion = '';
    const endTag = '</FILETAGEND>';

    if (message.trim().length > 0 && selectedFiles.length > 0) {
      dispatch(setInputMessage(''));
      setAttachedQuestion(message);
      await handleSendFiles();
      setAttachedQuestion(null);
      setSendButtonClicked(false);
      if (flexRef?.current?.innerHTML) {
        dispatch(
          addMessage({
            from: 'me',
            text: `${message}<FILETAGSTART>${flexRef?.current?.innerHTML}</FILETAGEND>`,
          })
        );
      }
      if (isChat) {
        setSelectedFiles([]);
        setProgressArray([]);
      }
      finalQuestion = `${message}<FILETAGSTART>${flexRef?.current?.innerHTML}</FILETAGEND>`;
      setLoading(false);
    } else if (selectedFiles.length > 0) {
      await handleSendFiles();
      setSendButtonClicked(false);
      if (flexRef?.current?.innerHTML) {
        dispatch(
          addMessage({
            from: 'me',
            text: `<FILETAGSTART>${flexRef?.current?.innerHTML}</FILETAGEND>`,
          })
        );
      }
      if (isChat) {
        setSelectedFiles([]);
        setProgressArray([]);
      }
      setLoading(false);
      return;
    } else {
      const lastMessage = messages[messages.length - 1];

      let lastPDF = '';

      if (lastMessage?.text?.trim().endsWith(endTag)) {
        lastPDF = lastMessage?.text;
        finalQuestion = message + lastPDF;
        if (!message?.trim().length) {
          return;
        }
        dispatch(setInputMessage(''));
        dispatch(updateLastMessage({ from: 'me', text: finalQuestion }));
      } else {
        if (!message?.trim().length) {
          return;
        }
        finalQuestion = message + lastPDF;
        dispatch(setInputMessage(''));
        dispatch(addMessage({ from: 'me', text: lastPDF + message }));
      }
    }

    if (!ws) {
      connectWebSocket();
    }
    if (
      !ws.current ||
      ws.current.readyState === WebSocket.CLOSING ||
      ws.current.readyState === WebSocket.CLOSED
    ) {
      return;
    }

    if (!profile?.idToken || !profile?.user_id) {
      login();
      return;
    }

    const tabFromSession = parseInt(sessionStorage.getItem('activeTab') || '1');

    let partnerId, partner, persona;
    if (tabFromSession === 2) {
      partnerId = process.env.REACT_APP_STATE_PARTNER_ID;
      partner = process.env.REACT_APP_STATE_PARTNER;
      persona = process.env.REACT_APP_STATE_PERSONA;
    } else if (tabFromSession === 1) {
      partnerId = process.env.REACT_APP_PARTNER_ID;
      partner = process.env.REACT_APP_PARTNER;
      persona = process.env.REACT_APP_PERSONA;
    } else if (tabFromSession === 3) {
      partnerId = process.env.REACT_APP_CANADA_PARTNER_ID;
      partner = process.env.REACT_APP_CANADA_PARTNER;
      persona = process.env.REACT_APP_CANADA_PERSONA;
    }

    if (
      ws.current.readyState === WebSocket.CLOSING ||
      ws.current.readyState === WebSocket.CLOSED
    ) {
      console.warn(
        'WebSocket is closing or closed. Attempting to reconnect...'
      );
      displayToast('WebSocket Disconnected', 'Reconnecting...', 'warning', 3000);
      console.info('Connect ws 2')
      connectWebSocket();
      return;
    }

    const uu_id = uuidv4().toString();
    dispatch(addMessage({ from: 'computer', text: ' ', uu_id: uu_id }));
    const token = await ensureValidToken(null);
    const filename = regulatoryChangeData?.document_name;
    const summary = regulatoryChangeData?.summary || '';
    const impact_level = regulatoryChangeData?.impact_level || '';
    const requestBody = chatRequestBody( finalQuestion, endTag, uu_id, sessionId, token, partnerId, partner, persona, filename, summary, impact_level);
    ws.current.send(requestBody);
    handleSendWSMessage();
    };
  
  function chatRequestBody( finalQuestion: string, endTag: string, uu_id: string, sessionId: string, token: string, partnerId: string, partner: string, persona: string, filename: string, summary: string, impact_level: string) {
    if (isChat) {
      return JSON.stringify({
        is_from_user_document: finalQuestion.endsWith(endTag),
        partner_id: partnerId,
        partner: partner,
        persona: persona,
        UUId: uu_id,
        question: finalQuestion,
        user_id: profile?.user_id,
        session_id: sessionId,
        checklist_array: null,
        access_token: token,
        org_id: profile?.org_id
      });
    } else {
      return JSON.stringify({
        is_from_user_document: finalQuestion.endsWith(endTag),
        filename: filename,
        partner_id: partnerId,
        partner: partner,
        persona: persona,
        UUId: uu_id,
        question: finalQuestion,
        user_id: profile?.user_id,
        session_id: sessionId,
        access_token: token,  
        summary: summary,
        impact_level: impact_level,
        org_id: profile?.org_id
      });     
      }
    }

  const chatHeight = useBreakpointValue({
    base: isChat ? '80vh' : '50vh',
    sm: isChat ? '85vh' : '50vh',
    md: isChat ? '85vh' : '50vh',
    xl: isChat ? '78vh' : '50vh',
    '2xl': isChat ? isLongPage ? '97vh' : '83vh' : '50vh',
  });

  function waitUntilOpen(socket: WebSocket) {
    return new Promise<void>((resolve) => {
      if (socket.readyState === WebSocket.OPEN) {
        resolve();
      } else {
        socket.addEventListener("open", () => resolve());
      }
    });
  }

  const connectWebSocket = (retryCount = 3) => {
    setTimeout(() => {
      if (localStorage.getItem('authentication') === null) {
        return console.info('User is not authenticated');
      }
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        console.info('WebSocket is already connected');
        return;
      }
      const time = new Date();
      console.info(time, 'Connecting WebSocket');
      const newWs = new WebSocket(
        isChat
          ? `${process.env.REACT_APP_LLM_WS_URL}/ws-get-answer`
          : `${process.env.REACT_APP_LLM_WS_URL}/ws-get-regulatory-summary`
      );

      newWs.onopen = () => {
        waitUntilOpen(newWs).then(() => {
          console.info("WebSocket connection established.");
        });
      };

      newWs.onclose = () => {
        console.info('WebSocket disconnected');
      };

      newWs.onerror = (error) => {
        console.info('WebSocket error:', time);
        console.error('WebSocket error:', error);
        if (retryCount > 0) {
          console.info(`Retrying WebSocket connection (${3 - retryCount + 1}/3)`);
          setTimeout(() => connectWebSocket(retryCount - 1), 1000);
        } else {
          if (!websocketErrorToast) {
            setWebsocketErrorToast(true);
            console.info('Failed to connect websocket.');
            displayToast('Connection Problem','Connection Problem. Please Refresh the Page..','error',3000);
          }
        }
      };

      ws.current = newWs;

      return () => {
        if (ws.current) {
          ws.current.close();
        }
      };
    }, 500);
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && !ws.current) {
        // You may want to reconnect WebSocket or perform other actions here
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const getSessionData = async (session_id: string) => {
    const token = await ensureValidToken(localStorage.getItem('token'));

    const getSessionURL = `${process.env.REACT_APP_LLM_API_URL}/get-session-data`;
    const requestBody = {
      session_id: session_id,
    };
    const response = await axios.post(getSessionURL, requestBody, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  };

  const fetchData = async (sessionId: string, dispatch: (action: ReturnType<typeof addMessage> | ReturnType<typeof clearMessages>) => void) => {
    if (sessionId) {
      try {
        const old_chat = await getSessionData(sessionId);
        dispatch(clearMessages());
        old_chat.content.forEach((message: { role: string; content: string }) => {
          const { role, content } = message;
          if (role === 'user') {
            dispatch(addMessage({ from: 'me', text: content }));
          } else if (role === 'assistant') {
            dispatch(addMessage({ from: 'computer', text: content }));
          }
        });
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    }
  };

  const clearIntervalFunc = () => {
    const interval_id = window.setInterval(function () { /* no-op */ },
    Number.MAX_SAFE_INTEGER);

    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
  };
  const startNewSession = () => {
    const newSessionId = GetNewSessionId();
    setIsNewChat(true);
    setSessionId(newSessionId);
  };

  const closeCurrentWSConnection = () => {
    if (ws.current) {
      ws.current.close();
      ws.current.onmessage = null;
      ws.current = null;
    }
  };
  const handleTabChange = (newTab: number) => {
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    sessionStorage.setItem('activeTab', newTab.toString());
    closeCurrentWSConnection();
    clearIntervalFunc();
    startNewSession();
    setSelectedFiles([]);
    setProgressArray([]);
    setAttachedQuestion(null);
    setLoading(false); 
    setSendButtonClicked(false);
    dispatch(setInputMessage(''));
    dispatch(clearMessages());
    setTabValue(newTab);
  };

  const messagesSection = () => {
    return <Messages
              handleSendMessage={handleSendMessage}
              tabValue={tabValue}
              selectedFiles={selectedFiles}
              attachedQuestion={attachedQuestion}
              sendButtonClicked={sendButtonClicked}
              progressArray={progressArray}
              flexRef={flexRef}
              regulatoryChangeData={regulatoryChangeData}
            />
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionIdFromURL = queryParams.get('sessionId');
    const tabValueFromURL = parseInt(queryParams.get('tab') || '1', 10);

    dispatch(clearMessages());
    setSelectedFiles([]);
    setAttachedQuestion(null);
    setProgressArray([]);
    setLoading(false);
    setSendButtonClicked(false);

    if (sessionIdFromURL) {
      dispatch(setInputMessage(''));
      setSessionId(sessionIdFromURL);
      setTabValue(tabValueFromURL);
      sessionStorage.setItem('session', sessionIdFromURL);
      fetchData(sessionIdFromURL, dispatch);
    } else {
      startNewSession();
    }
    closeCurrentWSConnection();
    clearIntervalFunc();
    connectWebSocket();

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [location, abortController]);

  const chatfooter = () => {
    return <ChatFooter
              setSendButtonClicked={setSendButtonClicked}
              sendButtonClicked={sendButtonClicked}
              handleSendMessage={handleSendMessage}
              handleRemoveFile={handleRemoveFile}
              handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFileChange(e.target.files)
              }
              selectedFiles={selectedFiles}
              loading={loading}
            />
  }

  return (
    <div
      className={`chat-section ${isDragging ? 'dragging' : ''}`} 
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <SEOComponent
      title="NuComply - Compliance Expert Assistant"
      description="Experience the future of compliance management with nucomply, and empower your institution with the tools needed to confidently navigate the complex regulatory landscape."
      canonical="/compliance/chat"
      />
      {isChat &&
      <AIModel tab={tabValue} onTabChange={handleTabChange} />
      }
      <Flex w="100%" h={chatHeight} justify="center" align="center">
        <Flex
          w={['95%', '100%', '85%', '95%', '70%']}
          mx="auto"
          maxW={isChat ? "58rem" : undefined}
          h={{base: isChat ? "90%" : "125vh", sm: isChat ? "90%" : "100vh", md: isChat ? "90%" : "125vh", xl: isChat ? "90%" : "125vh", '2xl': isChat ? "90%" : "125vh"}}
          mt= {{sm: isChat ? "null" : "12vh"}}
          flexDir="column"
        >
          {!isChat && (regulatoryChangeData !== null) && messagesSection() }
          {!isChat && (regulatoryChangeData !== undefined) && <Divider />}
          {!isChat && (regulatoryChangeData !== undefined) && chatfooter()}
          {isChat && messagesSection()} 
          {isChat && <Divider />}        
          {isChat && chatfooter()}
        </Flex>
      </Flex>
      {!profile?.user_id && <LogInModal />}
    </div>
  );
}
