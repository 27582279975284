import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import useAuth from "hooks/auth";
import { useController, useForm } from "react-hook-form";
import useDisplayToast from "../../../../../utils/DisplayToast";
import * as yup from "yup";
import { setInstitutionProfileForNuComply } from '../../../../../services/UserService/userService';
import "./styles.css";
import { MultiSelectDropdown } from './multiSelectDropdown';
import { PolicyType } from '../../../../../models/policy';
import { Category, Product } from '../../../../../models/products';

const schema = yup.object().shape({
  signupReasons: yup
    .array()
    .required("You must select")
    .min(0, "Please pick at least")
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    ),
});

interface MultiSelectProps {
  category: Category;
  policyTypes?: PolicyType[];
  width: string;
  onSubmitPolicies?: (values: any) => void;
  closeAfterSelection?: boolean;
  onSelectAll?: (policies: any[]) => void;
  onReset?: () => void;
  disabled?: boolean;
  resetClicked?: boolean;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  category,
  policyTypes = [],
  width,
  onSubmitPolicies,
  closeAfterSelection,
  onSelectAll,
  onReset,
  disabled,
  resetClicked,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      signupReasons: category?.products
        ? category.products
            .filter((product: any) => product.active)
            .map((product: any) => ({ label: product.name, value: product.id }))
        : [],
    },
  });
  const [hideSelectAll, setHideSelectAll] = React.useState(false);

  useEffect(() => {
    if (resetClicked) {
      reset();
    }
  }
  , [resetClicked]);

  useEffect(() => {
    if (policyTypes?.length > 0) {
      setHideSelectAll(true);
    }
  }
  , [policyTypes]);

  const { fetchUserProduct, ensureValidToken } = useAuth();
  const displayToast = useDisplayToast();

  const onSubmit = async (values: any): Promise<void> => {
    if (category) {
      onSubmitCategory(values);
    } else {
      onSubmitPolicies(values);
    }
  };

  const onSubmitCategory = async (values: {
    signupReasons: { label: string; value: string }[];
  }) => {
    const token = await ensureValidToken(localStorage.getItem("token"));
    const selectedIds = values.signupReasons.map((item) =>
      parseInt(item.value, 10)
    );
    const droppedActiveIds = selectedOptions
      .filter((option) => !selectedIds.includes(parseInt(option.value, 10)))
      .map((option) => parseInt(option.value, 10));
    try {
      await setInstitutionProfileForNuComply(
        token,
        selectedIds,
        droppedActiveIds
      ).then((response) => {
        if (response.success === true) {
          displayToast(
            "Products Updated",
            "Selected products have been updated successfully.",
            "success",
            3000
          );
        } else {
          displayToast(
            "Error",
            "An error occurred while updating products.",
            "error",
            3000
          );
        }
      });
      await fetchUserProduct();
    } catch (error) {
      console.error("Error updating products:", error);
    }
  };

  const handleReset = () => {
    reset({
      signupReasons: [],
    });
    onReset && onReset();
  };

  const handleSelectAll = () => {
    const allOptions = [
      ...(category?.products?.map((product: any) => ({
        label: product.name,
        value: product.id,
      })) || []),
      ...((Array.isArray(policyTypes) ? policyTypes : []).map(
        (policy: PolicyType) => ({
          label: policy.name,
          value: policy.id,
        })
      ) || []),
    ];
    setValue("signupReasons", allOptions);
    if (policyTypes) {
      onSelectAll(allOptions);
    }
  };

  const {
    field: signupReasonsField,
    fieldState: { error: signupReasonsError },
  } = useController({
    name: "signupReasons",
    control,
  });

  const allOptions = [
    ...(category?.products?.map((product: Product) => ({
      label: product.name,
      value: product.id,
    })) || []),
    ...((Array.isArray(policyTypes) ? policyTypes : []).map(
      (policy: PolicyType) => ({
        label: policy.name,
        value: policy.id,
      })
    ) || []),
  ].filter((option) => option.label && option.value);

  const selectedOptions = [
    ...(category?.products
      ?.filter((product: any) => product.active)
      .map((product: any) => ({ label: product.name, value: product.id })) ||
      []),
    ...(policyTypes?.map((policy: any) => ({
      label: policy.name,
      value: policy.id,
    })) || []),
  ];
  const isAssetSizeSelection =
    category?.category_name === "AssetSizeSelection" || false;

  return (
    <>
      {(category?.products.length > 0 || policyTypes.length > 0) && (
        <MultiSelectDropdown
          width={width}
          signupReasonsError={signupReasonsError}
          category={
            category?.products.length > 0 ? category : { products: policyTypes }
          }
          isAssetSizeSelection={isAssetSizeSelection}
          allOptions={allOptions}
          signupReasonsField={signupReasonsField}
          isSubmitting={isSubmitting}
          handleReset={handleReset}
          handleSelectAll={handleSelectAll}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          showSubmitButton={policyTypes?.length > 0 ? false : true}
          closeAfterSelection={closeAfterSelection}
          disabled={disabled}
          resetClicked={resetClicked}
          hideSelectAll={hideSelectAll}
        />
      )}
    </>
  );
};

export default MultiSelect;