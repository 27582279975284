import React from 'react';
import { Icon } from '@chakra-ui/react';
import {
  MdManageAccounts,
  MdOutlineLabel,
  MdOutlineList,
} from 'react-icons/md';
import FileList from 'views/admin/filelists/FilelIst';
import PromptList from 'views/admin/prompts/Prompts';
import Tags from 'views/admin/tags/Tags';
import UserManagement from './views/admin/userManagement/userManagement';

const routes = [
  {
    name: 'File list',
    layout: '/admin',
    path: '/files',
    icon: (
      <Icon as={MdOutlineList} width="20px" height="20px" color="inherit" />
    ),
    component: FileList,
    collapse: false,
    rightElement: true,
    plan: "Basic",
    showOnSidebar: true,
  },
  {
    name: 'Prompts',
    layout: '/admin',
    path: '/prompts',
    icon: (
      <Icon as={MdManageAccounts} width="20px" height="20px" color="inherit" />
    ),
    component: PromptList,
    collapse: false,
    rightElement: true,
    plan: "Basic",
    showOnSidebar: true,
  },
  {
    name: 'Tags',
    layout: '/admin',
    path: '/tags',
    icon: (
      <Icon as={MdOutlineLabel} width="20px" height="20px" color="inherit" />
    ),
    component: Tags,
    collapse: false,
    rightElement: true,
    plan: "Basic",
    showOnSidebar: true,
  },
  {
    name: 'User Management',
    layout: '/admin',
    path: '/user-management',
    icon: (
      <Icon as={MdManageAccounts} width="20px" height="20px" color="inherit" />
    ),
    component: UserManagement,
    collapse: false,
    rightElement: true,
    plan: "Basic",
    showOnSidebar: true,
  }
];

export default routes;
