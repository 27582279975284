import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Chat from '../../../ask-question/chat';

interface DrillDownProps {
    id: number;
}

const DrillDown: React.FC<DrillDownProps> = ({id}) => {
    const setCurrentChange = () => {
        localStorage.setItem('currentChange', id?.toString());
        localStorage.setItem('currentChangeOpen', 'true');  
    }

    const isMobile = window.innerWidth < 768;

    useEffect(() => {
        setCurrentChange();
    }, [id]);

    return (
    <Box p={isMobile ? 1 : 16} maxW={isMobile ? "full" : "15xl"} mx="auto">
      <Flex
        w="100%"
        direction="column"
        position="relative"
      >
        <Chat/>
      </Flex>          

    </Box>
    );
};

export default DrillDown;