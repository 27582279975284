import React, { Suspense } from 'react';
import '../../styles/style_nucomply_landing.css';

const About = React.lazy(() => import("./about"));
const Banner = React.lazy(() => import("./banner"));
const Nav = React.lazy(() => import("./nav"));
const Features = React.lazy(() => import("./features"));
const Footer = React.lazy(() => import("./footer"));

export default function LandingPage() {
  return (
    <>
      <Suspense fallback={<div>Loading Nav...</div>}>
        <Nav />
      </Suspense>
      <Suspense fallback={<div>Loading Banner...</div>}>
        <Banner />
      </Suspense>
      <Suspense fallback={<div>Loading Features...</div>}>
        <Features />
      </Suspense>
      <Suspense fallback={<div>Loading About...</div>}>
        <About />
      </Suspense>
      <Suspense fallback={<div>Loading Footer...</div>}>
        <Footer />
      </Suspense>
    </>
  );
}