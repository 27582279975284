import { Flex, Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Metrics } from "../../../../../models/complianceMetric";
import SEOComponent from "../../../../../seo";
import { ensureValidTokenExternal, getComplianceMetricsListExternal } from "../../../../../services/RegChangesService/regExternalService";
import WidgetLogic from "./Widgets/WidgetLogic/WidgetLogic";


function RegulatoryChangeReviewDashboard() {
  const profile = JSON.parse(localStorage.getItem('authentication'));
  const [widgets, setWidgets] = useState<Metrics[]>([]);



  const getWidgets = async () => {
    const email = profile?.user.email;
        const org_id = profile?.org_id;
        const widgets = await getComplianceMetricsListExternal(await ensureValidTokenExternal(localStorage.getItem('access_token'), email, org_id));
        return widgets;
  }

  useEffect(() => {
    getWidgets().then((data) => {
      data && setWidgets(data.data);
    });
  }, []);

  return (
    <>
      <SEOComponent
        title="NuComply-Regulatory-Changes-Dashboard"
        description="Banking Compliance Expert Provided Solutions"
        canonical="/compliance/solutions/regulatory"
      />
      <Flex mt={{ base: "-20px", md: "110px" }} mx="25px">
        <Box mt={{ base: "70px", md: "0px", xl: "0px" }}>
          <SimpleGrid 
            columns={{ base: 1, md: 1, lg: 2, xl: 3 }} 
            spacingY="5px">
            {widgets.map((widget, index) => (
                <WidgetLogic
                  key={index}
                  title={widget.title}
                  feature={widget.feature}
                  filter={widget.filter}
                />
            ))}
          </SimpleGrid>
        </Box>
      </Flex>
    </>
  );
}

export default RegulatoryChangeReviewDashboard;
