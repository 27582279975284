import React from "react";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, useColorModeValue, useMediaQuery } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import PageSelect from "../../../components/pageSelect/pageSelect";
import PageSizeSelection from "../../../components/pageSizeSelection/pageSizeSelection";
import useAuth from "../../../hooks/auth";
import { UserDetails } from "../../../models/auth";
import { User } from "../../../models/user";
import { getUsers } from "../../../services/UserService/userService";
import AccessAlert from "../AccessAlert/AccessAlert";
import { SearchInput } from "../filelists/searchFileInput";
import { UserList } from "./userList";

const UserManagement = () => {
    const profile: UserDetails = localStorage.getItem("authentication") ? JSON.parse(localStorage.getItem("authentication") as string) : null;
    const inputTextColor = useColorModeValue('black', 'white');
    const [ users, setUsers ] = useState<User[]>([]);
    const [ filteredUsers, setFilteredUsers ] = useState<User[]>([]);
    const [ pageNo, setPageNo ] = useState(1);
    const [ pageSize, setPageSize ] = useState(10);
    const [ search, setSearch ] = useState('');
    const { ensureValidToken } = useAuth();
    const searchType = 'user'; 
    const [isTablet] = useMediaQuery("(max-width: 1200px)");
    const [isMobile] = useMediaQuery("(max-width: 768px)");
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const width = isTablet ? "90vw" : isMobile ? "20vw" : "30vw";

    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
    useEffect(() => {
        fetchUsers();
    }, []);

    const handleSearch = (value: string) => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            setSearch(value);
        }, 1000);
    };

    useEffect(() => {
        fetchUsers();
        if (search === '') {
            setFilteredUsers(users);
            return;
        } else {
            setFilteredUsers(users.filter(user => 
                user.user_name?.toLowerCase().includes(search?.toLowerCase())
            ));
        }
        
        setFilteredUsers(filteredUsers);
    }, [pageNo, pageSize, search]);
    
    const handleModalAction = () => {
        fetchUsers();
    }
    const pageSizeOptions = [
        { value: 10, label: "10" },
        { value: 25, label: "25" },
        { value: 50, label: "50" },
        { value: 75, label: "75" },
        { value: 100, label: "100" },
      ];

    const onPageSizeChange = (size: number) => {
        setPageNo(1);
        setPageSize(size);
    }

    const onPageNoChange = (page: number) => {
        setPageNo(page);
    }

    const fetchUsers = async () => {
        const token = await ensureValidToken(localStorage.getItem("token"));
        try {
            setLoading(true);
            await getUsers(
                token,
                profile.org_id,
                pageNo,
                pageSize,
                search
            ).then((response) => {
                setUsers(response.data.data);
                setFilteredUsers(response.data.data);
                setTotalPages(Math.ceil(response.data.count_doc / pageSize) || 1);
            }
            ).finally(() => {
                setLoading(false);
            });
      
        }
        catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const [accordionOpen, setAccordionOpen] = useState(false);

    return (
        <Box p={isMobile ? "0" : "8"} mx="auto" mt="50px">
            { profile ? (
                profile.role_name !== 'Admin' ? (
                    <AccessAlert />
                ) : (
                    <>
                        <Flex
                            w="99%"
                            direction={isTablet ? "column" : "row"}
                            position="relative"
                            justifyContent="space-between"
                            alignItems={isTablet ? "" : "center"}
                            mb={4}
                            mr={10}
                        >
                            {isMobile ? (
                                <Box w="100%">
                                    <Accordion 
                                        paddingBottom={5}
                                        allowToggle onChange={() => setAccordionOpen(!accordionOpen)}>
                                        <AccordionItem>
                                            <AccordionButton>
                                                <Box flex="1" textAlign="left">
                                                    Search & Filters
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                            <AccordionPanel pb={4}>
                                                <SearchInput
                                                    searchType={searchType}
                                                    search={search}
                                                    handleSearch={handleSearch}
                                                    inputTextColor={inputTextColor}
                                                />
                                                <PageSizeSelection
                                                    options={pageSizeOptions}
                                                    onPageSizeChange={(size: number) => onPageSizeChange(size)}
                                                    mt={4}
                                                />
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Box>
                            ) : (
                                <>
                                    <SearchInput
                                        searchType={searchType}
                                        search={search}
                                        handleSearch={handleSearch}
                                        inputTextColor={inputTextColor}
                                        width={width}
                                    />
                                    <PageSizeSelection
                                        options={pageSizeOptions}
                                        onPageSizeChange={(size: number) => onPageSizeChange(size)}
                                        w={200}
                                        ml={5}
                                        mr={0}
                                        mt={0}
                                    />
                                </>
                            )}
                            <PageSelect
                                currentPage={pageNo}
                                totalPages={totalPages}
                                loading={false}
                                onPageChange={onPageNoChange}
                                w={500}
                                ml={5}
                                mr={0}
                                mt={10}
                            />
                        </Flex>
                        <UserList
                            users={filteredUsers}
                            loading={loading}
                            accordionOpen={accordionOpen}
                            onModalAction={handleModalAction}
                        />
                    </>
                )
            ) : null}
        </Box>
    );
};

export default UserManagement;