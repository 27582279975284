'use client';
import { Box } from '@chakra-ui/react';
import CheckTable from 'components/tables/CheckTable';
import React from 'react';

export default function SearchChat() {
  return (
    <Box
      mt={{ base: '0px', md: '50px', xl: '100px' }}
      mb={{ base: '0px', md: '80px', xl: '160px' }}
    >
      <CheckTable />
    </Box>
  );
}
