import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface regChangeState {
    currentFileOpen: boolean;
    currentFile: string | null;
    newRegsCount: number;
    currentMetricSelected: string;
}

const initialState: regChangeState = {
    currentFileOpen: false,
    currentFile: null,
    newRegsCount: 0,
    currentMetricSelected: "High Impact Rules Published",
};

const regChangeSlice = createSlice({
    name: 'regChange',
    initialState,
    reducers: {
        setCurrentFileOpen(state, action: PayloadAction<boolean>) {
            state.currentFileOpen = action.payload;
        },
        setCurrentFile(state, action: PayloadAction<string | null>) {
            state.currentFile = action.payload;
        },
        setNewRegsCount(state, action: PayloadAction<number>) {
            state.newRegsCount = action.payload;
        },
        setCurrentMetricSelected(state, action: PayloadAction<string>) {
            state.currentMetricSelected = action.payload;
        }
    },
});

export const { setCurrentFileOpen, setCurrentFile, setNewRegsCount, setCurrentMetricSelected } = regChangeSlice.actions;

export default regChangeSlice.reducer;