import { createSlice } from '@reduxjs/toolkit';

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: [],
  reducers: {
    addMessage: (state, action) => {
      if (action.payload.from === 'me') {
        state.push(action.payload);
      } else if (action.payload.from === 'computer') {
        if (state.length > 0 && state[state.length - 1].from === 'me') {
          state.push(action.payload);
        } else {
          const index = state.findIndex(
            (message) => message.uu_id === action.payload.uu_id
          );
          if (index !== -1) {
            state[index].text = action.payload.text;
            state[index].complete = action.payload.complete;
          } else {
            state.push(action.payload);
          }
        }
      }
    },
    clearMessages: (state) => {
      state.splice(0, state.length);
    },
    updateLastMessage: (state, action) => {
      if (state.length > 0) {
        state[state.length - 1] = {
          ...state[state.length - 1],
          ...action.payload,
        };
      }
    },
  },
});

export const { addMessage, clearMessages, updateLastMessage } =
  messagesSlice.actions;

export default messagesSlice.reducer;
