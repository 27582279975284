/*eslint-disable*/

import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';

export default function Footer() {
  const textColor = useColorModeValue('gray.400', 'white');
  const [isVeryHighRes] = useMediaQuery("(min-width: 3000px)");
  const screenWidth = window.innerWidth;
  return (
    <Flex
      display={{ sm: 'flex', md: 'flex', xl: 'flex' }}
      zIndex="3"
      flexDirection='row'
      alignItems={{
        base: 'center',
        xl: 'start',
      }}
      justifyContent="space-between"
      px={{ base: '30px', md: '50px' }}
      pt={screenWidth <= 768 ? "100px" : "30px"}
      minWidth={{ base: '100vw', sm: '100vw', md: '100vw', xl: '80vw', '2xl': isVeryHighRes ? '92vw' : '87vw' }}
      ml="auto"
      mr="auto"
    >
      <Text
        color={textColor}
        textAlign={{
          base: 'center',
          xl: 'start',
        }}
        mb={{ base: '10px', sm: '0px', xl: '0px' }}
        ml="1vw"
        fontSize={{sm: `sm`}}
      >
        {' '}
        &copy; {new Date().getFullYear()}
        <Text as="span" fontWeight="500" ms="4px">
          <Link
            mx="3px"
            color={textColor}
            href="https://www.nuarca.com/"
            target="_blank"
            fontWeight="700"
          >
            NuArca Labs
          </Link>
        </Text>
      </Text>
      <List display="flex" ml="auto" mr="1vw" fontSize={{sm: 'sm'}} >
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link
            fontWeight="500"
            color={textColor}
            href="mailto:support@nucomply.com"
          >
            Support
          </Link>
        </ListItem>
        <ListItem>
          <Link
            fontWeight="500"
            color={textColor}
            href={`${window.location.origin}/compliance/privacy`}
          >
            Privacy
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
