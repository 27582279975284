import { RegulatoryChangesSortField } from "../../models/regChangeModels";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

export const authorizeRegulatoryExternal = async (
  email: string,
  org_id: number
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_LLM_API_URL}/authorize`,
      { email, org_id },
      {
        headers: {
          accept: "application/json",
          api_key: process.env.REACT_APP_EXTERNAL_API_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    localStorage.setItem("access_token", response.data.data.access_token);
    return response.data;
  } catch (error) {
    console.error("Error authorizing regulatory change:", error);
  }
};

export const refreshRegulatoryExternal = async (access_token: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_LLM_API_URL}/refresh-token`,
      {
        access_token: access_token,
      },
      {
        headers: {
          Accept: "application/json",
          ContentType: "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error refreshing regulatory change:", error);
  }
};

export const getInstitutionProfileRegulatoryExternal = async (
  access_token: string
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_LLM_API_URL}/get-institution-profile`,
      { access_token },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting institution profile:", error);
  }
};

export const saveInstitutionProfileRegulatoryExternal = async (
  access_token: string,
  added_product_ids: number[],
  removed_product_ids: number[]
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_LLM_API_URL}/save-institution-profile`,
      {
        access_token,
        added_product_ids,
        removed_product_ids,
      },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error saving institution profile:", error);
  }
};

export const getRegulatoryChangeListExternal = async (
  access_token: string,
  page: number,
  limit: number,
  search: string,
  filter: string,
  sort_fields: RegulatoryChangesSortField[],
  regulators?: string,
  jurisdiction?: string,
  start_date?: string,
  end_date?: string
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_LLM_API_URL}/get-regulatory-change-list`,
      {
        page,
        limit,
        search,
        filter,
        sort_fields,
        regulators,
        jurisdiction,
        start_date,
        end_date,
      },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching regulatory changes:", error);
  }
};

export const getRegulatoryChangeExternal = async (
  access_token: string,
  reg_change_id: number
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_LLM_API_URL}/get-regulatory-change-details`,
      { reg_change_id },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching regulatory change:", error);
  }
};

export const getComplianceMetricsListExternal = async (
  access_token: string
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_LLM_API_URL}/compliance-metrics-list`,
      {},
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching compliance metrics:", error);
  }
};

export const getComplianceMetricsSummaryExternal = async (
  access_token: string,
  feature: string,
  filter: string,
  start_date: string,
  end_date: string
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_LLM_API_URL}/compliance-metrics-summary`,
      { feature, filter, start_date, end_date },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching compliance metrics summary:", error);
  }
};

export const createOrganizationExternal = async (
  access_token: string,
  org_name: string
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_LLM_API_URL}/create-organization`,
      { org_name },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating organization:", error);
  }
};

export const assignAppuserToOrganizationExternal = async (
  access_token: string,
  email: string,
  org_id: number
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_LLM_API_URL}/assign-appuser-to-organization`,
      { email, org_id },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error assigning appuser to organization:", error);
  }
};

export const ensureValidTokenExternal = async (
  token: string | null,
  email: string,
  org_id: number
): Promise<string> => {
  try {
    if (!token || token === "undefined") {
      const response = await authorizeRegulatoryExternal(email, org_id);
      token = response.data.access_token;
    }
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp > currentTime) {
      return token;
    } else if (decoded.exp < currentTime - 1800) {
      const response = await authorizeRegulatoryExternal(email, org_id);
      token = response.data.access_token;
      localStorage.setItem("access_token", token);
      return token;
    } else {
      await refreshRegulatoryExternal(token).then((response) => {
        localStorage.setItem("access_token", response.data.access_token);
        return response.data.access_token;
      });
    }
    return token;
  } catch (error) {
    console.error("Error renewing token:", error);
    throw error;
  }
};
