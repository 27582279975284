import { Box, Flex, Stack } from '@chakra-ui/react';
import Brand from 'components/sidebarAdmin/components/Brand';
import Links from 'components/sidebarAdmin/components/Links';
import useAuth from 'hooks/auth';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

function SidebarContent(props: any) {
  const { routes } = props;
  const { getUserActiveSubscriptions } = useAuth();
  const profile = useSelector((state: RootState) => state.auth.authentication);

  useEffect(() => {
    const delay = 1000;
    const timer = setTimeout(async () => {
      if (profile?.user_id) {
        try {
          const user_plans = await getUserActiveSubscriptions(profile?.user_id);
          const hasPremiumPlan = user_plans?.some(
            (plan: any) => plan.plan_name === 'Premium'
          );
          if (hasPremiumPlan) {
            console.info('User has a premium plan');
          }
        } catch (error) {
          console.error('Error fetching user active subscriptions:', error);
        }
      }
    }, delay);

    return () => clearTimeout(timer);
  }, [profile]);

  return (
    <Flex
      direction="column"
      height="100%"
      pt="25px"
      borderRadius="30px"
      maxW="300px"
      px="20px"
    >
      <Brand />
      <Stack direction="column" mt="8px" mb="auto">
        <Box ps="20px" pe={{ lg: '16px', '2xl': '16px' }}>
          <Links routes={routes} />
        </Box>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;
