import 'bootstrap/dist/css/bootstrap.min.css';
import { createRoot } from "react-dom/client";
import "../src/styles/App.css";
import "../src/styles/Contact.css";
import "../src/styles/MiniCalendar.css";
import "../src/styles/Plugins.css";
import App from "./app";
import React from 'react';

import registerServiceWorker from "./registerServiceWorker";

const loadExternalCSS = (url: string, integrity?: string, crossorigin?: string) => {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = url;
  if (integrity) {
    link.integrity = integrity;
  }
  if (crossorigin) {
    link.crossOrigin = crossorigin;
  }
  document.head.appendChild(link);
};

const loadExternalJS = (url: string, integrity?: string, crossorigin?: string, callback?: () => void) => {
  const link = document.createElement("link");
  link.rel = "preconnect";
  link.href = new URL(url).origin;
  document.head.appendChild(link);

  const dnsPrefetchLink = document.createElement("link");
  dnsPrefetchLink.rel = "dns-prefetch";
  dnsPrefetchLink.href = new URL(url).origin;
  document.head.appendChild(dnsPrefetchLink);

  const script = document.createElement("script");
  script.src = url;
  if (integrity) {
    script.integrity = integrity;
  }
  if (crossorigin) {
    script.crossOrigin = crossorigin;
  }
  script.onload = callback;
  document.head.appendChild(script);
};

const loadInlineJS = (code: string) => {
  const script = document.createElement("script");
  script.innerHTML = code;
  document.body.appendChild(script);
};

loadExternalCSS(
  "https://cdnjs.cloudflare.com/ajax/libs/ekko-lightbox/5.3.0/ekko-lightbox.css"
);
loadExternalCSS("https://unpkg.com/aos@next/dist/aos.css");

loadExternalCSS(
  "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css",
  "sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ",
  "anonymous"
);

loadExternalJS("https://code.jquery.com/jquery-3.6.0.min.js", undefined, "anonymous", () => {
  loadExternalJS("https://kit.fontawesome.com/a98ba3b45d.js");
  loadExternalJS("https://unpkg.com/aos@next/dist/aos.js", undefined, undefined, () => {
    if (window.AOS) {
      window.AOS.init({
        offset: 100,
        duration: 1000,
      });
    }
  });

  loadExternalJS(
    "https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.7/dist/umd/popper.min.js",
    "sha384-zYPOMqeu1DAVkHiLqWBUTcbYfZ8osu1Nd6Z89ify25QV9guujx43ITvfi12/QExE",
    "anonymous"
  );

  loadExternalJS(
    "https://cdnjs.cloudflare.com/ajax/libs/ekko-lightbox/5.3.0/ekko-lightbox.min.js"
  );

  loadExternalJS(
    "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/js/bootstrap.bundle.min.js",
    "sha384-ENjdO4Dr2bkBIFxQpeoTz1HIcje39Wm4jDKdf19U8gI4ddQ3GYNS7NTKfAdVQSZe",
    "anonymous",
    () => {
      const scriptContent = `
        window.dataLayer = window.dataLayer || [];

        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());

        gtag('config', 'G-V7RDPF6H21');

        // Get the current year for the copyright
        $('#year').text(new Date().getFullYear());

        //configure slider
        $('.carousel').carousel({
            interval: 6000,
            pause: 'hover'
        });

        //lightbox init
        $(document).on('click', '[data-toggle="lightbox"]', function(event) {
            event.preventDefault();
            $(this).ekkoLightbox();
        });

        //video play
        $(function() {
            //autoplay modal video
            $(".video").click(function() {
                var theModal = $(this).data("target"),
                    videoSRC = $(this).attr("data-video"),
                    videoSRCauto = videoSRC + "?modestbranding=1&rel=0&controls=0&showinfo=0&html5=1&autoplay=1";
                $(theModal + ' iframe').attr('src', videoSRCauto);
                $(theModal + ' button.close').click(function() {
                    $(theModal + ' iframe').attr('src', videoSRC);
                });
            });
        });

        // smooth scrolling
        $('#main-nav a').on('click', function(e) {
            if (this.hash !== '') {
                //prevent default behavior
                e.preventDefault();

                //store hash
                const hash = this.hash;

                // animate smooth scroll
                $('html, body').animate({
                    scrollTop: $(hash).offset().top
                }, 900, function() {

                    // add hah to url after scroll
                    window.location.hash = hash;
                });

            }

        });

        $('#footer-nav a').on('click', function(e) {
            if (this.hash !== '') {
                //prevent default behavior
                e.preventDefault();

                //store hash
                const hash = this.hash;

                // animate smooth scroll
                $('html, body').animate({
                    scrollTop: $(hash).offset().top
                }, 900, function() {

                    // add hah to url after scroll
                    window.location.hash = hash;
                });

            }

        });

        $('#button-nav a').on('click', function(e) {
            if (this.hash !== '') {
                //prevent default behavior
                e.preventDefault();

                //store hash
                const hash = this.hash;

                // animate smooth scroll
                $('html, body').animate({
                    scrollTop: $(hash).offset().top
                }, 900, function() {

                    // add hah to url after scroll
                    window.location.hash = hash;
                });

            }

        });
      `;

      loadInlineJS(scriptContent);
    }
  );
});

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);
registerServiceWorker();