import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import PaymentErrorAlert from "components/payment/PaymentError";
import PaymentSuccessAlert from "components/payment/PaymentSuccess";
import RedirectPage from "components/redirect/Redirect";
import SignUp from "components/signup/sign-up";
import AdminLayout from "layouts/admin";
import Auth from "layouts/auth";
import ClientLayout from "layouts/client";
import Profile from "profile/Profile";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ScrollToTop from "utils/scrollToTop";
import EditTags from "views/admin/admin/EditTag";
import ExploreData from "views/admin/admin/ExploreData";
import AdminFileList from "views/admin/filelists/FilelIst";
import SignInCentered from "views/auth/signIn";
import Pricing from "views/client/pricing";
import Landing from "views/landing/landing";

const MainPage: React.FC<any> = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <HelmetProvider>
        <Helmet>
          <title>NuComply | Compliance Management Solution</title>
          <meta
            name="description"
            content="NuComply is a comprehensive compliance solution that streamlines regulatory workflows and reduces risk. Achieve full adherence and operational excellence—learn more today!"
          />
          <link
            rel="preload"
            href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;600&display=swap&subset=latin"
            as="style"
          />
        </Helmet>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH_0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH_0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: `${
              window.location.origin
            }/redirect?path=${encodeURIComponent(
              window.location.href
            )}&organization=Nuarca`,
          }}
        >
          <Switch>
            <Route path={`/auth`} component={Auth} />
            <Route path={`/compliance`} component={ClientLayout} />
            <Route path={`/pricing`} component={Pricing} />
            <Route path={`/profile`} component={Profile} />
            <Route path={`/redirect`} component={RedirectPage} />
            <Route path={`/signup`} component={SignUp} />
            <Route path={`/payment/success`} component={PaymentSuccessAlert} />
            <Route path={`/payment/error`} component={PaymentErrorAlert} />
            <Route path={`/sign-in`} component={SignInCentered} />
            <Route path={"/files"} component={AdminFileList} />
            <Route path={"/admin"} component={AdminLayout} />
            <Route
              path="/file/chunk/:partner/:persona/:personaId/:fileName/:displayName"
              component={ExploreData}
            />
            <Route
              path="/file/chunk-tags/:partner/:persona/:personaId/:fileName/:displayName/:documentId"
              component={EditTags}
            />
            <Route exact path={`/`} component={Landing} />
          </Switch>
        </Auth0Provider>
      </HelmetProvider>
    </BrowserRouter>
  );
};

export default MainPage;
