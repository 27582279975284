import {
  Avatar,
  Box,
  Flex,
  FormControl,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import useAuth from 'hooks/auth';
import React, { useEffect, useState } from 'react';
import { MdAutoAwesome, MdBolt, MdSunny } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { components } from 'react-select';
import adminRoutes from 'routesadmin';
import routes from 'routes';
import { setAuthentication } from 'store/reducers/auth';
import { clearMessages } from 'store/reducers/messages';
import { RootState } from 'store/store';
import NotificationNavBar from '../notificationNavBar/notificationNavBar';

export default function AdminNavbar(props: {
  secondary: boolean;
  message: string | boolean;
  brandText: string;
  logoText: string;
  fixed: boolean;
  onOpen: (...args: any[]) => any;
}) {
  const location = useLocation();
  const isChatPage = location.pathname.includes('/chat');

  const getInitialTabValue = () => {
    const activeTabString = sessionStorage.getItem('activeTab');
    if (activeTabString === null || isNaN(parseInt(activeTabString, 10))) {
      return 1;
    }
    return parseInt(activeTabString, 10);
  };

  const [activeTab, setActiveTab] = useState<number>(getInitialTabValue);

  const history = useHistory();
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const { login, logOut } = useAuth();
  const isAdmin = history.location.pathname.includes('/admin');

  useEffect(
    () => {
      window.addEventListener('scroll', changeNavbar);
      const activeTabString = sessionStorage.getItem('activeTab');
      if (activeTabString !== null) {
        const newActiveTab = parseInt(activeTabString, 10);
        if (!isNaN(newActiveTab)) {
          setActiveTab(newActiveTab);
        }
      }

      return () => {
        window.removeEventListener('scroll', changeNavbar);
      };
    },[]
  );

  const { secondary, brandText } = props;
  const mainText = useColorModeValue('navy.700', 'white');
  const navbarPosition = 'fixed' as const;
  const navbarFilter = 'none';
  const navbarBackdrop = 'blur(20px)';
  const menuBg = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );
  const navbarShadow = 'none';
  const navbarBg = useColorModeValue(
    'rgba(244, 247, 254, 0.2)',
    'rgba(11,20,55,0.5)'
  );
  const navbarBorder = 'transparent';
  const secondaryMargin = '0px';
  const gap = '0px';
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const dispatch = useDispatch();
  const changeNavbar = () => {
    console.info('changeNavbar');
  };

  const handleLogIn = () => {
    if (!profile?.idToken || !profile?.user_id) {
      login();
      return;
    }
  };

  const handleProductSetting = () => {
    history.push('/compliance/profile-overview');
  };

  const handleManageUsers = () => {
    history.push('/admin/user-management');
  };
  
  const { colorMode } = useColorMode();
  const logoSrc =
    colorMode === 'dark' ? '/img/logo_light.png' : '/img/logo_dark.png';

  const handleTabChange = (newTab: number) => {
    sessionStorage.setItem('activeTab', newTab.toString());
    setActiveTab(newTab);
  };

  const options = [
    { label: 'Federal', value: 1, icon: MdAutoAwesome },
    { label: 'Federal & State', value: 2, icon: MdBolt },
    { label: 'Canada', value: 3, icon: MdSunny },
  ];

  const handleSelectChange = (selectedOption: any) => {
    handleTabChange(selectedOption.value);
  };

  const CustomOption = (props: any) => (
    <components.Option {...props}>
      <Flex alignItems="center">
        <Icon as={props.data.icon} mr="8px" color="currentColor" />
        {props.data.label}
      </Flex>
    </components.Option>
  );

  const handleLogout = () => {
    localStorage.removeItem('authentication');
    sessionStorage.removeItem('product');
    sessionStorage.removeItem('plan');
    sessionStorage.removeItem('chat');
    sessionStorage.removeItem('subscriptionLevel');
    dispatch(setAuthentication(undefined));
    dispatch(clearMessages());
    logOut();
  };

  const SingleValue = (props: any) => {
    const singleValueTextColor = useColorModeValue('navy.700', 'white');
    return (
      <components.SingleValue {...props}>
        <Flex alignItems="center" color={singleValueTextColor}>
          <Icon as={props.data.icon} mr="8px" color="currentColor" />
          {props.data.label}
        </Flex>
      </components.SingleValue>
    );
  };

  const resResponsiveBrandText = useBreakpointValue({
    base: (
      <Flex alignItems="center" justifyContent="space-between">
        <Flex>
          <SidebarResponsive routes={isAdmin ? adminRoutes : routes} />
          <Image src={logoSrc} alt="logo" h="40px" />
        </Flex>

        {isChatPage && (
          <>
            <FormControl
              ml="0px"
              mr="5px"
              display="flex"
              justifyContent="right"
              w="unset"
            >
              <Select
          value={options.find((option) => option.value === activeTab)}
          options={options}
          onChange={handleSelectChange}
          components={{ Option: CustomOption, SingleValue }}
          size="sm"
          chakraStyles={{
            container: (provided) => ({
              ...provided,
              minWidth: '120px',
              maxWidth: '300px',
            }),
            menu: (provided) => ({
              ...provided,
              fontSize: '0.8rem',
            }),
          }}
              />
            </FormControl>
          </>
        )}

        <Menu>
          <MenuButton p="0px">
            <Avatar
              _hover={{ cursor: 'pointer' }}
              color="white"
              // name='User'
              bg="#0680ff"
              size="sm"
              w="40px"
              h="40px"
              src={profile?.user?.picture} 
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <Flex flexDirection="column" p="10px">
              {!profile?.idToken && (
                <>
                  <Text
                    ps="10px"
                    pt="16px"
                    pb="10px"
                    w="100%"
                    borderBottom="1px solid"
                    borderColor={borderColor}
                    fontSize="sm"
                    fontWeight="700"
                    color={textColor}
                    onClick={handleLogIn}
                  >
                    👋&nbsp; Hey, Please login
                  </Text>
                  <MenuItem
                    _hover={{ bg: 'none' }}
                    _focus={{ bg: 'none' }}
                    color="blue.400"
                    borderRadius="8px"
                    px="14px"
                    onClick={handleLogIn}
                  >
                    <Text fontSize="sm">Log In</Text>
                  </MenuItem>
                </>
              )}
              {profile?.idToken && (
                <div>
                  <Text
                    ps="10px"
                    pt="16px"
                    pb="10px"
                    w="100%"
                    borderBottom="1px solid"
                    borderColor={borderColor}
                    fontSize="sm"
                    fontWeight="700"
                    color={textColor}
                  >
                    &nbsp; {profile?.userEmail}
                  </Text>
                  <MenuItem
                    _hover={{ bg: 'none' }}
                    _focus={{ bg: 'none' }}
                    onClick={handleProductSetting}
                    borderRadius="8px"
                    px="14px"
                  >
                    <Text fontSize="sm">Institution Profile</Text>
                  </MenuItem>
                  {profile?.role_name === 'Admin' && (
                    <MenuItem
                      _hover={{ bg: 'none' }}
                      _focus={{ bg: 'none' }}
                      borderRadius="8px"
                      px="14px"
                      onClick={handleManageUsers}
                    >
                      <Text fontSize="sm">User Management</Text>
                    </MenuItem>
                  )}
                  <MenuItem
                    _hover={{ bg: 'none' }}
                    _focus={{ bg: 'none' }}
                    color="red.400"
                    borderRadius="8px"
                    px="14px"
                    onClick={handleLogout}
                  >
                    <Text fontSize="sm">Log out</Text>
                  </MenuItem>
                </div>
              )}
            </Flex>
          </MenuList>
        </Menu>
      </Flex>
    ),
    xl: brandText,
  });

  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="16px"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration="0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: 'center' }}
      display={secondary ? 'block' : 'flex'}
      minH="75px"
      justifyContent={{ xl: 'center' }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
      px={{
        // sm: paddingX,
        md: '10px',
      }}
      ps={{
        xl: '12px',
      }}
      pt="35px"
      top="0px"
      w={{
        base: 'calc(100vw - 6%)',
        md: 'calc(100vw - 8%)',
        lg: 'calc(100vw - 6%)',
        xl: 'calc(100vw - 350px)',
        '2xl': 'calc(100vw - 365px)',
      }}
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: 'column',
          xl: 'row',
        }}
        alignItems={{ xl: 'center' }}
        mb={gap}
        zIndex="10"
      >
        <Box mb={{ sm: '8px', md: '0px' }}>
          <Link
            color={mainText}
            href="#"
            bg="inherit"
            borderRadius="inherit"
            fontWeight="bold"
            fontSize="34px"
            _hover={{ color: { mainText } }}
            _active={{
              bg: 'inherit',
              transform: 'none',
              borderColor: 'transparent',
            }}
            _focus={{
              boxShadow: 'none',
            }}
          >
            {resResponsiveBrandText}
          </Link>
        </Box>
        <NotificationNavBar />
        <Box ms="auto" ml={2} w={{ sm: '100%', md: 'unset' }}>   
          <AdminNavbarLinks
            onOpen={props.onOpen}
            secondary={props.secondary}
            fixed={props.fixed}
          />
        </Box>
      </Flex>
    </Box>
  );
}
