import { CloseIcon } from '@chakra-ui/icons';
import {
  Box, Flex,
  Icon,
  IconButton,
  Text
} from '@chakra-ui/react';
import React from 'react';
import { FaFileAlt, FaFileWord, FaHtml5 } from 'react-icons/fa';
import { FaFilePdf } from 'react-icons/fa6';

interface File {
  name: string;
  type: string;
}

export function selectedFilesSection(selectedFiles: File[], inputColor: string, handleRemoveFile: (fileName: string) => void): React.ReactNode {
  return <Flex mb="8px" p={{ base: '2px', md: '5px' }} gap="5px" wrap="wrap">
    {selectedFiles.map((file: File, index: number) => (
      <Flex
        key={index}
        justifyContent="space-between"
        alignItems="center"
        mb="4px"
        width={{ base: '180px', md: '220px' }}
        p="10px"
        border="1px solid"
        borderColor="gray.500"
        borderRadius="md"
        position="relative"
      >
        <Box display="flex" alignItems="center">
          <Box mr="8px">
            {file.type === 'application/pdf' && (
              <Icon as={FaFilePdf} color="white" w={6} h={6} />
            )}
            {(file.type === 'application/msword' ||
              file.type ===
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document') && (
                <Icon as={FaFileWord} color="white" w={6} h={6} />
              )}
            {file.type === 'text/plain' && (
              <Icon as={FaFileAlt} color="white" w={6} h={6} />
            )}
            {file.type === 'text/html' && (
              <Icon as={FaHtml5} color="white" w={6} h={6} />
            )}
          </Box>
          <Box>
            <Text
              fontSize="sm"
              color={inputColor}
              isTruncated
              maxW={{ base: '100px', md: '130px' }}
            >
              {file.name}
            </Text>
            <Text fontSize="sm" color={inputColor}>
              {file.type === 'application/pdf'
                ? 'PDF'
                : file.type === 'application/msword'
                  ? 'DOC'
                  : file.type ===
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    ? 'DOCX'
                    : file.type === 'text/plain'
                      ? 'TXT'
                      : file.type.toUpperCase()}
            </Text>
          </Box>
        </Box>
        <IconButton
          size="xs"
          aria-label="Remove file"
          icon={<CloseIcon />}
          onClick={() => handleRemoveFile(file.name)}
          position="absolute"
          top="2px"
          right="2px" />
      </Flex>
    ))}
  </Flex>;
}
