import { getPartners, getPersonas } from '../../../services/PartnerService/partnerService';
import { FetchPersonasPartnersProps } from "./FetchPersonasPartnersProps";
import useDisplayToast from '../../../utils/DisplayToast';

export function useFetchPersonasPartners({
  ensureValidToken, alreadyCalledFetchPartnersRef, setPartners, alreadyCalledFetchPersonasRef, setLoading, setPersonas,
}: FetchPersonasPartnersProps) {
  const displayToast = useDisplayToast();

  const fetchPartners = async () => {
    const token = await ensureValidToken(localStorage.getItem('token'));

    if (!alreadyCalledFetchPartnersRef.current) {
      alreadyCalledFetchPartnersRef.current = true;
      try {
        const response = await getPartners(token);
        const filteredPartners = response.data.filter(
          (partner: any) => partner.name === 'state_banking_compliance' ||
            partner.name === 'federal_banking_compliance'
        );
        setPartners(filteredPartners);
      } catch (error) {
        console.error('Error fetching partners:', error);
        displayToast('Error', 'There was an error fetching partners.', 'error', 5000);
      }
    }
  };

  const fetchPersonas = async (partnerId: number) => {
    const token = await ensureValidToken(localStorage.getItem('token'));
    if (!alreadyCalledFetchPersonasRef.current) {
      alreadyCalledFetchPersonasRef.current = true;
      try {
        setLoading(true);
        const response = await getPersonas(token, partnerId);
        setPersonas(response);
      } catch (error) {
        console.error('Error fetching personas:', error);
        displayToast('Error', 'There was an error fetching personas.', 'error', 5000);
      } finally {
        setLoading(false);
      }
    }
  };
  return { fetchPersonas, fetchPartners };
}
