import React, { useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  FormControl,
  FormErrorMessage,
  Stack,
} from "@chakra-ui/react";
import { Select, components } from "chakra-react-select";

interface MultiSelectDropdownProps {
  width: string;
  signupReasonsError: any;
  category: any;
  isAssetSizeSelection: boolean;
  allOptions: any;
  signupReasonsField: any;
  isSubmitting: boolean;
  handleReset: () => void;
  handleSelectAll: () => void;
  handleSubmit: any;
  onSubmit: (values: any) => Promise<void>;
  showSubmitButton?: boolean;
  closeAfterSelection?: boolean;
  disabled?: boolean;
  resetClicked?: boolean;
  hideSelectAll?: boolean;
}
export const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  width,
  signupReasonsError,
  category,
  isAssetSizeSelection,
  allOptions,
  signupReasonsField,
  isSubmitting,
  handleReset,
  handleSelectAll,
  handleSubmit,
  onSubmit,
  showSubmitButton,
  closeAfterSelection,
  disabled,
  resetClicked,
  hideSelectAll,
}) => {
    const isMobile = window.innerWidth <= 768;
  const handleChange = (value: any) => {
    let selectedValue;
    if (typeof value === "object" && value.value === "") {
      selectedValue = [];
    } else {
      selectedValue = isAssetSizeSelection ? (value ? [value] : []) : value;
    }
    signupReasonsField.onChange([
      ...signupReasonsField.value,
      ...selectedValue,
    ]);
    if (!showSubmitButton) {
      handleSubmit(onSubmit)();
    }
  };

  const onSelectAll = () => {
    signupReasonsField.onChange(allOptions);
    handleSelectAll();
  };

  useEffect(() => {
    console.log("resetClicked", resetClicked);
    if (resetClicked) {
      handleReset();
    }
  }, [resetClicked]);

  return (
    <Container as="form">
    <Stack
      spacing={6}
      w={{ sm: "70vw", md: width }}
      ml={{ sm: "-8.5vw", md: "0vw", lg: "0vw", xl: "0vw" }}
    >
      <FormControl isInvalid={!!signupReasonsError}>
        {category && category.products && (
        <>
          <Select
            isMulti={!isAssetSizeSelection}
            options={
              isAssetSizeSelection
                ? [
                    { label: "Select One", value: "" },
                    ...allOptions.filter(
                      (option: any) =>
                        !signupReasonsField.value.some(
                          (selected: any) => selected.value === option.value
                        )
                    ),
                  ]
                : allOptions.filter(
                    (option: any) =>
                      !signupReasonsField.value.some(
                        (selected: any) => selected.value === option.value
                      )
                  )
            }
            closeMenuOnSelect={isAssetSizeSelection || closeAfterSelection}
            value={null}
            onChange={handleChange}
            isDisabled={disabled}
            components={{
              DropdownIndicator: (props) => (
                <components.DropdownIndicator {...props} />
              ),
            }}
            styles={{
              container: (provided) => ({ ...provided, width: "1000px", zIndex: 2 }),
            }}
          />
          {hideSelectAll && (
            <Button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                handleReset();
              }}
              bottom="10"
              right="45"
              disabled={disabled}
              style={{
                marginLeft: "8px",
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              &#x21bb;
            </Button>
          )}
        </>
          )}
          <div
            style={{
              maxHeight: "16vh",
              overflowY:
                signupReasonsField.value.length > 2 || !isMobile ? "scroll" : "visible",
              position: hideSelectAll ? "absolute" : "relative",
              zIndex: 1,
              width: "100%",
              top: "40px"
            }}
          >
            {signupReasonsField.value.map((selected: any) => (
              <Button
                type="button"
                className="selected-options-profile"
                key={selected.value}
                variant="outline"
                rightIcon={
                  <span style={{ marginLeft: "8px", cursor: "pointer" }}>
                    x
                  </span>
                }
                onClick={() => {
                  const newValue = signupReasonsField.value.filter(
                    (item: any) => item.value !== selected.value
                  );
                  signupReasonsField.onChange(newValue);
                  if (!showSubmitButton) {
                    handleSubmit(onSubmit)();
                  }
                }}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: window.innerWidth <= 768 ? "none" : "20vw",
                }}
                title={selected.label}
                disabled={disabled}
              >
                {selected.label.length > (window.innerWidth <= 768 ? 4 : 20)
                  ? `${selected.label.substring(
                      0,
                      window.innerWidth <= 768 ? 10 : 20
                    )}...`
                  : selected.label}
              </Button>
            ))}
          </div>
          <FormErrorMessage>{signupReasonsError?.message}</FormErrorMessage>
        </FormControl>

        <ButtonGroup>
          {!hideSelectAll && (
            <Button
              type="button"
              isLoading={isSubmitting}
              onClick={handleReset}
              disabled={disabled}
              mt="0"
            >
              Reset
            </Button>
          )}
          {!isAssetSizeSelection && !hideSelectAll && (
            <Button type="button" onClick={onSelectAll} disabled={disabled}>
              Select All
            </Button>
          )}
          {showSubmitButton && (
            <Button
              type="button"
              isLoading={isSubmitting}
              colorScheme="blue"
              onClick={handleSubmit(onSubmit)}
              disabled={disabled}
            >
              Submit
            </Button>
          )}
        </ButtonGroup>
      </Stack>
    </Container>
  );
};
