import {
  Badge,
  Box,
  Button,
  List,
  ListItem,
  Text,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';
import axios from 'axios';
import Card from 'components/card/Card';
import useAuth from 'hooks/auth';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/store';
import useDisplayToast from '../../../../utils/DisplayToast';

export default function Pack(props: {
  title: string;
  desc: string;
  button: string;
  planId: number;
  benefits: string[];
  clickButton?: boolean;
  highlighted?: boolean;
}) {
  const { title, desc, button, planId, benefits, highlighted, clickButton } =
    props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const { login } = useAuth();
  const history = useHistory();
  const { ensureValidToken } = useAuth();
  const displayToast = useDisplayToast();
  const [isFullHD] = useMediaQuery("(min-width: 1920px)");

  const handleButtonClick = async () => {
    const token = await ensureValidToken(localStorage.getItem('token')); 
    
    if (!profile?.user_id) {
      console.error('No User id found');
      login();
      return;
    }

    let requestBody = {
      user_id: profile.user_id,
      plan_id: planId,
      success_url: `${window.location.origin}/payment/success`,
      cancel_url: `${window.location.origin}/payment/cancel`,
    };

    try {
      if (button === 'Upgrade') {
        const createStripeSessionURL = `${process.env.REACT_APP_BACKEND_API_URL}/api/v1/payment/create-checkout-session`;
        requestBody = {
          user_id: profile.user_id, //TODO Dynamic the user id
          plan_id: planId,
          success_url: `${window.location.origin}/payment/success`,
          cancel_url: `${window.location.origin}/payment/cancel`,
        };
        const response = await axios.post(createStripeSessionURL, requestBody, {
          headers: { Authorization: `Bearer ${token}`  },
        });
        const { payment_url } = response.data.data;
        window.open(payment_url, '_blank');
      } else if (button === 'Downgrade') {
        const downgradePlanURL = `${process.env.REACT_APP_BACKEND_API_URL}/api/v1/payment/downgrade-plan`;
        const response = await axios.post(downgradePlanURL, requestBody, {
          headers: { Authorization: `Bearer ${token}`  },
        });

        if (response.data.success) {
          displayToast('Plan Downgraded Successfully','You have downgraded your subscription plan.','success',3000);
          setTimeout(() => {
            history.push('/compliance/pricing');
            window.location.reload();
          }, 1000);
        } else {
          displayToast('Failed to Downgrade Plan',response.data.message || 'Unknown error occurred','error',3000);
        }
      } else if (button === 'Contact Us') {
        window.location.href = 'mailto:support@nucomply.com';
      }
    } catch (error) {
      console.error(
        `Error while processing ${button.toLowerCase()} request:`,
        error
      );
      displayToast('Failed to Process Request','An error occurred while processing your request','error',3000);
    }
  };

  useEffect(() => {
    // Refresh the state or perform any necessary actions here
  }, [history.location]);

  return (
    <Card
      style={{ alignSelf: 'flex-start' }}
      p="20px"
      pb="45px"
      pt={highlighted ? '60px' : '30px'}
      w={{ sm: '300px', md: '650px', lg: '200px', xl: isFullHD ? '375px' : '300px' }}
      alignItems="flex-start"
      justifyContent="flex-start"
      overflow="unset !important"
    >
      <Badge
        display={highlighted ? 'block' : 'none'}
        w="max-content"
        position="absolute"
        fontSize="sm"
        color="orange.500"
        bg="orange.100"
        fontWeight="bold"
        textTransform="unset"
        left="50%"
        borderRadius="6px"
        transform="translate(-50%,-250%)"
      >
        Most popular plan
      </Badge>
      <Text fontSize="30px" color={textColor} fontWeight="700">
        {title}
      </Text>
      <Text mb="30px" fontSize="md" color="secondaryGray.600" fontWeight="500">
        {desc || '\u00A0'} {/* Using '\u00A0' to render a non-breaking space */}
      </Text>
      <Button
        w="100%"
        variant={clickButton ? 'brand' : 'lightBrand'}
        onClick={handleButtonClick}
        isDisabled={!clickButton}
      >
        {button}
      </Button>
      <List spacing={3} justifyContent="flex-start">
        {benefits && benefits.map((benefit, index) => (
          <ListItem
            key={`pack-${index}`}
            display="flex"
            alignItems="flex-start"
            textAlign="start"
            fontSize="md"
            fontWeight="500"
            color={textColor}
            lineHeight="100%"
            mt="25px !important"
          >
            <Box as="span" mr={2} fontSize="3xl" lineHeight="inherit">
              •
            </Box>
            <Text
              dangerouslySetInnerHTML={{ __html: benefit }}
              sx={{ lineHeight: '1.5' }}
            />
          </ListItem>
        ))}
      </List>
    </Card>
  );
}
