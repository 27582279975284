import { useAuth0 } from "@auth0/auth0-react";
import { Box, Image, Heading, Text, Spinner } from "@chakra-ui/react";
import React from 'react';

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();  

  if (isLoading) {
    return <Spinner />;
  }

  return isAuthenticated && (
    <Box p={4} maxW="400px" borderWidth="1px" borderRadius="lg">
      <Image src={user.picture} alt={user.name} borderRadius="full" boxSize="150px" />
      <Heading as="h2" size="lg" mt={4}>
        {user.name}
      </Heading>
      <Text mt={2}>{user.email}</Text>
    </Box>
  );
};

export default Profile;
