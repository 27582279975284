import { NavLink } from "react-router-dom";
import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { FeatureRoute } from "../../../models/routes";
import React from 'react';

interface LinkLayoutProps {
  index: number;
  route: FeatureRoute;
  setAbortController: (controller: AbortController) => void;
  handleResetRegChanges: () => void;
  activeRoute: (routeName: string) => boolean;
  activeIcon: string;
  textColor: string;
  activeColor: string;
  newRegs: number;
  brandColor: string;
  inactiveColor: string;
}
export function linkLayout({
  index, route, setAbortController, handleResetRegChanges, activeRoute, activeIcon, textColor, activeColor, newRegs, brandColor, inactiveColor,
}: LinkLayoutProps) {
  return (
    <NavLink
      key={index}
      to={route.layout + route.path}
      onClick={() => {
        const newAbortController = new AbortController();
        setAbortController(newAbortController);
        handleResetRegChanges();
      }}
    >
      {route.icon ? (
        <Box>
          <HStack
            spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"}
            py="5px"
            ps="0px"
          >
            <Flex w="100%" alignItems="center" justifyContent="center">
              <Box
                color={activeRoute(route.path.toLowerCase())
                  ? activeIcon
                  : textColor}
                me="18px"
              >
                {route.icon}
              </Box>
              <Text
                me="auto"
                color={activeRoute(route.path.toLowerCase())
                  ? activeColor
                  : textColor}
                fontWeight={activeRoute(route.path.toLowerCase())
                  ? "bold"
                  : "normal"}
              >
                {route.name}
                {route.name === "Regulatory Change Review" && newRegs > 0
                  ? ` (${newRegs})`
                  : ""}
              </Text>
            </Flex>
            <Box
              h="36px"
              w="4px"
              bg={activeRoute(route.path.toLowerCase())
                ? brandColor
                : "transparent"}
              borderRadius="5px" />
          </HStack>
        </Box>
      ) : (
        <Box>
          <HStack
            spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"}
            py="5px"
            ps="10px"
          >
            <Text
              me="auto"
              color={activeRoute(route.path.toLowerCase())
                ? activeColor
                : inactiveColor}
              fontWeight={activeRoute(route.path.toLowerCase())
                ? "bold"
                : "normal"}
            >
              {route.name}
            </Text>
            <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
          </HStack>
        </Box>
      )}
    </NavLink>
  );
}
