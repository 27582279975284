import { useLocation } from "react-router-dom";
import { useColorModeValue } from "@chakra-ui/react";
import { useAbortController } from "hooks/AbortControllerContext";
import { FeatureRoute } from "../../../models/routes";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../hooks/auth";
import { RootState } from "store/store";
import React, { useEffect, useState } from "react";
import eventBus from "../../../utils/eventBus";
import { setSubscription } from "../../../store/reducers/subscription";
import { linkLayout } from "./LinkLayout";

export function SidebarLinks(props: { routes: FeatureRoute[] }) {
  const location = useLocation();
  const activeColor = useColorModeValue("gray.700", "white");
  const inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  const activeIcon = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.500", "white");
  const brandColor = useColorModeValue("brand.500", "brand.400");
  const { setAbortController } = useAbortController();
  const { getUserActiveSubscriptions } = useAuth();
  const { routes } = props;
  const [filteredRoutes, setFilteredRoutes] = useState<FeatureRoute[]>([]);
  const [subscriptionLevel, setSubscriptionLevel] = useState<string | null>(
    null
  );
  const newRegCount = useSelector(
    (state: RootState) => state.regChange.newRegsCount
  );
  const [newRegs, setNewRegs] = useState<number>(0);
  const profile: any = JSON.parse(
    localStorage.getItem("authentication") || "{}"
  );
  const userPlans = useSelector((state: RootState) => state.subscriptions.subscriptionPlans);
  const dispatch = useDispatch();
  const availableSubscriptions = useSelector((state: RootState) => state.subscriptions.subscriptionPlans);

  useEffect(() => {
    const highestTeirPlan = Array.isArray(availableSubscriptions) ? availableSubscriptions.filter((plan: { status: string }) => {
      return plan.status === "Active";
    }).reduce((highest: { plan_name: string }, current: { plan_name: string }) => {
      const planOrder = ["Basic", "Premium", "Enterprise"];
      return planOrder.indexOf(current.plan_name) > planOrder.indexOf(highest.plan_name) ? current : highest;
    }, { plan_name: "Basic" }) : { plan_name: "Basic" };
    const updatedRoutes = routes.map((route: FeatureRoute) => {
      if (!Array.isArray(highestTeirPlan) && linkValidForSubscription(route, highestTeirPlan.plan_name)) {
        return route;
      }
      return undefined;
    });
    setFilteredRoutes(updatedRoutes.filter((route) => route !== undefined));
    if (!Array.isArray(highestTeirPlan)) {
      dispatch(setSubscription(highestTeirPlan.plan_name));
      setSubscriptionLevel(highestTeirPlan.plan_name);
      sessionStorage.setItem("subscriptionLevel", highestTeirPlan.plan_name);
    }
  }, [availableSubscriptions]);

  useEffect(() => {
    const fetchSubscriptionLevel = async () => {
      if (profile?.user_id !== undefined) {
        const fetchLevel = async () => {
          await getUserActiveSubscriptions(profile.user_id)
        };
        fetchLevel();
      } else {
      setFilteredRoutes(routes);
      setSubscriptionLevel(undefined);
      }
    };

    fetchSubscriptionLevel();
  }, [profile?.user_id, userPlans]);

  useEffect(() => {
    setNewRegs(newRegCount);
  }, [newRegCount]);

  const handleResetRegChanges = () => {
    eventBus.emit('resetRegChanges');
  }

  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  const linkValidForSubscription = (
    route: FeatureRoute,
    subscriptionLevel: string
  ) => {
    switch (subscriptionLevel) {
      case "Basic":
        if (
          route.plan === "Basic" && route.showOnSidebar
        ) {
          return route;
        }
        break;
      case "Premium":
        if (
          (route.plan === "Basic" || route.plan === "Premium") &&
          route.showOnSidebar
        ) {
          return route;
        }
        break;
      case "Enterprise":
        if (
          (route.plan === "Basic" ||
            route.plan === "Premium" ||
            route.plan === "Enterprise") &&
          route.showOnSidebar
        ) {
          return route;
        }
        break;
      default:
        return route;
    }
  };

  const createLinks = () => {
    if (!profile?.user_id) {
      return filteredRoutes.slice(0,3).map((route: FeatureRoute, index: number) => {
        return (
          linkLayout({
            index,
            route,
            setAbortController,
            handleResetRegChanges,
            activeRoute,
            activeIcon,
            textColor,
            activeColor,
            newRegs,
            brandColor,
            inactiveColor
          })
        );
      });
    }
    return filteredRoutes.map((route: FeatureRoute, index: number) => {
      if (
        route.layout === "/compliance" ||
        route.layout === "/auth" ||
        route.layout === "/rtl" ||
        route.layout === "/admin"
      ) {
        if (route.name === "Institution Profile" && (subscriptionLevel === undefined)) {
          return null;
        }
        return (
          linkLayout({
            index,
            route,
            setAbortController,
            handleResetRegChanges,
            activeRoute,
            activeIcon,
            textColor,
            activeColor,
            newRegs,
            brandColor,
            inactiveColor
          })
        );
      }
      return null;
    }).filter(route => route !== null);
  };
 
    return <>{createLinks()}</>;

}

export default SidebarLinks;