import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import { IconButton } from "@chakra-ui/react";
import { FaFileAlt } from "react-icons/fa";

interface DownloadPolicyCardProps {
  textColor: string;
  updatedPdfLink: string;
  redlinePdfLink: string;
  screenWidth: number;
}

export const DownloadPolicyCard: React.FC<DownloadPolicyCardProps> = ({
  textColor,
  updatedPdfLink,
  redlinePdfLink,
  screenWidth,
}) => {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    if (updatedPdfLink || redlinePdfLink) {
      setIsVisible(true);
    }
  }, [updatedPdfLink, redlinePdfLink]);

  return (
    <Flex
      direction="row"
      align="center"
      justify="center"
      gap="1vw"
      minH={
        updatedPdfLink || redlinePdfLink ? "10vh" : "0vh"
      }
      transition="all 3s ease-in-out"
      opacity={isVisible ? 1 : 0}
    >
      <Flex direction="column" align="center" transition="all 3s ease-in-out" ml="5px">
        {updatedPdfLink && (
          <>
            <IconButton
              as="a"
              href={updatedPdfLink}
              aria-label="File Button 1"
              icon={<FaFileAlt size="1.5em" />}
              size="lg"
              variant="ghost"
              w="30px"
              h="30px"
              position="relative"
              target="_blank"
              rel="noopener noreferrer"
            />
            <Text
              mt="10px"
              fontSize="md"
              fontWeight="bold"
              color={textColor}
              textAlign="center"
            >
              Updated Policy
            </Text>
          </>
        )}
      </Flex>
      <Flex direction="column" align="center" transition="all 3s ease-in-out" mr="5px">
        {redlinePdfLink && (
          <Flex direction="column" align="center" transition="all 3s ease-in-out" opacity={isVisible ? 1 : 0}>
            <IconButton
              as="a"
              href={redlinePdfLink}
              aria-label="File Button 2"
              icon={<FaFileAlt size="1.5em" />}
              size="lg"
              variant="ghost"
              w="30px"
              h="30px"
              position="relative"
              target="_blank"
              rel="noopener noreferrer"
            />
            <Text
              mt="10px"
              fontSize="md"
              fontWeight="bold"
              color={textColor}
              textAlign="center"
            >
              Redlined Policy
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
