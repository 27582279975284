import React, { useEffect } from 'react';
import {
  Button, Flex, Text,
  Textarea
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import UploadFiles from "../../../../components/upload-files/upload-files";
import { PolicyType } from "../../../../models/policy";
import MultiSelect from "../../../admin/main/profile/settings/MultiSelect";

interface PolicyEntryCardProps {
    generatingPolicy: boolean;
    screenWidth: number;
    selectedFiles: FileList;
    handleFileChange: (newFiles: FileList | File[]) => void;
    textColor: string;
    borderColor: string;
    placeholderColor: { color: string; } | { color: string; };
    policyTypes: PolicyType[];
    handleSelectedPolicyTypes: (selectedPolicyTypes: PolicyType[]) => void;
    handleTextChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    handleGeneratePolicy: () => void;
    policyReviewed: boolean;
    requirementsMet: boolean;
    resetClicked: boolean;
}

export const PolicyEntryCard: React.FC<PolicyEntryCardProps> = ({
    generatingPolicy,
    screenWidth,
    selectedFiles,
    handleFileChange,
    textColor,
    borderColor,
    placeholderColor,
    policyTypes,
    handleSelectedPolicyTypes,
    handleTextChange,
    handleGeneratePolicy,
    policyReviewed,
    requirementsMet,
    resetClicked
}) => {

    useEffect(() => {
        console.log('resetClicked', resetClicked);
    }
    , [resetClicked]);

    const handleSubmitPolicies = (values: { signupReasons: { value: string; label: string; }[] }) => {
        const selectedPolicyTypes = values.signupReasons.map((policy: { value: string; label: string; }) => {
            return { id: Number(policy.value), name: policy.label }
        });
        handleSelectedPolicyTypes(selectedPolicyTypes);
    }

    const onSelectAll = (policies: PolicyType[]) => {
        handleSelectedPolicyTypes(policies);
    }

    const onReset = () => {
        handleSelectedPolicyTypes([]);
    }


    return (
        <Card
            minW={screenWidth <= 768 ? "90vw" : "15vw"}
            maxW={screenWidth <= 768 ? "90vw" : "25vw"}
            h="min-content"
            ml={screenWidth <= 768 ? "-10px" : "0"}
        >
            <UploadFiles
                selectedFiles={selectedFiles}
                onFileChange={(files) => handleFileChange(files)}
                multipleSelection={false}
                resetClicked={resetClicked} />
            <Text
                fontSize={"30px"}
                color={textColor}
                fontWeight="800"
                mb="10px"
            >
                Additional Instructions
            </Text>
            <Text
                fontSize={"16px"}
                color="gray.500"
                fontWeight="500"
                mb="30px"
            >
                Provide as much information as possible about your existing
                policies in this area.
            </Text>
            <Textarea
                border="1px solid"
                borderRadius={"10px"}
                borderColor={borderColor}
                p="15px 20px"
                mb="28px"
                minH="25vh"
                fontWeight="500"
                _focus={{ borderColor: "none" }}
                color={textColor}
                placeholder="Enter any additional information here..."
                _placeholder={placeholderColor}
                onChange={(e) => handleTextChange(e)}
                overflowY="auto"
                background="#0b1437"
                disabled={generatingPolicy}
            />
            <Flex
                w="100%"
                direction="column"
                position="relative"
                minH="21.5vh"
                mt={{ base: "5px", md: "0px", xl: "0px" }}
                ml={{ base: "50px", md: "0px" }}
            >
                <MultiSelect 
                    category={null} 
                    policyTypes={policyTypes} 
                    width="20vw" 
                    onSubmitPolicies={handleSubmitPolicies} 
                    closeAfterSelection={true} 
                    onSelectAll={onSelectAll} 
                    onReset={onReset} 
                    disabled={generatingPolicy}
                    resetClicked={resetClicked}
                />
            </Flex>
            
            <Button
                variant={policyReviewed || !requirementsMet ? "solid" : "primary"}
                borderRadius="full"
                w="300px"
                h="54px"
                mt="20px"
                fontSize="md"
                onClick={() => { if (!policyReviewed && requirementsMet) handleGeneratePolicy(); }}
                disabled={policyReviewed || !requirementsMet || generatingPolicy}
                _disabled={{ cursor: "not-allowed", opacity: 0.6 }}

            >
                Review Policy
            </Button>
        </Card>
    );
};
